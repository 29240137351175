import { Col, Row, Select } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { billingSystemData, booleanData } from "../../../utils";
import ViewFileButton from "../../../components/ViewFileButton";

const selectIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='11'
    height='7'
    viewBox='0 0 11 7'
    fill='none'
  >
    <path
      d='M1.2925 0L5.5 4.32659L9.7075 0L11 1.33198L5.5 7L0 1.33198L1.2925 0Z'
      fill='#222222'
    />
  </svg>
);

export default function BillingTransitionPanel() {
  const { projectObj, projectPreparationDetails } = useSelector(
    (state) => state.obj
  );

  useEffect(() => {}, [projectObj, projectPreparationDetails]);

  return (
    <>
      <Row className='px-[2rem]'>
        <Col span={24}>
          <div className='panel_card w-full grid grid-cols-3 mb-[2rem] gap-x-[1rem]'>
            <span className='panel_txt_header'>Item</span>
            <span className='panel_txt_header'>Details</span>
            <span className='panel_txt_header'>File</span>
          </div>

          <div className='panel_card w-full grid grid-cols-3 mb-[2rem]'>
            <span className='panel_txt'>Existing billing system</span>

            <div className='w-[10.9375rem]'>
              <Select
                disabled
                value={
                  projectPreparationDetails?.wrBtBillingPlan ?? "Choose option"
                }
                options={billingSystemData?.map((item) => {
                  return {
                    label: item?.label,
                    value: item?.value,
                  };
                })}
                suffixIcon={selectIcon}
                className='select-dotted'
              />
            </div>
          </div>

          <div className='panel_card w-full grid grid-cols-3 mb-[2rem]'>
            <span className='panel_txt'>Billing transition plan provided</span>
            <Select
              disabled
              value={projectPreparationDetails?.wrBtTransitionPlan}
              options={booleanData?.map((item) => {
                return {
                  label: item?.label,
                  value: item?.value,
                };
              })}
              status={
                projectPreparationDetails?.wrBtTransitionPlan?.toUpperCase() ===
                "NO"
                  ? "error"
                  : "warning"
              }
              suffixIcon={selectIcon}
              className='select-status'
            />

            <ViewFileButton
              file={projectPreparationDetails?.wrBtTransitionPlanFile}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}
