import { Col, Input, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBusinessPlan } from "../../../features/obj/objSlice";
import ViewFileButton from "../../../components/ViewFileButton";
import { booleanData } from "../../../utils";

const selectIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='11'
    height='7'
    viewBox='0 0 11 7'
    fill='none'
  >
    <path
      d='M1.2925 0L5.5 4.32659L9.7075 0L11 1.33198L5.5 7L0 1.33198L1.2925 0Z'
      fill='#222222'
    />
  </svg>
);

export default function BusinessPlanPanel() {
  const dispatch = useDispatch();

  const { projectObj, businessPlan } = useSelector((state) => state.obj);

  const [formObj, setformObj] = useState(businessPlan);

  async function handleFetch() {
    await dispatch(fetchBusinessPlan(projectObj?.projId));
  }

  useEffect(() => {}, [projectObj]);

  useEffect(() => {
    handleFetch();
  }, []);

  useEffect(() => {}, [projectObj, formObj]);

  useEffect(() => {
    setformObj(businessPlan);
  }, [businessPlan]);

  return (
    <>
      <Row className='px-[2rem]'>
        <Col span={24}>
          <span className='heading_6'>Term Sheet</span>
        </Col>
        <Col span={24} className='mt-[1.81rem]'>
          <div className='panel_card w-full grid grid-cols-3 mb-[2rem] gap-x-[1rem]'>
            <span className='panel_txt_header'>Item</span>
            <span className='panel_txt_header col-span-2'>Value</span>
          </div>
        </Col>

        <Col span={24}>
          <div className='panel_card w-full grid grid-cols-3 mb-[2rem] gap-x-[1rem]'>
            <span className='panel_txt_header'>Loan (KES)</span>
            <Input
              readOnly
              value={formObj?.bspLoan}
              className='input_borderless'
            />
          </div>
        </Col>

        <Col span={24}>
          <div className='panel_card w-full grid grid-cols-3 mb-[2rem] gap-x-[1rem]'>
            <span className='panel_txt_header'>Interest Rate (%)</span>
            <Input
              readOnly
              value={formObj?.bspRateAve}
              className='input_borderless'
            />
          </div>
        </Col>

        <Col span={24}>
          <div className='panel_card w-full grid grid-cols-3 mb-[2rem] gap-x-[1rem]'>
            <span className='panel_txt_header'>Loan Term (months) (%)</span>
            <Input
              readOnly
              value={formObj?.bspLoanTerm}
              className='input_borderless'
            />
          </div>
        </Col>

        <Col span={24}>
          <div className='panel_card w-full grid grid-cols-3 mb-[2rem] gap-x-[1rem]'>
            <span className='panel_txt_header'>Business Plan Attachment</span>
            <ViewFileButton file={formObj?.bspPlanFile} />
          </div>
        </Col>
      </Row>

      <Row className='px-[2rem] mt-[2.62rem]'>
        <Col span={24}>
          <span className='heading_6'>Scenarios evaluated</span>
        </Col>
        <Col span={24} className='mt-[1.81rem]'>
          <div className='panel_card w-full grid grid-cols-8 gap-x-[1rem]'>
            <span className='panel_txt_header col-span-2'>No</span>
            <span className='panel_txt_header'>1</span>
            <span className='panel_txt_header'>2</span>
            <span className='panel_txt_header'>3</span>
            <span className='panel_txt_header'>4</span>
            <span className='panel_txt_header col-span-2'>5</span>
          </div>
        </Col>
        <Col span={24} className='mt-[]'>
          <div className='panel_card w-full grid grid-cols-8 gap-x-[1rem]'>
            <span className='panel_txt_header col-span-2'>
              Scenario Description
            </span>
            <span className='panel_txt_header'>
              2nd portion of <br />
              Subsidy = 0
            </span>
            <span className='panel_txt_header'>
              Low <br />
              revenue
            </span>
            <span className='panel_txt_header'>
              High <br />
              interest rate
            </span>
            <span className='panel_txt_header'>
              Moderate <br />
              interest rate
            </span>
            <span className='panel_txt_header col-span-2'>
              High <br />
              Inflation rate
            </span>
          </div>
        </Col>

        <Col span={24} className='mt-[]'>
          <div className='panel_card w-full grid grid-cols-8 gap-x-[1rem]'>
            <span className='panel_txt_header col-span-2'>Item</span>
          </div>
        </Col>

        <Col span={24}>
          <div className='panel_card w-full grid grid-cols-8 gap-x-[1rem]'>
            <span className='panel_txt_header col-span-2'>Loan Sum (KES)</span>
            <Input
              readOnly
              value={formObj?.bspScnLoanSumOne}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.bspScnLoanSumTwo}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.bspScnLoanSumThree}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.bspScnLoanSumFour}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.bspScnLoanSumFive}
              className='input_borderless'
            />
          </div>
        </Col>

        <Col span={24}>
          <div className='panel_card w-full grid grid-cols-8 gap-x-[1rem]'>
            <span className='panel_txt_header col-span-2'>
              Interest Rate (%)
            </span>
            <Input
              readOnly
              value={formObj?.bspInterestRateOne}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.bspInterestRateTwo}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.bspInterestRateThree}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.bspInterestRateFour}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.bspInterestRateFive}
              className='input_borderless'
            />
          </div>
        </Col>

        <Col span={24}>
          <div className='panel_card w-full grid grid-cols-8 gap-x-[1rem]'>
            <span className='panel_txt_header col-span-2'>
              Inflation Rate (%)
            </span>
            <Input
              readOnly
              value={formObj?.bspInflationRateOne}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.bspInflationRateTwo}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.bspInflationRateThree}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.bspInflationRateFour}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.bspInflationRateFive}
              className='input_borderless'
            />
          </div>
        </Col>

        <Col span={24}>
          <div className='panel_card w-full grid grid-cols-8 gap-x-[1rem]'>
            <span className='panel_txt_header col-span-2'>
              Loan Term (months)
            </span>
            <Input
              readOnly
              value={formObj?.bspScnTermSumOne}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.bspScnTermSumTwo}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.bspScnTermSumThree}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.bspScnTermSumFour}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.bspScnTermSumFive}
              className='input_borderless'
            />
          </div>
        </Col>

        <Col span={24}>
          <div className='panel_card w-full grid grid-cols-8 gap-x-[1rem]'>
            <span className='panel_txt_header col-span-2'>
              Monthly Loan Repayment with 15% collateral savings
            </span>
            <Input
              readOnly
              value={formObj?.bspScnMnthRepayOne}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.bspScnMnthRepayTwo}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.bspScnMnthRepayThree}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.bspScnMnthRepayFour}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.bspScnMnthRepayFive}
              className='input_borderless'
            />
          </div>
        </Col>

        <Col span={24}>
          <div className='panel_card w-full grid grid-cols-8 gap-x-[1rem]'>
            <span className='panel_txt_header col-span-2'>Baseline IC</span>
            <Input
              readOnly
              value={formObj?.bspScnBaselineOne}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.bspScnBaselineTwo}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.bspScnBaselineThree}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.bspScnBaselineFour}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.bspScnBaselineFive}
              className='input_borderless'
            />
          </div>
        </Col>

        <Col span={24}>
          <div className='panel_card w-full grid grid-cols-8 gap-x-[1rem]'>
            <span className='panel_txt_header col-span-2'>Target IC</span>
            <Input
              readOnly
              value={formObj?.bspScnTargetOne}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.bspScnTargetTwo}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.bspScnTargetThree}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.bspScnTargetFour}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.bspScnTargetFive}
              className='input_borderless'
            />
          </div>
        </Col>

        <Col span={24}>
          <div className='panel_card w-full grid grid-cols-8 gap-x-[1rem]'>
            <span className='panel_txt_header col-span-2'>
              Average Tariff (Ksh/m3)
            </span>
            <Input
              readOnly
              value={formObj?.bspScnTariffOne}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.bspScnTariffTwo}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.bspScnTariffThree}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.bspScnTariffFour}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.bspScnTariffFive}
              className='input_borderless'
            />
          </div>
        </Col>

        <Col span={24}>
          <div className='panel_card w-full grid grid-cols-8 gap-x-[1rem]'>
            <span className='panel_txt_header col-span-2'>
              Is there a cash flow shortage?
            </span>

            <Select
              disabled
              value={businessPlan?.bspScnCashShortOne ?? "NO"}
              options={booleanData?.map((item) => {
                return {
                  label: item?.label,
                  value: item?.value,
                };
              })}
              suffixIcon={selectIcon}
              className='select-borderless'
            />

            <Select
              disabled
              value={businessPlan?.bspScnCashShortTwo ?? "NO"}
              options={booleanData?.map((item) => {
                return {
                  label: item?.label,
                  value: item?.value,
                };
              })}
              suffixIcon={selectIcon}
              className='select-borderless'
            />

            <Select
              disabled
              value={businessPlan?.bspScnCashShortThree ?? "NO"}
              options={booleanData?.map((item) => {
                return {
                  label: item?.label,
                  value: item?.value,
                };
              })}
              suffixIcon={selectIcon}
              className='select-borderless'
            />

            <Select
              disabled
              value={businessPlan?.bspScnCashShortFour ?? "NO"}
              options={booleanData?.map((item) => {
                return {
                  label: item?.label,
                  value: item?.value,
                };
              })}
              suffixIcon={selectIcon}
              className='select-borderless'
            />

            <Select
              disabled
              value={businessPlan?.bspScnCashShortFive ?? "NO"}
              options={booleanData?.map((item) => {
                return {
                  label: item?.label,
                  value: item?.value,
                };
              })}
              suffixIcon={selectIcon}
              className='select-borderless'
            />
          </div>
        </Col>

        <Col span={24}>
          <div className='panel_card w-full grid grid-cols-8 gap-x-[1rem]'>
            <span className='panel_txt_header col-span-2'>
              Is tariff affordable?
            </span>
            <Select
              disabled
              value={businessPlan?.bspScnAffordOne ?? "NO"}
              options={booleanData?.map((item) => {
                return {
                  label: item?.label,
                  value: item?.value,
                };
              })}
              suffixIcon={selectIcon}
              className='select-borderless'
            />

            <Select
              disabled
              value={businessPlan?.bspScnAffordTwo ?? "NO"}
              options={booleanData?.map((item) => {
                return {
                  label: item?.label,
                  value: item?.value,
                };
              })}
              suffixIcon={selectIcon}
              className='select-borderless'
            />

            <Select
              disabled
              value={businessPlan?.bspScnAffordThree ?? "NO"}
              options={booleanData?.map((item) => {
                return {
                  label: item?.label,
                  value: item?.value,
                };
              })}
              suffixIcon={selectIcon}
              className='select-borderless'
            />

            <Select
              disabled
              value={businessPlan?.bspScnAffordFour ?? "NO"}
              options={booleanData?.map((item) => {
                return {
                  label: item?.label,
                  value: item?.value,
                };
              })}
              suffixIcon={selectIcon}
              className='select-borderless'
            />

            <Select
              disabled
              value={businessPlan?.bspScnAffordFive ?? "NO"}
              options={booleanData?.map((item) => {
                return {
                  label: item?.label,
                  value: item?.value,
                };
              })}
              suffixIcon={selectIcon}
              className='select-borderless'
            />
          </div>
        </Col>
      </Row>
    </>
  );
}
