import { Box, Typography } from '@mui/material'
import React from 'react'
import { cashConverter } from '../../../utils'

const ProjectDescription = ({ data }) => {
    const { projDesc, projCost, projMoneySource, projImpact } = data;
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%', height: '100%', alignItems: 'center' }}>
            <Box
                sx={{
                    backgroundColor: "#FEFEFF",
                    display: "flex",
                    flexDirection: "column",
                    p: 4,
                    borderRadius: 4,
                    mt: 2,
                    width: "65%"
                }}
            >
                <Typography sx={{ fontWeight: "bold", mb: 1 }}>
                    Project description
                </Typography>
                <Typography sx={{ color: '#667085', lineHeight: 2, overflowY: 'auto', maxHeight: '14em' }}>
                    {projDesc}
                </Typography>

            </Box>
            <Box
                sx={{
                    backgroundColor: "#FEFEFF",
                    display: "flex",
                    flexDirection: "column",
                    p: 4,
                    borderRadius: 4,

                    width: "65%"
                }}
            >
                <Typography sx={{ fontWeight: "bold", mb: 1 }}>
                    Amount
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 10,
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: "#",
                            display: "flex",
                            gap: 1,
                            flexDirection: "column",
                        }}
                    >
                        <Typography sx={{}}>
                            Amount requested
                        </Typography>
                        <Typography sx={{}}>Purpose of funds</Typography>

                    </Box>

                    <Box
                        sx={{
                            backgroundColor: "#",
                            display: "flex",
                            gap: 1,
                            flexDirection: "column",
                        }}
                    >
                        <Typography sx={{ color: "#667085" }}>{cashConverter(projCost)}</Typography>
                        <Typography sx={{ color: "#667085" }}>{projImpact}</Typography>

                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default ProjectDescription