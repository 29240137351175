import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, {
	stepConnectorClasses,
} from '@mui/material/StepConnector';
import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import backArrow from '../../assets/backArrow.svg';
import forwardArrow from '../../assets/forwardArrow.svg';
import Governance from './tables/governance/Governance';
import WaterSource from './tables/water_source_infrastracture/WaterSource';
import ApplicantParticulars from './tables/applicant_particulars/ApplicantParticulars';
import FinancialPosition from './tables/financial_position/FinancialPosition';
import FinancialType from './tables/type_of_financing/FinancialType';
import FinalTable from './tables/final_verdict/FinalTable';


const ColorlibConnector = styled(StepConnector)(() => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 22,
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor: 'var(--color-technical)',
			alignItems: 'center',
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor: 'var(--color-technical)',
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		height: 3,
		border: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.12)',

		borderRadius: 1,
	},
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
	backgroundColor:
		theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#fff',
	zIndex: 1,
	color: 'var(--color-technical)',
	fontSize: '17px',
	fontWeight: 600,
	width: 50,
	height: 50,
	display: 'flex',
	borderRadius: '50%',
	border: '1px solid var(--color-technical)',
	justifyContent: 'center',
	alignItems: 'center',
	cursor: 'pointer',
	...(ownerState.active && {
		backgroundColor: 'var(--color-active-stepper)',
		color: 'var(--color-technical)',
		fontWeight: 700,
		fontSize: '17px',
	}),
	...(ownerState.completed && {
		backgroundColor: 'var(--color-technical)',
		color: '#fff',
		fontWeight: 700,
		fontSize: '17px',
	}),
}));

const steps = [
	'Governance',
	'Water source & infrastructure',
	'Applicant particulars',
	'Summary of financial position',
	'Type of financing',
	'Final verdict',
];

export default function Screening() {
	const [activeStep, setActiveStep] = useState(0);
	const previousStep = useRef(0);

	function ColorlibStepIcon(props) {
		const { active, completed } = props;

		const icons = {
			1: 1,
			2: 2,
			3: 3,
			4: 4,
			5: 5,
			6: 6,
		};
		return (
			<ColorlibStepIconRoot
				ownerState={{ completed, active }}
				className={`${active ? '#fff' : 'green'}`}
			>
				{icons[String(props.icon)]}
			</ColorlibStepIconRoot>
		);
	}

	const handleNext = () => {
		setActiveStep(activeStep + 1);
	};

	const handleBack = () => {
		setActiveStep(activeStep - 1);
	};
	const handledispatchpatientDetails = () => {};

	const submitPatientDetails = () => {
		handleNext();
	};

	const submitTestDetails = () => {
		handleNext();
	};
	const submitClinicalNotes = () => {
		handleNext();
	};

	const submitReview = () => {
		console.log('reachedsubmitreview');
		handleNext();
	};

	const handleStep = (step) => () => {
		setActiveStep(step);
	};

	const ActiveStepContent = ({ step }) => {
		switch (step) {
			case 0:
				return <Governance steps={step} />;
			case 1:
				return <WaterSource steps={step} />;
			case 2:
				return <ApplicantParticulars steps={step} />;
			case 3:
				return <FinancialPosition steps={step} />;
			case 4:
				return <FinancialType steps={step} />;
			case 5:
				return <FinalTable steps={step} />;
			default:
				throw new Error('Unknown step');
		}
	};

	useEffect(() => {
		previousStep.current = activeStep;
	}, [activeStep]);

	return (
		<Stack spacing={8}>
			<Stepper
				alternativeLabel
				activeStep={activeStep}
				connector={<ColorlibConnector />}
				sx={{ width: '100%' }}
			>
				{steps.map((label, index) => (
					<Step key={label}>
						<StepLabel
							className='custom-stepper-screening'
							onClick={handleStep(index)}
							StepIconComponent={ColorlibStepIcon}
						>
							{label}
						</StepLabel>
					</Step>
				))}
			</Stepper>

			<div>
				<React.Fragment>
					<Box className='preq-steps'>
						<ActiveStepContent step={activeStep} />
					</Box>

					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							bgcolor: 'inherit',
						}}
					>
						{activeStep === 0 ? (
							<div
								className='flex items-center gap-20'
								style={{
									color: 'var(--color-technical)',
									fontSize: '18px',
									fontWeight: 500,
								}}
							>
								{activeStep + 1}/{steps.length}
								<img
									onClick={() => submitPatientDetails()}
									src={forwardArrow}
									alt='Forward'
									style={{ height: '48px', width: '48px', cursor: 'pointer' }}
								/>
							</div>
						) : null}

						{activeStep === 1 && (
							<div
								className='flex items-center gap-20'
								style={{
									color: 'var(--color-technical)',
									fontSize: '18px',
									fontWeight: 500,
								}}
							>
								<img
									onClick={handleBack}
									src={backArrow}
									alt='Backward'
									style={{ height: '48px', width: '48px', cursor: 'pointer' }}
								/>
								{activeStep + 1}/{steps.length}
								<img
									onClick={() => submitTestDetails()}
									src={forwardArrow}
									alt='Forward'
									style={{ height: '48px', width: '48px', cursor: 'pointer' }}
								/>
							</div>
						)}
						{activeStep === 2 && (
							<div
								className='flex items-center gap-20'
								style={{
									color: 'var(--color-technical)',
									fontSize: '18px',
									fontWeight: 500,
								}}
							>
								<img
									onClick={handleBack}
									src={backArrow}
									alt='Backward'
									style={{
										height: '48px',
										width: '48px',
										cursor: 'pointer',
										color: 'red',
									}}
								/>
								{activeStep + 1}/{steps.length}
								<img
									onClick={() => submitClinicalNotes()}
									src={forwardArrow}
									alt='Forward'
									style={{ height: '48px', width: '48px', cursor: 'pointer' }}
								/>
							</div>
						)}

						{activeStep === 3 && (
							<div
								className='flex items-center gap-20'
								style={{
									color: 'var(--color-technical)',
									fontSize: '18px',
									fontWeight: 500,
								}}
							>
								<img
									onClick={handleBack}
									src={backArrow}
									alt='Backward'
									style={{
										height: '48px',
										width: '48px',
										cursor: 'pointer',
										color: 'red',
									}}
								/>
								{activeStep + 1}/{steps.length}
								<img
									onClick={() => submitReview()}
									src={forwardArrow}
									alt='Forward'
									style={{ height: '48px', width: '48px', cursor: 'pointer' }}
								/>
							</div>
						)}

						{activeStep === 4 && (
							<div
								className='flex items-center gap-20'
								style={{
									color: 'var(--color-technical)',
									fontSize: '18px',
									fontWeight: 500,
								}}
							>
								<img
									onClick={handleBack}
									src={backArrow}
									alt='Backward'
									style={{
										height: '48px',
										width: '48px',
										cursor: 'pointer',
										color: 'red',
									}}
								/>
								{activeStep + 1}/{steps.length}
								<img
									onClick={() => handleNext()}
									src={forwardArrow}
									alt='Forward'
									style={{ height: '48px', width: '48px', cursor: 'pointer' }}
								/>
							</div>
						)}

						{activeStep === 5 && (
							<div
								className='flex items-center gap-20'
								style={{
									color: 'var(--color-technical)',
									fontSize: '18px',
									fontWeight: 500,
								}}
							>
								<img
									onClick={handleBack}
									src={backArrow}
									alt='Backward'
									style={{
										height: '48px',
										width: '48px',
										cursor: 'pointer',
										color: 'red',
									}}
								/>
								{activeStep + 1}/{steps.length}
							</div>
						)}
					</Box>
				</React.Fragment>
			</div>
		</Stack>
	);
}
