import React from 'react'
import ImpactGraph from './ImpactGraph'
import ResponsivePieChart from './ResponsivePieChart'
import { capitalize } from '../../../../utils';

const SectionTwo = ({ graphData, countyCount, selected, clientInfo }) => {

    const MonthlyViewButton = ({ onClick, active }) => (
        <button
            className={`bg-[#0170BD] text-white py-2 px-5 border border-[#0170BD] rounded-l-xl ${active ? 'font-bold' : ''}`}
            onClick={onClick}
        >
            Monthly View
        </button>
    );

    const AnnualViewButton = ({ onClick, active }) => (
        <button
            className={`bg-white text-blue-500 py-2 px-5 border border-blue-500 rounded-r-xl ${active ? 'font-bold' : ''}`}
            onClick={onClick}
        >
            Annual View
        </button>
    );

    const handleMonthlyClick = () => {
        // setViewMode('monthly');
        // onDataChange(generateMonthlyData());
    };

    const handleAnnualClick = () => {
        // setViewMode('annual');
        // onDataChange(generateAnnualData());
    };
    return (
        <div className='flex flex-row   w-[100%] h-[100%]'>
            <div className='flex w-[60%] px-2 bg-white mr-3 flex-col' >
                <div className='text-blk font-bold text-[1.125rem] mb-5 capitalize'>{capitalize(selected)} Impact</div>
                <div className='flex flex-row mb-10'>
                    <div className='text-blk mr-5 capitalize '>Total Impact across {`${selected === "Total" ? "counties" : capitalize(selected)}`}
                    </div>|
                    <div className='flex ml-2 mr-2'>
                        <div className='flex flex-row items-center'>
                            <div className='mt-1'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M11 8C11 9.65685 9.65685 11 8 11C6.34315 11 5 9.65685 5 8C5 6.34315 6.34315 5 8 5C9.65685 5 11 6.34315 11 8Z" fill="#1C1C1C" />
                                </svg>
                            </div>
                            <div>Target</div>
                        </div>
                        <div className='flex flex-row items-center ml-2'>
                            <div className='mt-1'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M11 8C11 9.65685 9.65685 11 8 11C6.34315 11 5 9.65685 5 8C5 6.34315 6.34315 5 8 5C9.65685 5 11 6.34315 11 8Z" fill="#A8C5DA" />
                            </svg></div>

                            <div>Outcome</div>
                        </div>
                    </div>



                </div>
                <div className="flex self-end mr-5">
                    <MonthlyViewButton onClick={handleMonthlyClick} active={false} />
                    <AnnualViewButton onClick={handleAnnualClick} active={true} />
                </div>
                <ImpactGraph graphData={graphData} />
            </div>
            <div className='flex w-[40%] flex-col p-4 bg-white'>
                <div className='text-blk font-bold text-[1rem]'>Contribution summary</div>
                <ResponsivePieChart selected={selected} clientInfo={clientInfo} countyCount={countyCount} />
            </div>
        </div>
    )
}

export default SectionTwo