export const CountyData = [
  {
    value: "Kajiado",
    label: "Kajiado",
  },
  {
    value: "Kiambu",
    label: "Kiambu",
  },
  {
    value: "Machakos",
    label: "Machakos",
  },
  {
    value: "Makueni",
    label: "Makueni",
  },

  {
    value: "Nairobi",
    label: "Nairobi",
  },

  {
    value: "Nakuru",
    label: "Nakuru",
  },
];
