import axios from 'axios';

const url = process.env.REACT_APP_API_BASE_URL;

const fetchAllWspOffers = async (data) => {
	if (data) {
		return axios
			.get(
				`${url}/usr/fetchLoanTermConditions.action?projAccId=${data}`,
			)
			.then((res) => res.data);
	}
	return null;
};


const offerService = {
	fetchAllWspOffers
};

export default offerService;
