import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { cashConverter } from "../../../utils";
import { useSelector } from "react-redux";


const TarrifTable = () => {
  const { singleProject } = useSelector((state) => state.global);

  const {
    projOtherV1,
    projOtherV2,
    projOtherCharge,
    projComV1,
    projComV2,
    projCommercialCharge,
    projInstV1,
    projInstV2,
    projIntitutionCharge,
    projKioskV1,
    projKioskV2,
    projKioskCharge,
    projIndV1,
    projIndV2,
    projIndividualCharge,
    projKioskRate,
    projInstitution,
    projCommercial,
    projIndividualRate,
    projTariffSystem,
  } = singleProject;

  function createTable(name, description) {
    return { name, description };
  }

  function volumeTable(item, v1, v2, charges) {
    return { item, v1, v2, charges };
  }

  const volumetricData = [
    volumeTable("Individual", projIndV1, projIndV2, projIndividualCharge),
    volumeTable("Kiosk", projKioskV1, projKioskV2, projKioskCharge),
    volumeTable(
      "Institution(school, HCF)",
      projInstV1,
      projInstV2,
      projIntitutionCharge
    ),
    volumeTable("Commerical", projComV1, projComV2, projCommercialCharge),
    volumeTable("Others", projOtherV1, projOtherV2, projOtherCharge),
  ];

  const flatRateData = [
    createTable("Individual", projIndividualRate),
    createTable("Kiosk", projKioskRate),
    createTable("Institution(school, HCF)", projInstitution),
    createTable("Commerical", projCommercial),
  ];
  return projTariffSystem !== "Volumetric" ? (
    <Table>
      <TableHead>
        <TableRow
          className='table'
        >
          <TableCell>
            Item
          </TableCell>
          <TableCell>
            Details
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody
        className='basic'
      >
        {flatRateData.map((row) => (
          <TableRow
            key={row.name}
          >
           <TableCell component="th" scope="row" sx={{ width:'20%', borderLeft: 'none !important' }}>
                {row.name}
              </TableCell>
            <TableCell
               align="left" 
               sx={{width:'30%'}}
            >
              {cashConverter(row.description)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  ) : (
    <Table>
      <TableHead>
        <TableRow
          lassName='table'
        >
          <TableCell>
            Type of customer         
             </TableCell>
          <TableCell>
            Volume(Minimum vol(v1))
          </TableCell>
          <TableCell>
            Volumee(Minimum vol(v2))
          </TableCell>
          <TableCell>
            Charge (Ksh/m3 (Minimum charge) )
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody
        className='basic'
      >
        {volumetricData.map((row) => (
          <TableRow
            key={row.item}
          >
            <TableCell component="th" scope="row" sx={{borderLeft: 'none !important' }}>
                {row.name}
              </TableCell>
            <TableCell
               align="left" 
            >
              {row.v1}
            </TableCell>
            <TableCell
              align="left" 
            >
              {row.v2}
            </TableCell>
            <TableCell
               align="left" 
            >
              {row.charges}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default TarrifTable;
