import React, { useState } from 'react'
import { styled, alpha } from '@mui/material/styles';
import { Box, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { Add, AttachFile, Close, FilterList, KeyboardArrowDown, Search } from '@mui/icons-material';
import { Button, Input } from '@mui/joy';

import Select, { selectClasses } from '@mui/joy/Select';
import './search.css'

import ViableTable from '../../../classification/ViableTable';
import FilterSection from '../../../../pages/expression_of_demand/components/FilterSection';
import ExportModal from '../../../../pages/expression_of_demand/components/ExportModal';
import CommittedTable from '../../../../pages/committedProjects/CommittedTable';
import ProjectTable from '../../../new_projects/ProjectTable';


const RedditTextField = styled((props) => (
    <TextField InputProps={{
        startAdornment: (
            <InputAdornment position="start">
                <Search sx={{ color: 'var(--color-technical)' }} />
            </InputAdornment>
        ),
        disableUnderline: true
    }} {...props} />
))(({ theme }) => ({
    '& .MuiFilledInput-root': {
        // border: "1px solid var(--color-primary-light)",
        overflow: 'hidden',
        borderRadius: 0,
        color: '#646464',
        fontSize: '15px !important',
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&.Mui-focused': {
            backgroundColor: 'transparent',
            // borderColor: theme.palette.primary.main,
            //   borderLeft: "3px solid var(--color-info)",
        },
    },
}));

function SearchField({ expressionData, category }) {
    const [searchField, setSearchField] = useState('')
    const [show, setShow] = useState(false)
    const [openExportDialog, setExportDialog] = useState(false);

    // const handleChange = (e) => {
    //     setFormData((prevState) => ({
    //         ...prevState,
    //         [e.target.name]: e.target.value,
    //     }));
    // };

    console.log("*************************************Show Category******************************************************", searchField);

    return (
        <>
            <Grid container spacing={0}>
                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center !important', textAlign: 'center' }} className='gap-10'>
                    <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center !important', }}>
                        <RedditTextField
                            onChange={(e) => setSearchField(e.target.value)}
                            label="Search"
                            placeholder='Try searching by Project or Assignee'
                            id="reddit-input-search"
                            variant="filled"
                            autoFocus
                            fullWidth
                            className='reddit-input-search'
                        />
                    </Grid>
                    <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center !important', }}>
                        {
                            !show ? (<>
                                <Button
                                    onClick={() => setShow(true)}
                                    sx={{
                                        height: '56px', color: 'inherit',
                                        borderRadius: '2px',
                                        backgroundColor: 'inherit !important',
                                        border: '1px solid var(--color-light-blue)'
                                    }}
                                    startDecorator={<FilterList sx={{ color: 'var(--color-technical)' }} />}>
                                    Filter
                                </Button></>) : (<>
                                    <Button
                                        onClick={() => setShow(false)}
                                        sx={{
                                            height: '56px', color: 'inherit',
                                            borderRadius: '2px',
                                            backgroundColor: 'inherit !important',
                                            border: '1px solid var(--color-light-blue)'
                                        }}
                                        startDecorator={<Close

                                        />}
                                    >
                                        Close Filter
                                    </Button>
                                </>)
                        }

                    </Grid>

                </Grid>
                {
                    (category == 'committed' || category == 'viable' || category == 'poor') ? (
                        null
                    ) : (
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button startDecorator={<AttachFile />}
                                sx={{
                                    fontSize: '15px', fontWeight: 500,
                                    backgroundColor: 'var(--color-technical)', height: '26px', mb: 4, borderRadius: '2px'
                                }}
                                onClick={() => setExportDialog(true)}
                            >
                                Export Applications
                            </Button>
                        </Grid>
                    )
                }


            </Grid>

            {
                show && (
                    <>
                        <FilterSection />
                    </>
                )
            }



            {
                (category == 'committed' || category == 'viable') ? (
                    <Box sx={{ mt: '44px' }}>
                        <CommittedTable expressionData={expressionData} searchField={searchField} category={category} />
                    </Box>
                ) : (category == 'active') ? (<ProjectTable tableData={expressionData}
                    assigned={[]}
                    search={searchField}
                    category={category}
                    url='active'
                    bank={true} />) : (
                    <Box sx={{ mt: '44px' }}>
                        <ViableTable expressionData={expressionData} searchField={searchField} category={category} />
                    </Box>
                )
            }


            <ExportModal open={openExportDialog} setDialog={setExportDialog} />
        </>
    )
}

export default SearchField
