import { Box, Typography } from '@mui/material';
import React from 'react';
import MatrixInput from './MatrixInput';
import UserMatrix from './UserMatrix';

const SingleTableItem = ({
	noCommentScore,
	name,
	stepName,
	id,
	maxScores,
	userComment,
	setMatrixInfo,
	matrixInfo,
	userMatrixInfo,
	setUserMatrixInfo,
}) => {
	return (
		<>
			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				{!noCommentScore && (
					<>
						{userComment[name] ? (
							<>
								<Typography>{userComment[name]}</Typography>
							</>
						) : (
							<Typography>No comment</Typography>
						)}

						<Box
							sx={{
								display: 'flex',
								mt: 2,
								mb: 2,
								gap: 6,
								alignItems: 'center',
							}}
						>
							<Typography
								className='gap-5'
								sx={{
									color: 'var(--color-blue-grey)',
									fontWeight: 500,
									fontSize: '14px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								System score:
								<MatrixInput
									setMatrixInfo={setMatrixInfo}
									matrixInfo={matrixInfo}
									name={name}
									stepName={stepName}
									id={id}
									maxScores={maxScores}
								/>
							</Typography>
							<Box
								sx={{
									width: '300px',
									display: 'flex',
									alignItems: 'center',
									bgColor: 'red',
									gap: 2,
								}}
							>
								<Typography
									sx={{
										display: 'flex',
										alignItems: 'center',
										gap: 1,
										color: 'var(--color-technical)',
									}}
								>
									Technical advisor score:
									<UserMatrix
										setMatrixInfo={setUserMatrixInfo}
										matrixInfo={userMatrixInfo}
										name={name}
										stepName={stepName}
										id={id}
										maxScores={maxScores}
									/>
								</Typography>
							</Box>
						</Box>
					</>
				)}
			</Box>
		</>
	);
};

export default SingleTableItem;
