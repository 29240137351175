import React, { useEffect, useState } from 'react'
import StatCard from '../../components/statsCard'
import { GiFinishLine, GiWaterGallon, GiInterstellarPath, GiSandsOfTime } from "react-icons/gi";
import { MdConstruction } from 'react-icons/md';
import { BiRegistered } from 'react-icons/bi';
import { TiCalculator } from 'react-icons/ti'
import { FaHandHoldingWater, FaHandsHelping } from 'react-icons/fa';
import { SiExpressvpn } from 'react-icons/si';
import { GrGrow } from 'react-icons/gr'
import { Box } from '@mui/material'
import StatTable from '../../components/statTable'
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrentUser, fetchWsp } from '../../../../features/auth/authSlice';
import { getExpressionDemandData } from '../../../../features/expression_demand/expressionDemandSlice';
import { cashConverter } from '../../../../utils';
import { useParams } from 'react-router-dom';

const Dashboard = () => {
    const dispatch = useDispatch();
    const { wspAccounts } = useSelector((state) => state.auth);

    const [complete, setComplete] = useState([]);
    const [incomplete, setIncomplete] = useState([]);
    const [viable, setViable] = useState([]);
    const [approved, setApproved] = useState([]);
    const [committed, setCommitted] = useState([]);
    const [draft, setDrafts] = useState([]);
    const { userId, accId } = useParams();


    async function fetchLoggedInUser() {
        await dispatch(
            fetchCurrentUser({
                accId: accId,
                usrId: userId,
            }),
        );
    }

    useEffect(() => {
        fetchLoggedInUser();
    }, []);

    useEffect(() => {
        fetchLoggedInUser();
    }, [accId, userId]);

    async function fetchAccounts() {
        const resp = await dispatch(fetchWsp());
        const res = await dispatch(getExpressionDemandData({ projStatus: "PENDING_APPROVAL" }))
        console.log(res.payload.data.result, "Here is Data");
        setComplete(res.payload.data.result);
        const inc = await dispatch(getExpressionDemandData({ projStatus: "ACTIVE" }));
        setIncomplete(inc.payload.data.result);
        const vbl = await dispatch(getExpressionDemandData({ projStatus: "DEAL_SOURCING" }));
        setViable(vbl.payload.data.result);
        const app = await dispatch(getExpressionDemandData({ projStatus: "APPROVED" }));
        setApproved(app.payload.data.result);
        const comm = await dispatch(getExpressionDemandData({ projStatus: "COMMITTED" }));
        setCommitted(comm.payload.data.result);
        const drt = await dispatch(getExpressionDemandData({ projStatus: "DRAFT" }));
        setDrafts(drt.payload.data.result);

    }

    // console.log(complete, "Complete Projects==========================")

    function findLatestAccCreatedAt(array) {
        let latestAccCreatedAt = 0; // Set an initial value for comparison

        for (const obj of array) {
            if (obj.accCreatedAt > latestAccCreatedAt) {
                latestAccCreatedAt = obj.accCreatedAt;
            }
        }

        return latestAccCreatedAt;
    }

    function calculateSumOfProjCost(array) {
        if (array.length) {
            let sum = 0;
            for (const obj of array) {
                sum += obj.projCost;
            }
            return sum;
        }
        return 0
    }

    function convertToMonthAndDay(timestamp) {
        const date = new Date(timestamp);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const dayWithSuffix = day + (day === 1 ? 'st' : day === 2 ? 'nd' : day === 3 ? 'rd' : 'th');
        return `${dayWithSuffix} ${month}`;
    }

    const latestAccCreatedAt = findLatestAccCreatedAt(wspAccounts);
    console.log("Latest accCreatedAt:", latestAccCreatedAt);
    const formattedDate = convertToMonthAndDay(latestAccCreatedAt);
    useEffect(() => {
        fetchAccounts();
    }, []);
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', gap: 5, justifyContent: 'space-between' }}>
                <StatCard url='ssp-accounts' icon={BiRegistered} title="WSP Sign ups" iconColor="#FFAE14" cashColor='#0873b9' cash={`last Registered on ${formattedDate}`} count={wspAccounts.length} />
                <StatCard url='complete' icon={GiFinishLine} title="Completed Projects" iconColor="#0E71F1" cashColor='#0873b9' cash={cashConverter(calculateSumOfProjCost(complete))} count={complete.length} />
                <StatCard url='viable' icon={FaHandHoldingWater} title="Viable Projects" iconColor="#11CB95" cashColor='#0873b9' cash={cashConverter(calculateSumOfProjCost(viable))} count={viable.length} />
                <StatCard url='incomplete' icon={GiWaterGallon} title="incomplete Projects" iconColor="#EA5453" cashColor='#0873b9' cash={cashConverter(calculateSumOfProjCost(incomplete))} count={incomplete.length + draft.length} />
            </Box>
            <Box sx={{ display: 'flex', gap: 3, mt: 3, justifyContent: 'space-between' }}>
                <StatTable title={"Expression of Demand"}
                    icon={GiInterstellarPath}
                    complete={"Complete Projects"}
                    incomplete={"Onboarding"}
                    draft={"Drafts"}
                    completeCash={`${complete.length}`}
                    incompleteCash={`${incomplete.length}`}
                    draftCash={`${draft.length}`}
                />
                <StatTable
                    icon={FaHandsHelping}
                    title={"Pipelines"}
                    complete={'Deal Sourcing'}
                    incomplete={"Project Preparations"}
                    completeCash={`${approved.length}`}
                    incompleteCash={`${committed.length}`}
                />
                <StatTable title={"Deal Room"} complete={"Viable Projects"}
                    icon={TiCalculator}
                    incomplete={"Offers"}
                    completeCash={`${viable.length}`}
                    incompleteCash={"0"}
                />
            </Box>
        </Box>
    )
}

export default Dashboard