import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Input, Typography } from '@mui/joy';
import { useState } from 'react';
import { Box, Divider, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
 
import { useEffect } from 'react';
import { setWaterDemand } from '../../features/committed_project/committedSlice';
import { setInstitutionDetails } from '../../features/partner/partnerSlice';
 
 



function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('ICs', 159, 6.0, 24, 4.0),
    createData('Kiosks', 237, 9.0, 37, 4.3),
];



export default function WaterDemandTable() {
    const { waterDemandAssesment, 
    } = useSelector((state) => state.committedProj)
    const dispatch = useDispatch()
    const [isUpdatingr, setIsUpdatingr] = useState(false);
    const [formData, setFormData] = useState(waterDemandAssesment)

    const { existingIcs, 
        existingKiosks, existingInstitution, existingProduction, existingNrw,
        existingConsumed, existingIc, existingZonal, existingMaster, futureIcs,
        futureKiosks, futureInstitution, futureProduction, futureNrw, futureConsumed,
        futureIc, futureZonal, futureMaster,
    } = formData
    const handleChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
        setIsUpdatingr(true);
    };

    useEffect(() => {
        if (!isUpdatingr) {
        } else {
            dispatch(setWaterDemand(formData))
        }
    }, [dispatch, isUpdatingr, formData]);
    return (
        <Paper sx={{
            p: 0, display: 'flex', flexDirection: 'column', borderTop: 'none', borderBottom: 'none', border: '1px solid var(--color-border-table)'
        }} elevation={0} variant="outlined" >

            <Box>
                <Grid container spacing={4}>
                    <Grid item xs={3}>
                        <Typography className="mitigation-title" sx={{ padding: '16px' }}>
                            Item
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography sx={{ padding: '16px', display: 'flex', alignItems: 'center' }} className="mitigation-title gap-2">
                            Unit
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography sx={{ padding: '16px', display: 'flex', alignItems: 'center' }} className="mitigation-title gap-2">
                            Existing
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography sx={{ padding: '16px', display: 'flex', alignItems: 'center' }} className="mitigation-title gap-2">
                            Future
                        </Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ borderColor: 'var(--color-border-table)' }} />
            </Box>

            <Box>
                <Grid container spacing={4}>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            No. of ICs
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            No.
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                    <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            <Input
                                onChange={handleChange}
                                name='existingIcs'
                                value={existingIcs}
                                className='fmw-input-joy-mitigation'
                            />
                            </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            <Input
                                onChange={handleChange}
                                name='futureIcs'
                                value={futureIcs}
                                className='fmw-input-joy-mitigation'
                            />
                        </Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ borderColor: 'var(--color-border-table)' }} />
            </Box>

             

            <Box>
                <Grid container spacing={4}>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            No. of Kiosks
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            No.
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            <Input
                                onChange={handleChange}
                                name='existingKiosks'
                                value={existingKiosks}
                                className='fmw-input-joy-mitigation'
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            <Input
                                onChange={handleChange}
                                name='futureKiosks'
                                value={futureKiosks}
                                className='fmw-input-joy-mitigation'
                            />
                        </Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ borderColor: 'var(--color-border-table)' }} />
            </Box>
            <Box>
                <Grid container spacing={4}>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            No. of Institutions
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            No.
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            <Input
                                onChange={handleChange}
                                name='existingInstitution'
                                value={existingInstitution}
                                className='fmw-input-joy-mitigation'
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            <Input
                                onChange={handleChange}
                                name='futureInstitution'
                                value={futureInstitution}
                                className='fmw-input-joy-mitigation'
                            />
                        </Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ borderColor: 'var(--color-border-table)' }} />
            </Box>

            <Box>
                <Grid container spacing={4}>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            Water Production
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            (m3/day)
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            <Input
                                onChange={handleChange}
                                name='existingProduction'
                                value={existingProduction}
                                className='fmw-input-joy-mitigation'
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            <Input
                                onChange={handleChange}
                                name='futureProduction'
                                value={futureProduction}
                                className='fmw-input-joy-mitigation'
                            />
                        </Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ borderColor: 'var(--color-border-table)' }} />
            </Box>
            <Box>
                <Grid container spacing={4}>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            NRW
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            (%)
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            <Input
                                onChange={handleChange}
                                name='existingNrw'
                                value={existingNrw}
                                className='fmw-input-joy-mitigation'
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            <Input
                                onChange={handleChange}
                                name='futureNrw'
                                value={futureNrw}
                                className='fmw-input-joy-mitigation'
                            />
                        </Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ borderColor: 'var(--color-border-table)' }} />
            </Box>
            <Box>
                <Grid container spacing={4}>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            Water consumed
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            (m3/day)
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            <Input
                                onChange={handleChange}
                                name='existingConsumed'
                                value={existingConsumed}
                                className='fmw-input-joy-mitigation'
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            <Input
                                onChange={handleChange}
                                name='futureConsumed'
                                value={futureConsumed}
                                className='fmw-input-joy-mitigation'
                            />
                        </Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ borderColor: 'var(--color-border-table)' }} />
            </Box>

            <Box>
                <Grid container spacing={4}>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            IC Meters
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            No.
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            <Input
                                onChange={handleChange}
                                name='existingIc'
                                value={existingIc}
                                className='fmw-input-joy-mitigation'
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            <Input
                                onChange={handleChange}
                                name='futureIc'
                                value={futureIc}
                                className='fmw-input-joy-mitigation'
                            />
                        </Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ borderColor: 'var(--color-border-table)' }} />
            </Box>

            <Box>
                <Grid container spacing={4}>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            Zonal Meters
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            No.
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            <Input
                                onChange={handleChange}
                                name='existingZonal'
                                value={existingZonal}
                                className='fmw-input-joy-mitigation'
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            <Input
                                onChange={handleChange}
                                name='futureZonal'
                                value={futureZonal}
                                className='fmw-input-joy-mitigation'
                            />
                        </Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ borderColor: 'var(--color-border-table)' }} />
            </Box>
            <Box>
                <Grid container spacing={4}>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            Master Meters
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            No.
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            <Input
                                onChange={handleChange}
                                name='existingMaster'
                                value={existingMaster}
                                className='fmw-input-joy-mitigation'
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className="typo-paragraph" sx={{ padding: '8px', height: '100%', borderRight: '1px solid var(--color-border-table)' }}>
                            <Input
                                onChange={handleChange}
                                name='futureMaster'
                                value={futureMaster}
                                className='fmw-input-joy-mitigation'
                            />
                        </Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ borderColor: 'var(--color-border-table)' }} />
            </Box>



        </Paper>

    );
}

    // <TableContainer component={Paper} elevation={0} sx={{background:'inherit'}}>
    //   <Table sx={{ minWidth: 650 }} aria-label="simple table">
    //     <TableHead>
    //       <TableRow className='table'>
    //         <TableCell>Item</TableCell>
    //         <TableCell align="left">Unit</TableCell>
    //         <TableCell align="left">Existing</TableCell>
    //         <TableCell align="left">Future</TableCell>
    //       </TableRow>
    //     </TableHead>
    //     <TableBody  className='basic' >
    //       {rows.map((row) => (
    //         <TableRow
    //           key={row.name}
    //         >
    //           <TableCell component="th" scope="row"   sx={{ borderLeft: 'none !important' }}>
    //             {row.name}
    //           </TableCell>
    //           <TableCell align="left" >{row.calories}</TableCell>
    //           <TableCell align="left">{row.fat}</TableCell>
    //           <TableCell align="left">{row.carbs}</TableCell>
    //         </TableRow>
    //       ))}
    //     </TableBody>
    //   </Table>
    // </TableContainer>