import { Col, Input, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { booleanData } from "../../../utils";
import ViewFileButton from "../../../components/ViewFileButton";

const selectIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='11'
    height='7'
    viewBox='0 0 11 7'
    fill='none'
  >
    <path
      d='M1.2925 0L5.5 4.32659L9.7075 0L11 1.33198L5.5 7L0 1.33198L1.2925 0Z'
      fill='#222222'
    />
  </svg>
);

export default function RiksMitigationPanel() {
  const { projectObj, projectPreparationDetails } = useSelector(
    (state) => state.obj
  );

  const [formObj, setformObj] = useState(projectPreparationDetails);

  useEffect(() => {}, [projectObj, formObj]);

  useEffect(() => {
    setformObj(projectPreparationDetails);
  }, [projectPreparationDetails]);

  return (
    <>
      <Row className='px-[2rem]'>
        <Col span={24}>
          <div className='panel_card w-full grid grid-cols-3 mb-[2rem] gap-x-[1rem]'>
            <span className='panel_txt_header'>Item</span>
            <span className='panel_txt_header'>Details</span>
            <span className='panel_txt_header'>File</span>
          </div>

          <div className='panel_card w-full grid grid-cols-3 mb-[2rem]'>
            <span className='panel_txt'>Risk Mitigation Plan provided</span>
            <Select
              disabled
              value={projectPreparationDetails?.wrRiskMitigationPlan}
              options={booleanData?.map((item) => {
                return {
                  label: item?.label,
                  value: item?.value,
                };
              })}
              status={
                projectPreparationDetails?.wrRiskMitigationPlan?.toUpperCase() ===
                "NO"
                  ? "error"
                  : "warning"
              }
              suffixIcon={selectIcon}
              className='select-status'
            />

            <ViewFileButton
              file={projectPreparationDetails?.wrRiskMitigationPlanFile}
            />
          </div>
        </Col>
      </Row>

      <Row className='px-[2rem] mt-[3.75rem]'>
        <Col span={24}>
          <span className='heading_6'>What are 5 main risks to BP?</span>
        </Col>
        <Col span={24}>
          <div className='panel_card w-full grid grid-cols-3 mb-[2rem] gap-x-[1rem]'>
            <span className='panel_txt_header'>Risk</span>
            <div className='panel_txt_header flex items-center'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='12'
                height='8'
                viewBox='0 0 12 8'
                fill='none'
              >
                <path
                  d='M12 3.34L0 3.33333V4.66667H12V3.34ZM0 6.66667H8V8H0V6.66667ZM12 0H0V1.34L12 1.33333V0Z'
                  fill='black'
                />
              </svg>
              <span className='ml-[.31rem]'>Likelihood</span>
            </div>
            <div className='panel_txt_header flex items-center'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='12'
                height='8'
                viewBox='0 0 12 8'
                fill='none'
              >
                <path
                  d='M12 3.34L0 3.33333V4.66667H12V3.34ZM0 6.66667H8V8H0V6.66667ZM12 0H0V1.34L12 1.33333V0Z'
                  fill='black'
                />
              </svg>
              <span className='ml-[.31rem]'>Mitigation measure</span>
            </div>
          </div>

          <div className='panel_card w-full grid grid-cols-3 mb-[2rem]'>
            <Input
              readOnly
              value={formObj?.wrRisk1}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.wrRisk1Likelyhood}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.wrRisk1Mitigation}
              className='input_borderless'
            />
          </div>

          <div className='panel_card w-full grid grid-cols-3 mb-[2rem]'>
            <Input
              readOnly
              value={formObj?.wrRisk2}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.wrRisk2Likelyhood}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.wrRisk2Mitigation}
              className='input_borderless'
            />
          </div>

          <div className='panel_card w-full grid grid-cols-3 mb-[2rem]'>
            <Input
              readOnly
              value={formObj?.wrRisk3}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.wrRisk3Likelyhood}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.wrRisk3Mitigation}
              className='input_borderless'
            />
          </div>

          <div className='panel_card w-full grid grid-cols-3 mb-[2rem]'>
            <Input
              readOnly
              value={formObj?.wrRisk4}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.wrRisk4Likelyhood}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.wrRisk4Mitigation}
              className='input_borderless'
            />
          </div>

          <div className='panel_card w-full grid grid-cols-3 mb-[2rem]'>
            <Input
              readOnly
              value={formObj?.wrRisk5}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.wrRisk5Likelyhood}
              className='input_borderless'
            />
            <Input
              readOnly
              value={formObj?.wrRisk5Mitigation}
              className='input_borderless'
            />
          </div>
        </Col>
      </Row>
    </>
  );
}
