import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { dateConverter} from '../../../../utils';
import SingleAttachmentComplete from '../../components/SingleAttachmentComplete';
import { useSelector } from 'react-redux';
 


function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}


export default function GovernanceInfoTable() {
  const { singleProject } = useSelector((state) => state.global);
  const rows = [
    createData('Type of registration', singleProject?.projRegType),
    createData('Registration number',  singleProject?.projRegNo ),
    createData('Date of registration',  singleProject?.projRegDate ? dateConverter(singleProject?.projRegDate) : "N/A",),
    createData('KRA pin', singleProject?.projKraPin ),
    createData('Period the organization has been providing water services', singleProject?.projOrgPeriod ),
    createData('1st statutory returns', singleProject?.projStatu1File),
    createData('2nd statutory returns',  singleProject?.projStatu2File),
    createData('3rd statutory returns', singleProject?.projStatu3File),
  ];
  return (
    <TableContainer component={Paper} elevation={0} sx={{background:'inherit'}}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow className='table'>
            <TableCell>Item</TableCell>
            <TableCell align="left">Details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody  className='basic'>
          {rows.map((row) => (
            <TableRow
              // key={row.name}
            >
              <TableCell component="th" scope="row"   sx={{ borderLeft: 'none !important',width:'50%' }}>
              {row.name}
              </TableCell>
              
               {
                row.name == '1st statutory returns' || row.name == '2nd statutory returns' || row.name == "3rd statutory returns" ? (
                  <TableCell align="left">
                  <SingleAttachmentComplete
                      title={row.calories}
                      tableFile="true"
                    />
                  </TableCell>
                ):(
 
              <TableCell component="th" scope="row" sx={{width:'50%'}}>
              {row.calories}
              </TableCell>
                )
               }
             
            </TableRow>
          ))}
        </TableBody>
      </Table> 
    </TableContainer>
  );
}