import React, { useEffect, useState } from 'react';
import { Textarea, Typography, colors } from '@mui/joy';
import { Box, Grid, TextareaAutosize } from '@mui/material';
import { toast } from 'react-toastify';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import styled from 'styled-components';
import {
	DragIndicator,
	Circle,
	ExpandLess,
	ExpandMore,
} from '@mui/icons-material';
import SingleItem from '../../components/single_item';
import SingleAttachment from '../../components/single_attachment';
import TextareaComment from '../../components/TextareaComment';
import { useLocation, useParams } from 'react-router-dom';
import GovernanceInfoTable from './GovernanceInfoTable';
import MeetingTable from './MeetingTable';
import DirectorsTable from './DirectorsTable';

import { useDispatch, useSelector } from 'react-redux';
import { dateConverter, formatImgPath, formatPath } from '../../../../utils';
import {
	fetchProjectScore,
	postScoreStep,
	setProjectScore,
} from '../../../../features/expression_demand/expressionDemandSlice';
import {
	fetchDirectors,
	fetchManagementTool,
} from '../../../../features/onboard_partner/onboardPartnerSlice';
import FileButton from '../../components/FileButton';
import ManagementTools from './ManagementTools';

const Governance = () => {
	const { singleProject } = useSelector((state) => state.global);
	const { scores } = useSelector((state) => state.expressionDemandData);
	const { managementTool } = useSelector((state) => state.onboardPartner);
	const dispatch = useDispatch();

	const [expanded, setExpanded] = React.useState('panel1');
	const location = useLocation();

	const [onBlur, setOnBlur] = useState(true);
	const [comment, setComment] = useState('');
	const [matrixInfo, setMatrixInfo] = useState({});
	const [userMatrixInfo, setUserMatrixInfo] = useState({});
	const [userComment, setUserComment] = useState({});

	const category = location.pathname.split('/')[3];
	const stepName = 'GOVERNANCE_INFO';
	const agmDate = singleProject?.projAgmMinutesDate
		? dateConverter(singleProject?.projAgmMinutesDate)
		: 'N/A';

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	function createData(
		name,
		description,
		variableName,
		maxScores,
		userScore,
		userComment,
	) {
		return {
			name,
			description,
			variableName,
			maxScores,
			userScore,
			userComment,
		};
	}

	const fetchProjectScores = async () => {
		const info = { projId: singleProject?.projId, page: stepName };
		await dispatch(fetchProjectScore(info));
		await dispatch(
			fetchManagementTool({
				projId: singleProject?.projId,
			}),
		);

		await dispatch(
			fetchDirectors({
				projId: singleProject?.projId,
			}),
		);
	};

	const values = Object.values(matrixInfo).map((v) => parseInt(v) || 0);

	const total = values.reduce((acc, val) => acc + val, 0);

	const handleTotalScores = () => {
		dispatch(setProjectScore({ [stepName]: total }));
	};

	function handleCommentScore() {
		scores?.forEach((score) => {
			const { scScore, scMethod, scUsrScore, scUsrComment } = score;
			setMatrixInfo((prevMatrixInfo) => ({
				...prevMatrixInfo,
				[scMethod]: scScore,
			}));
			setUserMatrixInfo((prevMatrixInfo) => ({
				...prevMatrixInfo,
				[scMethod]: scUsrScore,
			}));
			setUserComment((prevMatrixInfo) => ({
				...prevMatrixInfo,
				[scMethod]: scUsrComment,
			}));
		});
	}

	useEffect(() => {
		fetchProjectScores();
		handleCommentScore();
	}, []);

	useEffect(() => {
		handleCommentScore();
	}, [singleProject, scores, managementTool]);

	useEffect(() => {
		handleTotalScores();
	}, [total]);
	const governanceStatement = [
		createData(
			'Type of registration',
			singleProject?.projRegType,
			'projRegType',
			5,
		),
		createData(
			'Period the organization has been providing water services',
			singleProject?.projOrgPeriod,
			'projOrgPeriod',
			5,
		),
	];

	const attachments = [
		createData(
			'1st statutory returns',
			singleProject?.projStatu1File,
			'projStatu1File',
			1,
		),
		createData(
			'2nd statutory returns',
			singleProject?.projStatu2File,
			'projStatu2File',
			2,
		),
		createData(
			'3rd statutory returns',
			singleProject?.projStatu3File,
			'projStatu3File',
			2,
		),
	];

	const agmAttachments = [
		createData(
			`Minutes of last AGM held on ${agmDate}`,
			singleProject?.projMinAgmFile,
			'projMinAgmFile',
			10,
		),
		createData(
			'PDF of constitution/bylaws or articles of association',
			singleProject?.projAssocArtFile,
			'projAssocArtFile',
			5,
		),
		// createData(
		//   "PDF copy of minute stating project willing to seek a loan",
		//   singleProject?.projSgmFile,
		//   "projSgmFile"
		// ),
		createData(
			`Minutes of First committee meeting held on ${singleProject?.projMin1Date
				? dateConverter(singleProject?.projMin1Date)
				: ''
			}`,
			singleProject?.projMin1File,
			'projMin1File',
			1,
		),
		createData(
			`Minutes of second committee meeting held on ${singleProject?.projMin2Date
				? dateConverter(singleProject?.projMin2Date)
				: ''
			}`,
			singleProject?.projMin2File,
			'projMin2File',
			1,
		),
		createData(
			`Minutes of third committee meeting held on ${singleProject?.projMin3Date
				? dateConverter(singleProject?.projMin3Date)
				: ''
			}`,
			singleProject?.projMin3File,
			'projMin3File',
			1,
		),
	];

	const managementTools = managementTool?.map((item) => {
		let newItem = {};
		if (item.mtType === 'Operation and Maintenance Plan') {
			newItem = {
				...item,
				variableName: 'operationAndMaintenancePlan',
				maxScores: 1,
			};
		} else if (item.mtType === 'Strategic Plan') {
			newItem = { ...item, variableName: 'strategicPlan', maxScores: 1 };
		} else if (item.mtType === 'Marketing Plan') {
			newItem = { ...item, variableName: 'marketingPlan', maxScores: 1 };
		} else if (item.mtType === 'Customer Contract') {
			newItem = { ...item, variableName: 'customerContract', maxScores: 1 };
		} else if (item.mtType === 'Other') {
			newItem = {
				...item,
				variableName: 'other',
				maxScores: 1,
				mtType: 'Other Management tools',
			};
		}
		return newItem;
	});

	return (
		<Box sx={{ display: 'flex', gap: '30.03px', flexDirection: 'column' }}>
			{category == 'complete' || category == 'incomplete' ? (
				<Accordion
					expanded={expanded === 'panel1'}
					onChange={handleChange('panel1')}
					className='fmw-accrdion-table fmw-accrd'
					elevation={0}
				>
					<AccordionSummary
						expandIcon={
							<ExpandMore
								sx={{
									color:
										expanded == 'panel1' ? '#7E93AE' : 'var(--color-technical)',
								}}
								className='fmw-accrdion-svg'
							/>
						}
						aria-controls='panel1bh-content'
						id='panel1bh-header'
					>
						<Typography
							className='gap-5 accordion-title'
							sx={{
								width: '33%',
								flexShrink: 0,
								paddingBottom: '15px !important',
							}}
						>
							<DragIndicator className='fmw-accrdion-drag-svg' />
							Governance information
						</Typography>
					</AccordionSummary>
					<AccordionDetails sx={{ padding: '0px !important' }}>
						<GovernanceInfoTable />
					</AccordionDetails>
				</Accordion>
			) : (
				<Accordion
					expanded={expanded === 'panel1'}
					onChange={handleChange('panel1')}
					className='fmw-accrdion'
					elevation={0}
				>
					<AccordionSummary
						expandIcon={
							<ExpandMore
								sx={{
									color:
										expanded == 'panel1' ? '#7E93AE' : 'var(--color-technical)',
								}}
								className='fmw-accrdion-svg'
							/>
						}
						aria-controls='panel1bh-content'
						id='panel1bh-header'
					>
						<Typography
							className='gap-5 accordion-title'
							sx={{ width: '33%', flexShrink: 0 }}
						>
							<DragIndicator className='fmw-accrdion-drag-svg' />
							Governance information
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Box
							sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
						>
							<Box
								sx={{
									width: '70%',
									display: 'flex',
									background: 'var(--color-background-technical)',
									justifyContent: 'space-between',
									p: 3,
									fontWeight: 900,
									fontSize: '18px',
									borderRadius: '40px',
								}}
							>
								<Typography sx={{ fontWeight: 700, fontSize: '16px' }}>
									Registration No. | {singleProject?.projRegNo}
								</Typography>
								<Typography sx={{ fontWeight: 700, fontSize: '16px' }}>
									Registration Date |{' '}
									{dateConverter(singleProject?.projRegDate)}
								</Typography>
								<Typography sx={{ fontWeight: 700, fontSize: '16px' }}>
									KRA PIN | {singleProject?.projKraPin}
								</Typography>
							</Box>
							{governanceStatement?.map((row) => (
								<SingleItem
									row={row}
									userComment={userComment}
									setMatrixInfo={setMatrixInfo}
									matrixInfo={matrixInfo}
									name={row.variableName}
									stepName={stepName}
									id={singleProject?.projId}
									setUserMatrixInfo={setUserMatrixInfo}
									userMatrixInfo={userMatrixInfo}
								/>
							))}
							{attachments?.map((row) => (
								<SingleAttachment
									row={row}
									userComment={userComment}
									setUserComment={setUserComment}
									setMatrixInfo={setMatrixInfo}
									matrixInfo={matrixInfo}
									name={row.variableName}
									stepName={stepName}
									id={singleProject?.projId}
									setOnblur={setOnBlur}
									onBlur={onBlur}
									maxScores={row.maxScores}
									status={category}
									comment={comment}
									setComment={setComment}
									setUserMatrixInfo={setUserMatrixInfo}
									userMatrixInfo={userMatrixInfo}
								/>
							))}
						</Box>
					</AccordionDetails>
				</Accordion>
			)}

			{category == 'complete' || category == 'incomplete' ? (
				<Accordion
					expanded={expanded === 'panel2'}
					onChange={handleChange('panel2')}
					className='fmw-accrdion-table fmw-accrd'
					elevation={0}
				>
					<AccordionSummary
						expandIcon={
							<ExpandMore
								sx={{
									color:
										expanded == 'panel2' ? '#7E93AE' : 'var(--color-technical)',
								}}
								className='fmw-accrdion-svg'
							/>
						}
						aria-controls='panel2bh-content'
						id='panel2bh-header'
					>
						<Typography
							className='gap-5 accordion-title'
							sx={{
								width: '33%',
								flexShrink: 0,
								paddingBottom: '15px !important',
							}}
						>
							<DragIndicator className='fmw-accrdion-drag-svg' />
							Annual general meeting information
						</Typography>
					</AccordionSummary>
					<AccordionDetails sx={{ padding: '0px !important' }}>
						<MeetingTable />
					</AccordionDetails>
				</Accordion>
			) : (
				<Accordion
					expanded={expanded === 'panel2'}
					onChange={handleChange('panel2')}
					className='fmw-accrdion'
					elevation={0}
				>
					<AccordionSummary
						expandIcon={
							<ExpandMore
								sx={{
									color:
										expanded == 'panel2' ? '#7E93AE' : 'var(--color-technical)',
								}}
								className='fmw-accrdion-svg'
							/>
						}
						aria-controls='panel1bh-content'
						id='panel1bh-header'
					>
						<Typography
							className='gap-5 accordion-title'
							sx={{ width: '33%', flexShrink: 0 }}
						>
							<DragIndicator className='fmw-accrdion-drag-svg' />
							Annual general meeting information
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Box
							sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
						>
							<Box
								sx={{
									width: '100%',
									display: 'flex',
									background: 'var(--color-background-technical)',
									justifyContent: 'space-between',
									p: 3,
									fontWeight: 900,
									fontSize: '18px',
									borderRadius: '40px',
								}}
							>
								<Typography sx={{ fontWeight: 700, fontSize: '16px' }}>
									Annual General Meeting Date |{' '}
									{dateConverter(singleProject?.projAgmMinutesDate)}
								</Typography>
								<Typography
									sx={{
										fontWeight: 700,
										fontSize: '16px',
										display: 'flex',
										alignItems: 'center',
									}}
								>
									PDF copy of minute stating project willing to seek a loan |{' '}
									<FileButton
										title={formatPath(singleProject?.projSgmFile)}
										titlePreview={singleProject?.projSgmFile}
										viable='true'
									/>
								</Typography>
								<Typography
									sx={{ fontWeight: 700, fontSize: '16px' }}
								></Typography>
							</Box>

							{agmAttachments?.map((row) => (
								<SingleAttachment
									row={row}
									userComment={userComment}
									setUserComment={setUserComment}
									setMatrixInfo={setMatrixInfo}
									matrixInfo={matrixInfo}
									name={row.variableName}
									stepName={stepName}
									id={singleProject?.projId}
									setOnblur={setOnBlur}
									onBlur={onBlur}
									maxScores={row.maxScores}
									status={category}
									comment={comment}
									setComment={setComment}
									setUserMatrixInfo={setUserMatrixInfo}
									userMatrixInfo={userMatrixInfo}
								/>
							))}
						</Box>
					</AccordionDetails>
				</Accordion>
			)}

			<Accordion
				expanded={expanded === 'panel3'}
				onChange={handleChange('panel3')}
				className='fmw-accrdion-table fmw-accrd'
				elevation={0}
			>
				<AccordionSummary
					expandIcon={
						<ExpandMore
							sx={{
								color:
									expanded == 'panel3' ? '#7E93AE' : 'var(--color-technical)',
							}}
							className='fmw-accrdion-svg'
						/>
					}
					aria-controls='panel3bh-content'
					id='panel3bh-header'
				>
					<Typography
						className='gap-5 accordion-title'
						sx={{
							width: '53%',
							flexShrink: 0,
							paddingBottom: '15px !important',
						}}
					>
						<DragIndicator className='fmw-accrdion-drag-svg' />
						Details of management committee members/ directors
					</Typography>
				</AccordionSummary>
				<AccordionDetails sx={{ padding: '0px !important' }}>
					<DirectorsTable
						userComment={userComment}
						setUserComment={setUserComment}
						setMatrixInfo={setMatrixInfo}
						matrixInfo={matrixInfo}
						name='director'
						stepName={stepName}
						id={singleProject?.projId}
						status={category}
						comment={comment}
						setComment={setComment}
						setUserMatrixInfo={setUserMatrixInfo}
						userMatrixInfo={userMatrixInfo}
					/>
				</AccordionDetails>
			</Accordion>
			{category == 'complete' || category == 'incomplete' ? (
				<Accordion
					expanded={expanded === 'panel4'}
					onChange={handleChange('panel4')}
					className='fmw-accrdion-table fmw-accrd'
					elevation={0}
				>
					<AccordionSummary
						expandIcon={
							<ExpandMore
								sx={{
									color:
										expanded == 'panel4' ? '#7E93AE' : 'var(--color-technical)',
								}}
								className='fmw-accrdion-svg'
							/>
						}
						aria-controls='panel4bh-content'
						id='panel4bh-header'
					>
						<Typography
							className='gap-5 accordion-title'
							sx={{
								width: '33%',
								flexShrink: 0,
								paddingBottom: '15px !important',
							}}
						>
							<DragIndicator className='fmw-accrdion-drag-svg' />
							Management tools
						</Typography>
					</AccordionSummary>
					<AccordionDetails sx={{ padding: '0px !important' }}>
						<ManagementTools data={managementTools} />
					</AccordionDetails>
				</Accordion>
			) : (
				<Accordion
					expanded={expanded === 'panel4'}
					onChange={handleChange('panel4')}
					className='fmw-accrdion'
					elevation={0}
				>
					<AccordionSummary
						expandIcon={
							<ExpandMore
								sx={{
									color:
										expanded == 'panel4' ? '#7E93AE' : 'var(--color-technical)',
								}}
								className='fmw-accrdion-svg'
							/>
						}
						aria-controls='panel4bh-content'
						id='panel4bh-header'
					>
						<Typography
							className='gap-5 accordion-title'
							sx={{ width: '33%', flexShrink: 0 }}
						>
							<DragIndicator className='fmw-accrdion-drag-svg' />
							Management tools
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<ManagementTools
							userComment={userComment}
							setUserComment={setUserComment}
							setMatrixInfo={setMatrixInfo}
							matrixInfo={matrixInfo}
							name='director'
							stepName={stepName}
							id={singleProject?.projId}
							status={category}
							comment={comment}
							setComment={setComment}
							setUserMatrixInfo={setUserMatrixInfo}
							userMatrixInfo={userMatrixInfo}
						/>
					</AccordionDetails>
				</Accordion>
			)}


			{/* <Grid item xs={12}>
					<Grid item xs={12}>
						<TextareaComment comment={comment} />
					</Grid>
				</Grid> */}
		</Box>
	);
};

export default Governance;
