import { Box, Card, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'


const StatCard = ({ icon: Icon, title, iconColor, cashColor, cash, count, url }) => {
    return (

        <Card sx={{ display: 'flex', bgcolor: '#f8fdff', flexDirection: 'column', mt: 5, width: '25%', }}>
            <Link to={`/${url}`}>
                <Box sx={{ py: 2, px: 2, gap: 2 }}>
                    <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
                        <Icon color={iconColor} size={24} />
                        <Typography variant='h5' >
                            {title}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                        <Typography sx={{ color: cashColor }}>
                            {cash}
                        </Typography>
                        <Typography variant='h6' sx={{ color: iconColor, fontWeight: 700, }}>
                            {count}
                        </Typography>
                    </Box>
                </Box>
            </Link>
        </Card >


    )
}

export default StatCard