import { Box, Card, Divider, Typography } from '@mui/material'

import React from 'react'

const StatTable = ({ title, complete, incomplete, draft, completeCash, incompleteCash, draftCash, icon: Icon }) => {
    return (
        <Card sx={{ width: '35%', }}>
            <Box sx={{ display: 'flex', gap: 1, p: 1, alignItems: 'center' }}>
                < Icon size={28} />
                <Typography sx={{ fontWeight: 700, color: '#646464' }}>{title} </Typography>
            </Box>
            <Divider sx={{ mt: 1 }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2, py: 1 }}>
                <Typography > {complete}</Typography>
                <Typography>
                    {completeCash}
                </Typography>

            </Box>
            <Divider sx={{ mt: 1 }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2, py: 1 }}>
                <Typography >{incomplete}</Typography>
                <Typography>
                    {incompleteCash}
                </Typography>

            </Box>
            <Divider sx={{ mt: 1 }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2, py: 1 }}>
                <Typography >{draft}</Typography>
                <Typography>
                    {draft && draftCash}
                </Typography>

            </Box>

        </Card>
    )
}

export default StatTable