import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProjectPreparationStepOneView from "./ProjectPreparationStepOne";
import ProjectPreparationStepTwoView from "./ProjectPreparationStepTwo";
import ProjectPreparationStepThreeView from "./ProjectPreparationStepThree";
import { fetchProjectPreparationDetails } from "../../features/obj/objSlice";
import PageHeader from "../../components/PageHeader";
import { Breadcrumb } from "antd";

export default function ProjectPreparationView() {
  const dispatch = useDispatch();

  const { projectObj } = useSelector((state) => state.obj);

  const [current, setCurrent] = useState(0);

  const view = useRef();

  const next = () => {
    setCurrent(current + 1);
    view?.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const prev = () => {
    setCurrent(current - 1);
    view?.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  async function handleFetch() {
    await dispatch(fetchProjectPreparationDetails(projectObj?.projId));
  }

  useEffect(() => {}, [projectObj]);

  useEffect(() => {
    handleFetch();
  }, []);

  const breadList = [
    {
      title: "Home",
      href: "/",
    },
    {
      title: "Deal Room",
      href: "/deal-room/viable-projects",
    },
    {
      title: projectObj?.accTradeName?.toUpperCase(),
    },
  ];

  const steps = [
    {
      key: 0,
      title: "Step 1",
      content: <ProjectPreparationStepOneView next={next} />,
    },
    {
      key: 1,
      title: "Step 2",
      content: <ProjectPreparationStepTwoView next={next} prev={prev} />,
    },
    {
      key: 2,
      title: "Step 3",
      content: <ProjectPreparationStepThreeView prev={prev} />,
    },
  ];

  return (
    <div ref={view}>
      <PageHeader header={"Viable Project"} />
      <div className='mt-[.94rem]'>
        <Breadcrumb routes={breadList} />
      </div>

      <div className='mt-[2.65rem]'>{steps[current].content}</div>
    </div>
  );
}
