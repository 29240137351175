import { useEffect, useState } from 'react';
import GlobalSearch from '../../components/global/GlobalSearch';
import PageTitle from '../../components/global/PageTitle';
import { useDispatch } from 'react-redux';
import { getExpressionDemandData } from '../../features/expression_demand/expressionDemandSlice';
import AssigneePriorityTable from '../../components/table_priority_assigned/AssigneePriorityTable';


export default function ViableProjects() {
    const dispatch = useDispatch()

    const [search, setsearch] = useState('');
    const [data, setdata] = useState([])

    function getSearchValue(val) {
        setsearch(val);
    }

    async function fetchCompleteExpressions() {
        const resp = await dispatch(getExpressionDemandData({ projStatus: "DEAL_SOURCING" }))
        await setdata(resp?.payload?.data?.result)
    }

    useEffect(() => {
        fetchCompleteExpressions()
    }, [])

    useEffect(() => {

    }, [data])

    return (
        <>
            <PageTitle
                currentPage='Deal Room'
                subSubTitle={'viable projects'}
                urlSubTitle={'/viable'}
            />
            <GlobalSearch getSearchValue={getSearchValue} />
            <AssigneePriorityTable
                tableData={data}
                assigned={[]}
                search={search}
                category={null}
                url='viable'
            />
        </>
    );
}
