import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { handleFailure } from "../utils";
import expressionDemandService from "./expressionDemandService";

// const url = process.env.REACT_APP_API_BASE_URL

const initialState = {
  message: "",
  loading: false,
  isSuccess: false,
  isError: false,
  error: "",
  expressionData: [],
  filteredExpressionData: [],
  directorsInformation: [],
  toolTipData: [],
  storageInformation: [],
  pipeInformations: [],
  bankInformation: [],
  budgetItemInformations: [],
  filterDetails: {},
  pdfInteractions: [],
  filterParam: "",
  formData: ["Hello world", "Hello", "Nice"],
  pdfData: {},
  openPDfDialog: false,
  scores: [],
  projectScore: {},
  stepScore: [],
  projectCounter: 0,
  pagination: {
    start: 0,
    limit: 10,
  },

  completeActive: false,
  inCompleteActive: false,
  viableActive: false,
  assistanceActive: false,
  poorActive: false,
  committedActive: false,
  priority: {},

  isErrorPriority: false,
  isProcessingPriority: false,
  isSuccessPriority: false,
  messagePriority: "",
};

export const getPdfInteractions = createAsyncThunk(
  "expressionDemandSlice/getPdfInteractions",
  async (formData, thunkAPI) => {
    try {
      return await expressionDemandService.getPdfInteractions(formData);
    } catch (err) {
      const message = handleFailure(err);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const postPdfInteractions = createAsyncThunk(
  "expressionDemandSlice/postPdfInteractions",
  async (formData, thunkAPI) => {
    try {
      return await expressionDemandService.postPdfInteractions(formData);
    } catch (err) {
      const message = handleFailure(err);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getExpressionDemandData = createAsyncThunk(
  "expressionDemandSlice/getExpressionDemandData",
  async (filterData, thunkAPI) => {
    const state = thunkAPI.getState();
    const { filterParam } = state.expressionDemandData;
    let finalFilter = {};

    finalFilter = { ...filterData };

    const queryString = `?${Object.entries(finalFilter)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&")}`;

    try {
      return await expressionDemandService.getExpressionData(queryString);
    } catch (err) {
      const message = handleFailure(err);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDirectorInformation = createAsyncThunk(
  "expressionDemandSlice/getDirectorInformation",
  async (projId, thunkAPI) => {
    try {
      return await expressionDemandService.getDirectorInformation(projId);
    } catch (err) {
      const message = handleFailure(err);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getStorageInformations = createAsyncThunk(
  "expressionDemandSlice/getStorageInformations",
  async (projId, thunkAPI) => {
    try {
      return await expressionDemandService.getStorageInformation(projId);
    } catch (err) {
      const message = handleFailure(err);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPipeInformations = createAsyncThunk(
  "expressionDemandSlice/getPipeInformations",
  async (projId, thunkAPI) => {
    try {
      return await expressionDemandService.getPipeInformation(projId);
    } catch (err) {
      const message = handleFailure(err);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getbankInformation = createAsyncThunk(
  "expressionDemandSlice/getbankInformation",
  async (projId, thunkAPI) => {
    try {
      return await expressionDemandService.getBankInformation(projId);
    } catch (err) {
      const message = handleFailure(err);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getBugetItemInformations = createAsyncThunk(
  "expressionDemandSlice/getBugetItemInformations",
  async (projId, thunkAPI) => {
    try {
      return await expressionDemandService.getBudgetItemsInformation(projId);
    } catch (err) {
      const message = handleFailure(err);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const postToolTipData = createAsyncThunk(
  "expressionDemandSlice/postToolTipData",
  async (toolData, thunkAPI) => {
    try {
      return await expressionDemandService.postToolTipData(toolData);
    } catch (err) {
      const message = handleFailure(err);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const postProjectScore = createAsyncThunk(
  "expressionDemandSlice/postProjectScore",
  async (toolData, thunkAPI) => {
    try {
      return await expressionDemandService.postProjectScore(toolData);
    } catch (err) {
      const message = handleFailure(err);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const postScoreStep = createAsyncThunk(
  "expressionDemandSlice/postScoreStep",
  async (toolData, thunkAPI) => {
    try {
      return await expressionDemandService.postScoreStep(toolData);
    } catch (err) {
      const message = handleFailure(err);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchProjectScore = createAsyncThunk(
  "expressionDemandSlice/fetchProjectScore",
  async (data, thunkAPI) => {
    try {
      return await expressionDemandService.fetchProjectScore(data);
    } catch (err) {
      const message = handleFailure(err);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getToolTipData = createAsyncThunk(
  "expressionDemandSlice/getToolTipData",
  async (projId, thunkAPI) => {
    try {
      return await expressionDemandService.getToolTipData(projId);
    } catch (err) {
      const message = handleFailure(err);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const postAttachmentAlt = createAsyncThunk(
  "expressionDemandSlice/postAttachmentAlt",
  async (formData, thunkAPI) => {
    try {
      return await expressionDemandService.postAttachmentAlt(formData);
    } catch (err) {
      const message = handleFailure(err);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchScoreStep = createAsyncThunk(
  "expressionDemandSlice/fetchScoreStep",
  async (data, thunkAPI) => {
    try {
      return await expressionDemandService.fetchScoreStep(data);
    } catch (err) {
      const message = handleFailure(err);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//saveProjectPriority
export const saveProjectPriority = createAsyncThunk(
  "expressionDemand/saveProjectPriority",
  async (_formData, thunkAPI) => {
    try {
      return await expressionDemandService.saveProjectPriority(_formData);
    } catch (error) {
      const message = handleFailure(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// fetchProjectPriority
export const fetchProjectPriority = createAsyncThunk(
  "expressionDemand/fetchProjectPriority",
  async (projId, thunkAPI) => {
    try {
      return await expressionDemandService.fetchProjectPriority(projId);
    } catch (error) {
      const message = handleFailure(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const expressionDemandSlice = createSlice({
  name: "expressionDemandData",
  initialState,
  reducers: {
    resetData: (state) => {
      state.expressionData = [];
    },
    resetLoader: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.loading = false;

      state.isErrorPriority = false;
      state.isProcessingPriority = false;
      state.isSuccessPriority = false;
      state.messagePriority = "";
    },
    setFilterParams: (state, action) => {
      state.filterParam = action.payload;
    },
    setPdfData: (state, action) => {
      state.pdfData = action.payload;
    },
    setOpenPDfDialog: (state, action) => {
      state.openPDfDialog = action.payload;
    },
    setProjectScore: (state, action) => {
      state.projectScore = {
        ...state.projectScore,
        ...action.payload,
      };
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setFilterDetails: (state, action) => {
      state.filterDetails = action.payload;
    },
    resetFilterDetails: (state, action) => {
      state.filterDetails = {};
    },
    setCompleteActive: (state, action) => {
      state.completeActive = action.payload;
    },
    setInCompleteActive: (state, action) => {
      state.inCompleteActive = action.payload;
    },
    setViableActive: (state, action) => {
      state.viableActive = action.payload;
    },
    setAssistanceActive: (state, action) => {
      state.assistanceActive = action.payload;
    },
    setPoorActive: (state, action) => {
      state.poorActive = action.payload;
    },
    setCommittedActive: (state, action) => {
      state.committedActive = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder

      // Get Pdf Interactions Data
      .addCase(getPdfInteractions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPdfInteractions.fulfilled, (state, action) => {
        state.pdfInteractions = action.payload.data.result;
        state.isSuccess = true;
      })
      .addCase(getPdfInteractions.rejected, (state, action) => {
        state.isError = true;
        state.loading = false;
      })

      // Post Pdf Interactions
      .addCase(postPdfInteractions.pending, (state) => {
        state.loading = true;
      })
      .addCase(postPdfInteractions.fulfilled, (state, action) => {
        state.message = action.payload.messages;
        state.isSuccess = true;
      })
      .addCase(postPdfInteractions.rejected, (state, action) => {
        state.isError = true;
        state.loading = false;
      })

      // Get Project Expression Data
      .addCase(getExpressionDemandData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getExpressionDemandData.fulfilled, (state, action) => {
        state.loading = true;
        state.expressionData = [];
        state.projectCounter = action.payload.data.count;
        state.expressionData = action.payload.data.result;
        state.loading = false;
        state.error = "";
      })
      .addCase(getExpressionDemandData.rejected, (state, action) => {
        state.loading = false;
        state.users = [];
        state.error = action.error.message;
      })
      // Post Tooltip Data

      .addCase(postToolTipData.pending, (state) => {
        state.loading = true;
      })
      .addCase(postToolTipData.fulfilled, (state, action) => {
        state.message = action.payload.messages;
        state.isSuccess = true;
      })
      .addCase(postToolTipData.rejected, (state, action) => {
        state.isError = true;
        state.loading = false;
      })
      // Get ToolTip Data
      .addCase(getToolTipData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getToolTipData.fulfilled, (state, action) => {
        state.toolTipData = action.payload.data.result;
        state.loading = false;
      })
      .addCase(getToolTipData.rejected, (state, action) => {
        state.loading = false;
      })

      // Get Directors information

      .addCase(getDirectorInformation.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDirectorInformation.fulfilled, (state, action) => {
        state.loading = false;
        state.directorsInformation = action.payload.data.result;
        state.isSuccess = true;
        state.message = action.payload.messages;
      })
      .addCase(getDirectorInformation.rejected, (state, action) => {
        state.loading = false;
      })

      // Get  Storage information

      .addCase(getStorageInformations.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStorageInformations.fulfilled, (state, action) => {
        state.loading = false;
        state.storageInformation = action.payload.data.result;
        state.isSuccess = true;
        state.message = action.payload.messages;
      })
      .addCase(getStorageInformations.rejected, (state, action) => {
        state.loading = false;
      })

      //get Pipe Informations

      .addCase(getPipeInformations.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPipeInformations.fulfilled, (state, action) => {
        state.loading = false;
        state.pipeInformations = action.payload.data.result;
        state.isSuccess = true;
        state.message = action.payload.messages;
      })
      .addCase(getPipeInformations.rejected, (state, action) => {
        state.loading = false;
      })

      // Get Bank Account Information

      .addCase(getbankInformation.pending, (state) => {
        state.loading = true;
      })
      .addCase(getbankInformation.fulfilled, (state, action) => {
        state.loading = false;
        state.bankInformation = action.payload.data.result;
        state.isSuccess = true;
        state.message = action.payload.messages;
      })
      .addCase(getbankInformation.rejected, (state, action) => {
        state.loading = false;
      })

      // Get Budget Item Information

      .addCase(getBugetItemInformations.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBugetItemInformations.fulfilled, (state, action) => {
        state.loading = false;
        state.budgetItemInformations = action.payload.data.result;
        state.isSuccess = true;
        state.message = action.payload.messages;
      })
      .addCase(getBugetItemInformations.rejected, (state, action) => {
        state.loading = false;
      })
      // Fetch Project Scores
      .addCase(fetchProjectScore.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProjectScore.fulfilled, (state, action) => {
        state.loading = false;
        state.scores = action.payload.data.result;
        state.isSuccess = true;
        state.message = action.payload.messages;
      })
      .addCase(fetchProjectScore.rejected, (state, action) => {
        state.loading = false;
      })

      // POST Project Scores
      .addCase(postProjectScore.pending, (state) => {
        state.loading = true;
      })
      .addCase(postProjectScore.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.message = action.payload.messages;
      })
      .addCase(postProjectScore.rejected, (state, action) => {
        state.loading = false;
      })

      // POST Scores Step
      .addCase(postScoreStep.pending, (state) => {
        state.loading = true;
      })
      .addCase(postScoreStep.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.message = action.payload.messages;
      })
      .addCase(postScoreStep.rejected, (state, action) => {
        state.loading = false;
      })

      // POST Scores Step
      .addCase(postAttachmentAlt.pending, (state) => {
        state.loading = true;
      })
      .addCase(postAttachmentAlt.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.message = action.payload.messages;
      })
      .addCase(postAttachmentAlt.rejected, (state, action) => {
        state.loading = false;
      })

      // Fetch step Scores
      .addCase(fetchScoreStep.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchScoreStep.fulfilled, (state, action) => {
        state.loading = false;
        state.stepScore = action.payload.data.result;
        state.isSuccess = true;
        state.message = action.payload.messages;
      })
      .addCase(fetchScoreStep.rejected, (state, action) => {
        state.loading = false;
      })

      //  saveProjectPriority
      .addCase(saveProjectPriority.pending, (state) => {
        state.isProcessingPriority = true;
      })
      .addCase(saveProjectPriority.fulfilled, (state, action) => {
        state.isProcessingPriority = false;
        state.isSuccessPriority = true;
        state.messagePriority = "Priority saved successfully";
        state.priority = action.payload;
      })
      .addCase(saveProjectPriority.rejected, (state, action) => {
        state.isProcessingPriority = false;
        state.isErrorPriority = true;
        state.priority = {};
        state.messagePriority = action.payload;
      })

      //fetchProjectPriority
      .addCase(fetchProjectPriority.pending, (state) => {
        state.isProcessingPriority = true;
      })
      .addCase(fetchProjectPriority.fulfilled, (state, action) => {
        state.isProcessingPriority = false;
        state.isSuccessPriority = true;
        state.priority = action.payload;
      })
      .addCase(fetchProjectPriority.rejected, (state, action) => {
        state.isProcessingPriority = false;
        state.isErrorPriority = true;
        state.priority = {};
        state.messagePriority = action.payload;
      });
  },
});

export default expressionDemandSlice.reducer;
export const {
  reset,
  setSelectedUser,
  setFilterParams,
  setPagination,
  setProjectScore,
  setOpenPDfDialog,
  setFilterDetails,
  setPdfData,
  setFilteredData,
  resetLoader,
  resetSelectedUser,
  setCompleteActive,
  setInCompleteActive,
  setViableActive,
  setAssistanceActive,
  setPoorActive,
  setCommittedActive,
} = expressionDemandSlice.actions;
