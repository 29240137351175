import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SingleAttachmentComplete from '../../components/SingleAttachmentComplete';
import NoTableDataComponent from '../../components/NoTableDataComponent';
import SingleTableItem from '../../components/single_table_item';

export default function ManagementTools({ data, userComment,
  setUserComment,
  setMatrixInfo,
  matrixInfo,
  name,
  stepName,
  id,
  status,
  comment,
  setComment,
  setUserMatrixInfo,
  userMatrixInfo }) {
  const { status: category } = useParams();
  const { sLoadingManagementTool, managementTool } = useSelector(
    (state) => state.onboardPartner
  );
  const managementTools = managementTool?.map((item) => {
    let newItem = {};
    if (item.mtType === "Operation and Maintenance Plan") {
      newItem = {
        ...item,
        variableName: "operationAndMaintenancePlan",
        maxScores: 1,
      };
    } else if (item.mtType === "Strategic Plan") {
      newItem = { ...item, variableName: "strategicPlan", maxScores: 1 };
    } else if (item.mtType === "Marketing Plan") {
      newItem = { ...item, variableName: "marketingPlan", maxScores: 1 };
    } else if (item.mtType === "Customer Contract") {
      newItem = { ...item, variableName: "customerContract", maxScores: 1 };
    } else if (item.mtType === "Other") {
      newItem = {
        ...item,
        variableName: "other",
        maxScores: 1,
        mtType: "Other Management tools",
      };
    }
    return newItem;
  });

  return (
    <TableContainer component={Paper} elevation={0} sx={{ background: 'inherit' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow className='table'>
            <TableCell>Item</TableCell>
            <TableCell align="left">Details</TableCell>

          </TableRow>
        </TableHead>
        {managementTools.length > 0 ? (<TableBody className='basic'>
          {managementTools.map((row) => (
            <TableRow
              key={row.mtFile}
            >
              <TableCell component="th" scope="row" sx={{ borderLeft: 'none !important' }}>
                {row.mtType}
              </TableCell>
              <TableCell component="th" scope="row"  >

                <SingleAttachmentComplete
                  title={row.mtFile}
                  tableFile="true"
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>) : <NoTableDataComponent />}
      </Table>
      {
        (category == "Viable" || category == "Partner") && (
          <Box sx={{ padding: '20px', mt: 2 }}>

            <SingleTableItem
              overallComment="true"
              userComment={userComment}
              setUserComment={setUserComment}
              setMatrixInfo={setMatrixInfo}
              matrixInfo={matrixInfo}
              name="director"
              stepName={stepName}
              id={data?.projId}

              maxScores="5"
              status={category}
              comment={comment}
              setComment={setComment}

              setUserMatrixInfo={setUserMatrixInfo}
              userMatrixInfo={userMatrixInfo}

            />
          </Box>
        )
      }

    </TableContainer>
  );
}