import { useEffect, useState } from 'react';
import GlobalSearch from '../../components/global/GlobalSearch';
import PageTitle from '../../components/global/PageTitle';
import { useDispatch } from 'react-redux';
import { getExpressionDemandData } from '../../features/expression_demand/expressionDemandSlice';
import AssigneePriorityTable from '../../components/table_priority_assigned/AssigneePriorityTable';

export default function InComplete() {
	const dispatch = useDispatch();

	const [search, setsearch] = useState('');
	const [data, setdata] = useState([]);

	function getSearchValue(val) {
		setsearch(val);
	}

	async function fetchCompleteExpressions() {
		const resp = await dispatch(
			getExpressionDemandData({ projStatus: 'DRAFT' }),
		);
		const res = await dispatch(
			getExpressionDemandData({ projStatus: 'ACTIVE' }),
		);
		if (res?.payload?.success) {
			await setdata([
				...resp?.payload?.data?.result,
				...res?.payload?.data?.result,
			]);
		}
		
	}

	useEffect(() => {
		fetchCompleteExpressions();
	}, []);

	useEffect(() => {}, [data]);

	return (
		<>
			<PageTitle
				currentPage='Expression of demand'
				subSubTitle={'incomplete'}
				subTitle={'Incomplete'}
				urlSubTitle={'/incomplete'}
			/>
			<GlobalSearch getSearchValue={getSearchValue} />
			<AssigneePriorityTable
				tableData={data}
				assigned={[]}
				search={search}
				category={null}
				url='incomplete'
			/>
		</>
	);
}
