import {

  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Button, Textarea } from "@mui/joy";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import {
  DragIndicator,
  ExpandMore,
} from "@mui/icons-material"
import { fetchProjectScore, fetchScoreStep, postProjectScore, postScoreStep } from "../../../../features/expression_demand/expressionDemandSlice";
import NoTableDataComponent from "../../components/NoTableDataComponent";
import TextareaComment from "../../components/TextareaComment";

const FinalTable = () => {
  const { singleProject } = useSelector((state) => state.global);
  const { scores, stepScore, loading } = useSelector((state) => state.expressionDemandData);

  const stepName = "FINAL_VERDICT";
  const [expanded, setExpanded] = React.useState('panel1');
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }

  const [comment, setComment] = useState("");
  const dispatch = useDispatch();

  const totalScore = stepScore?.reduce((accumulator, currentValue) => {
    if (currentValue.sctStep === "FINAL_VERDICT") {
      return accumulator;
    }
    return accumulator + currentValue.sctTotalScore;
  }, 0);

  const fetchProjectScores = async () => {
    const info = { projId: singleProject?.projId, page: stepName };
    await dispatch(fetchProjectScore(info));
    await dispatch(fetchScoreStep(info));
  };

  const finalData = [];

  stepScore &&
    stepScore?.forEach((item) => {
      let newData = {};
      if (item?.sctStep === "GOVERNANCE_INFO") {
        newData = {
          name: "Governance information",
          comment: item?.sctComment ?? "N/A",
          marks: `${item?.sctTotalScore} points`,
        };
      } else if (item?.sctStep === "PROJECT_DESCRIPTION") {
        newData = {
          name: "Existing Project Description & Infrastructure",
          comment: item?.sctComment ?? "N/A",
          marks: `${item?.sctTotalScore} points`,
        };
      } else if (item?.sctStep === "CUSTOMER_DETAIL") {
        newData = {
          name: "Customer Information",
          comment: item?.sctComment ?? "N/A",
          marks: `${item?.sctTotalScore} points`,
        };
      } else if (item?.sctStep === "LOAN_INFO") {
        newData = {
          name: "Loan Information",
          comment: item?.sctComment ?? "N/A",
          marks: `${item?.sctTotalScore} points`,
        };
      } else if (item?.sctStep === "FINANCIAL_SUMMARY") {
        newData = {
          name: "Financial Information",
          comment: item?.sctComment ?? "N/A",
          marks: `${item?.sctTotalScore} points`,
        };
      }
      if (item?.sctStep !== "FINAL_VERDICT") {
        finalData.push(newData);
      }

      // return newData;
    });


  useEffect(() => {
    fetchProjectScores();
  }, []);

  useEffect(() => {
  
  }, [stepScore])

  return (
    <>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          className='fmw-accrdion-table fmw-accrd'
          elevation={0}
        >
          <AccordionSummary
            expandIcon={<ExpandMore sx={{ color: expanded == 'panel1' ? '#7E93AE' : 'var(--color-technical)' }}
              className='fmw-accrdion-svg'
            />}
            aria-controls='panel1bh-content'
            id='panel1bh-header'
          >

            <Typography
              className='accordion-title'
              sx={{ width: '33%', flexShrink: 0, paddingBottom: '15px !important', fontSize: '18px', fontWeight: 500 }}>
              <DragIndicator className='fmw-accrdion-drag-svg' sx={{ marginRight: '1.25rem' }} />
              Final verdict
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: '0px !important' }}>
            <Table>
              <TableHead sx={{ height: "20px" }}>
                <TableRow
                >
                  <TableCell sx={{ fontSize: '14px', fontWeight: '500', }}>
                    Step
                  </TableCell>
                  <TableCell sx={{ fontSize: '14px', fontWeight: '500', }}>
                    Comments
                  </TableCell>
                  <TableCell sx={{ fontSize: '14px', fontWeight: '500', }}>
                    Total score
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  borderLeft: "1px solid #E4E4E4",
                  borderRight: "1px solid #E4E4E4",
                }}
              >
                {finalData?.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{
                      borderLeft: "1px solid #E4E4E4",
                      borderRight: "1px solid #E4E4E4",
                    }}
                    style={{ borderBottom: "1px solid #E4E4E4" }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderLeft: "1px solid #E4E4E4",
                        borderRight: "1px solid #E4E4E4",
                        fontSize: '16px', fontWeight: '400'
                      }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        borderLeft: "1px solid #E4E4E4",
                        borderRight: "1px solid #E4E4E4",
                        fontSize: '16px', fontWeight: '400',
                      }}
                    >
                      {row.comment}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderRight: "1px solid #E4E4E4", // add this style to create the right border for the last cell in each row
                        fontSize: '16px', fontWeight: '400',
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {row.marks}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>


      <Button
        sx={{
          backgroundColor: '#44BA41 !important', width: '244px', height: '50px',
          fontSize: '18px', fontWeight: 500, borderRadius: '0px!important', mt: 5, mb: 7
        }}

      >

          Total score: {totalScore} Points

      </Button>


    </>
  );
};

export default FinalTable;
