import { Textarea, Typography } from '@mui/joy';
import React from 'react';
import { useLocation } from 'react-router-dom';

function TextareaComment({ comment }) {
	return (
		<>
			<Textarea
				readOnly
				minRows={4}
				type='text'
				name='projSummary'
				value={comment}
				placeholder={comment ? comment : 'No comment'}
				size='lg'
				className='fmw-input-joy-comment'
			/>
		</>
	);
}

export default TextareaComment;
