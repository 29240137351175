import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CircularProgress, Grid, MenuItem, Paper } from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import { Box } from '@mui/system';
import { Checkbox, Link, Typography, Button, Input, TextField } from '@mui/joy';
import './verify.css';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { login, resendLoginOTP, reset } from '../../features/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import { CssVarsProvider } from '@mui/joy/styles';
import { sendOTP } from '../../features/wsp/wspSlice';
import axios from 'axios';
import CountUp from 'react-countup';

const RedditTextField = styled((props) => (
	<Input InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
	'& .MuiFilledInput-root': {
		border: '1px solid var(--color-primary-light)',
		overflow: 'hidden',
		borderRadius: 0,
		color: '#646464',
		fontSize: '15px !important',
		backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
		transition: theme.transitions.create([
			'border-color',
			'background-color',
			'box-shadow',
		]),
		'&:hover': {
			backgroundColor: 'transparent',
		},
		'&.Mui-focused': {
			backgroundColor: 'transparent',
			// borderColor: theme.palette.primary.main,
			borderLeft: '3px solid var(--color-info)',
		},
	},
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
		maxWidth: 'md',
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
}));

function BootstrapDialogTitle(props) {
	const { children, onClose, ...other } = props;
	return (
		<DialogTitle sx={{ m: 0, p: 2, pt: 4 }} {...other}>
			<IconButton
				aria-label='close'
				onClick={onClose}
				sx={{ position: 'absolute', left: 22, top: 15, color: '#2C2C2C' }}
			>
				<ArrowBackIcon />
			</IconButton>
			{children}
			{onClose ? (
				<IconButton
					aria-label='close'
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 15,
						top: 10,
						color: (theme) => theme.palette.grey[500],
						display: 'none',
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
}

BootstrapDialogTitle.propTypes = {
	children: PropTypes.node,
	onClose: PropTypes.func.isRequired,
};

const VerifyLogin = ({
	message,
	open,
	handleClickOpen,
	user,
	handleClose,
	timer,
}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [loadingResend, setLoadingResend] = useState(false);
	const { resetOtpCode, isSuccessResendOtp } = useSelector(
		(state) => state.auth,
	);
	const { isSuccessSend } = useSelector((state) => state.wsp);
	const [counter, setCounter] = useState(60);

	const [formData, setFormData] = useState({
		usrOtp: '',
	});
	const API_URL = `${process.env.REACT_APP_API_BASE_URL}`;

	const { usrOtp } = formData;

	const handleChange = (e) => {
		setFormData((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	const signIn = (e) => {
		e.preventDefault();
		if (!usrOtp) {
			toast.warning("Please fill in verification code");
		} else if (open && usrOtp != user.usrCode) {
			toast.error("OTP not verified");
		} else if (user.usrChannel == null || user.usrChannel == "") {
			navigate("/verify-email");
		} else if (open) {
			dispatch(
				sendOTP({
					usrId: user.usrId,
					usrSalt: user.usrCode,
				})
			);
			toast.success('Login successful ');
			if (user.usrJbrnId == "0") {
				navigate("/support-project");
			} else if (user.usrJbrnId == "1") {
				navigate("/wsp-home");
			} else if (user.usrJbrnId == "3") {
				navigate("/impact-funder-account");
			}
			else {
				navigate("/expression-demand/complete");
			}
		}
	};


	const resend = () => {
		setLoadingResend(true);
		const response = axios
			.post(
				`${API_URL}/usr/resend_otp_sign_in.action?usrId=${user.usrId}`,
				{
					headers: {
						Accept: 'application/json',
					},
				},
			)
			.then((response) => {
				if (response.data.messages.message == 'OTP sent successfully.') {
					setLoadingResend(false);
					toast.success('Check your phone again');
					setCounter(60);
				} else {
				}
			})
			.catch((error) => {
				setLoadingResend(false);
				// console.log("error",error)
				toast.error('Click resend again');
			});
	};
	// dispatch(resendLoginOTP({
	//   usrId:user.usrId
	// }))
	// }

	useEffect(() => {
		if (open && counter > 0) {
			const interval = setInterval(() => {
				setCounter((prev) => prev - 1);
			}, 1000);
			return () => clearInterval(interval);
		}
	}, [counter, open]);

	useEffect(() => {
		setCounter(60);
	}, [open]);

	useEffect(() => {
		//   setTimeout(() => {
		//     setTimer(true)
		// },50000)

		// if (isSuccessResendOtp) {
		//     toast.success(message)
		// }
		// if (isSuccess && usrOtp) {
		//   console.log("success")
		//     // toast.success(message)
		//     //  navigate('/wsp-home');
		// }
		return () => {
			dispatch(reset());
		};
	}, [user, navigate, dispatch]);

	// console.log("Channel", user.usrChannel)

	return (
		<>
			<BootstrapDialog
				// onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={open}
				fullWidth={true}
				maxWidth="sm"
			>
				<BootstrapDialogTitle
					id="customized-dialog-title"
					onClose={handleClose}
				/>


				<DialogContent sx={{ mx: 5, my: 3 }} className='signin-dialog'>
					<form onSubmit={signIn}>
						<Typography component='h4' className='meta-title signin-title'>
							Verify login
						</Typography>

						<Typography className='highlighter signin-h' />

						<Typography component='p' className='verify-signin-tagline'>
							Please enter the verification code sent to your mobile number to confirm login
						</Typography>
						<Input
							label=''
							type='text'
							value={usrOtp}
							name='usrOtp'
							onChange={handleChange}
							placeholder='OTP'
							size='lg'
							className='fmw-verify-input'
						/>
						<Typography component='p'>
							Resend PIN in:{' '}
							<span style={{ fontWeight: 'bold' }}>
								{counter}
								{/* <CountUp
                start={60}
                end={0}
                duration={60}
                // onEnd={() => console.log('Ended! 👏')}
              /> */}
							</span>
						</Typography>

						<Grid container spacing={3} style={{ marginTop: '8rem' }}>
							<Grid item xs={6}>
								{!loadingResend ? (
									<Button
										className='sign-btn'
										onClick={resend}
										disabled={counter > 0}
									>
										Resend
									</Button>
								) : (
									<Button
										disabled
										variant='contained'
										sx={{ textTransform: 'none' }}
									>
										<CircularProgress size={17} color='inherit' />
										&nbsp;Loading...
									</Button>
								)}
							</Grid>
							<Grid
								item
								xs={6}
								style={{ display: 'flex', justifyContent: 'flex-end' }}
							>
								<Button className='sign-btn' type='submit'>
									Verify
								</Button>
							</Grid>
						</Grid>
					</form>
				</DialogContent>

				<DialogActions></DialogActions>
			</BootstrapDialog>

			<CssVarsProvider />
		</>
	);
};

export default VerifyLogin;
