import { Button, Typography } from "@mui/material";
import React from "react";
import { formatImgPath, formatPath, handleOpenDoc, previewPaper } from "../../utils";
import { Visibility } from "@mui/icons-material";


function FileButton({ title, titlePreview, viable }) {
  return (
    <>
      {title !== '' ? (
        <Button
          variant='plain'
          style={{ textTransform: "capitalize", padding: viable ? "3px 10px" : "3px 0px" }}
          onClick={() => previewPaper(titlePreview)}
        >
          <Typography sx={{ display: "flex", gap: 1 }}>
            <
              Visibility
              sx={{ color: 'var(--color-technical)', fontSize: '23px', }}
            />
            <Typography sx={{ color: 'var(--color-technical)', textDecoration: "underline" }}>
              View File
            </Typography>
          </Typography>
        </Button>
      ) : (
        <Typography sx={{ marginLeft: '15px' }}>No File</Typography>
      )}
    </>
  );
}

export default FileButton;
