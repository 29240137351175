import { Typography } from '@mui/joy';
import React from 'react';

const UserMatrix = ({ matrixInfo, name }) => {
	return (
		<>
			<Typography sx={{ color: 'var(--color-technical)' }}>
				{matrixInfo[name] ? matrixInfo[name] : 'N/A'}
			</Typography>
		</>
	);
};

export default UserMatrix;
