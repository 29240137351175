import {createSlice,createAsyncThunk} from '@reduxjs/toolkit';
import { handleFailure } from '../utils';
import supportService from './supportService';

const initialState={
    isError:false,
    isProcessing: true,
    isLoading:false,
    isSuccess:false,
    accounts:[],
}

export const fetchSupportAccounts = createAsyncThunk('supportSlice/fetchAccounts',async(data,thunkAPI)=>{
    try {
        return await supportService.fetchAccounts(data)
    } catch (err) {
        const message = handleFailure(err)
        return thunkAPI.rejectWithValue(message)
    }
});

export const fetchSupportProjects = createAsyncThunk('supportSlice/fetchProjects',async(data,thunkAPI)=>{
    try {
        return await supportService.fetchProjects(data)
    } catch (err) {
        const message = handleFailure(err)
        return thunkAPI.rejectWithValue(message)
    }
});

 
export const supportSlice = createSlice({
     name:'support',
     initialState,
     reducers:{
         reset:(state)=>{
            state.isLoading=false
            state.isProcessing=false
            state.isError=false
            state.isSuccess=false
         },
     },
     extraReducers:(builder)=>{
         builder

         // fetch Accounts 
        .addCase(fetchSupportAccounts.pending, (state)=>{
            state.isProcessing=true
        })
        .addCase(fetchSupportAccounts.fulfilled, (state,action) =>{
            // console.log("Here is the Data in payload:*************** " + action.payload.data.result)
            state.isProcessing=false
            state.isSuccess=true
            state.accounts= action.payload.data.result
        })
        .addCase(fetchSupportAccounts.rejected,(state,action)=>{
            state.isProcessing=false
            state.isError=true
            state.message=action.payload
        })

           // fetch Projects
           .addCase(fetchSupportProjects.pending, (state)=>{
            state.isProcessing=true
        })
        .addCase(fetchSupportProjects.fulfilled, (state,action) =>{
            console.log("Here is the Data in payload:*************** " + action.payload.data.result)
            state.isProcessing=false
            state.isSuccess=true
            state.accounts= action.payload.data.result
        })
        .addCase(fetchSupportProjects.rejected,(state,action)=>{
            state.isProcessing=false
            state.isError=true
            state.message=action.payload
        })
     }
 })

export const {reset } = supportSlice.actions
export default supportSlice.reducer