import React from "react";
import Header from "../../components/header/Header";
import { Box, Button, Container, Grid, Paper, Stack } from "@mui/material";
import Sewage from "../../assets/img/desin1-2.png";
// import Sewage from '../../assets/img/water-treatment-plant-for-sewage-waste-water-2021-09-03-14-41-10-utc.png';
import BusinessMen from "../../assets/img/pexels-rodnae-productions-7841475.png";
import HandsTogether from "../../assets/img/many-people-hands-holding-a-jigsaw-puzzle-in-circl-2021-10-21-03-31-55-utc.png";
import ComputerPhoto from "../../assets/img/pexels-photo-8062289/pexels-photo-8062289.png";
import Impact from "../../assets/img/Group 671.png";
import { Typography } from "@mui/joy";
import Footer from "../../components/footer/Footer";
import Part from "../../assets/img/Partners.png";
import Fund from "../../assets/img/Project.png";
import { useNavigate } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";

const Home = () => {
	const navigate = useNavigate();
	return (
		<>
			<Header />
			<div className='' style={{ backgroundColor: '#FDFDFD' }}>
				<div className='Box'>
					<img
						className='hidden relative lg:block xl:block z-10'
						src={Sewage}
						alt='Pic 1'
						style={{ height: '74vh', width: '100%', objectFit: 'cover' }}
					/>
				</div>
				<div className='box overlay z-50'>
					<Box
						className='z-50 relative w-full top-14 lg:-mt-[200px] lg:left-[70px] lg:w-[50%] lg:h-[33%] !bg-[#0069DF] p-5'
						// sx={{
						//   position: "absolute",
						//   top: 465,
						//   // top: 370,
						//   left: 70,
						//   width: "50%",
						//   height: "33%",
						//   backgroundColor: "#0069DF",
						//   padding: "3px",
						// }}
					>
						<Box
							sx={{
								backgroundColor: '#26DEFF',
								my: 2,
								p: 1.5,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								width: 140,
								height: 'fit-content',
								ml: 4,
							}}
						>
							<Typography
								sx={{
									color: '#FFFFFF',
									textAlign: 'left',
									fontSize: '18px',
									fontWeight: 700,
									fontFamily: 'inherit',
									// ml: 1,
								}}
							>
								FundMyWater
							</Typography>
						</Box>
						<Typography
							sx={{
								textAlign: 'left',
								fontSize: '24px',
								fontWeight: 700,
								// fontFamily: 'Helvetica Neue, sans-serif',
                fontFamily: 'inherit',
								color: '#FFFFFF',
								letterSpacing: '0px',
								mx: 4,
								my: 3,
							}}
						>
							The innovative finance matchmaking platform that's revolutionizing
							the way water infrastructure investments are made.
						</Typography>
					</Box>
				</div>

        <main className="mt-[20%]  md:mt-[10%] lg:mt-[6%] xl:mt-[6%] w-full">
          <Container
          // sx={{
          //   width: "900px",
          //   mt: 13,
          // }}
          >
            <Box
              className="p-2 bg-[#0069DF] w-[100px] h-4 mb-2"
              // sx={{
              //   p: 0.5,
              //   backgroundColor: "#0069DF",
              //   width: "8%",
              //   height: "5%",
              // }}
            />

            <Typography //className="p-4 w-full mt-20 lg:p-1 bg-[#0069DF] lg:w-[8%] lg:h-[5%] "
           
              sx={{
              textAlign: "left",
              letterSpacing: "0px",
              color: "#484848",
              my: 2,
              fontFamily: "Roboto, sans-serif",
              fontWeight: "500",
            }}
            >
              FundMyWater is an innovative finance matchmaking platform that
              tackles the financing challenges of small-scale water
              infrastructure projects in Kenya. FundMyWater leverages technology
              to make water infrastructure investments fast, collaborative,
              secure, and economically viable for financiers, funders, and
              service providers. Our digital platform helps small water service
              providers to raise blended capital for investment in
              climate-resilient infrastructure and access to water services
              through
            </Typography>

            <div className="grid grid-cols-1 lg:grid-cols-3 gap-16 mt-14">
              <Box
                sx={{
                  mt: 3,
                }}
              >
                <Typography
                  sx={{
                    color: "#0069DF",
                    letterSpacing: "0px",
                    fontFamily: "Roboto, sans-serif",
                    fontWeight: "900",
                    fontSize: "20px",
                  }}
                >
                  1. <br />
                  Deal sourcing and Matchmaking Services
                </Typography>
                <br />
                <Typography
                  sx={{
                    textAlign: "left",
                    font: "normal normal normal 14px Roboto, sans-serif",
                    letterSpacing: "0px",
                    color: "#646464",
                    height: "190px",
                  }}
                >
                  We connect lenders to a pool of potential water infrastructure
                  projects by finding, screening, and profiling project
                  bankability and sustainability metrics for commercial
                  financing. Through our digital platform, we help lenders
                  secure quality deals efficiently by reducing costs associated
                  with pipeline development e.g. origination costs, expertise,
                  and turn-around time.
                </Typography>
                <Box
                  sx={{
                    mt: 6.5,
                  }}
                >
                  <img src={BusinessMen} alt="" width="100%" height="250px" />
                </Box>
              </Box>

              <Box
                sx={{
                  backgroundColor: "#F7F7F7",
                  p: 3,
                  // m: 4,
                }}
              >
                <Typography
                  sx={{
                    color: "#0069DF",
                    letterSpacing: "0px",
                    fontFamily: "Roboto, sans-serif",
                    fontWeight: "900",
                    fontSize: "20px",
                  }}
                >
                  2. <br />
                  Blended financing for social impact
                </Typography>
                <br />
                <Typography
                  sx={{
                    textAlign: "left",
                    font: "normal normal normal 14px Roboto, sans-serif",
                    letterSpacing: "0px",
                    color: "#646464",
                    height: "190px !important",
                  }}
                >
                  Our secure transaction deal room facilitates collaboration
                  between lenders and grants funders to co-develop and finance
                  projects in a unified and standardized way. We facilitate; (a)
                  preparation and access to standardized project documentation
                  including technical feasibility reports and financial models;
                  (b) collateralization of project cashflows via an end-to-end
                  billing, collection, and payment system; (c) real-time
                  performance monitoring of projects in the lifecycle of the
                  financing via a dynamic dashboard.
                </Typography>
                <Box
                  sx={{
                    mt: 6,
                  }}
                >
                  <img
                    className="!h-[374px]"
                    src={HandsTogether}
                    alt=""
                    width="100%"
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  mt: 3,
                }}
              >
                <Typography
                  sx={{
                    color: "#0069DF",
                    letterSpacing: "0px",
                    fontFamily: "Roboto, sans-serif",
                    fontWeight: "900",
                    fontSize: "20px",
                  }}
                >
                  3. <br />
                  Impact management
                </Typography>
                <br />
                <Typography
                  sx={{
                    textAlign: "left",
                    font: "normal normal normal 14px Roboto, sans-serif",
                    letterSpacing: "0px",
                    color: "#646464",
                    height: "190px",
                  }}
                >
                  Over and above deal discovery, screening, and due diligence,
                  the platform leverages technology to track and report the
                  direct impact of the water infrastructure projects on the
                  community in real-time to all stakeholders. FundMyWater will
                  illustrate the value for money to all its partners by
                  identifying and quantifying the positive effects of the
                  financed projects on society
                </Typography>
                <Box
                  sx={{
                    mt: 10,
                  }}
                >
                  <img src={ComputerPhoto} alt="" width="100%" height="250px" />
                </Box>
                {/* <Box
									sx={{
										width: '100%',
									}}
								>
									<img src={Impact} alt='' style={{ width: '100%' }} />
								</Box> */}
              </Box>
            </div>

            {/* <Grid container spacing={1} >
							<Grid item md={4} xs={12}>
								<Box
									sx={{
										mt: 5,
									}}
								>
									<Typography
										sx={{
											color: '#0069DF',
											letterSpacing: '0px',
											fontFamily: 'Roboto, sans-serif',
											fontWeight: '900',
											fontSize: '20px',
										}}
									>
										1. <br />
										Deal sourcing and Matchmaking Services
									</Typography>
									<br />
									<Typography
										sx={{
											textAlign: 'left',
											font: 'normal normal normal 14px Roboto, sans-serif',
											letterSpacing: '0px',
											color: '#646464',
										}}
									>
										We connect lenders to a pool of potential water
										infrastructure projects by finding, screening, and profiling
										project bankability and sustainability metrics for
										commercial financing. Through our digital platform, we help
										lenders secure quality deals efficiently by reducing costs
										associated with pipeline development e.g. origination costs,
										expertise, and turn-around time.
									</Typography>
									<Box
										sx={{
											mt: 12,
										}}
									>
										<img src={BusinessMen} alt='' width='100%' height='150%' />
									</Box>
								</Box>
							</Grid>
							<Grid item md={4} xs={12}>
								<Box
									sx={{
										backgroundColor: '#F7F7F7',
										// p: 3,
										// m: 4,
									}}
								>
									<Typography
										sx={{
											color: '#0069DF',
											letterSpacing: '0px',
											fontFamily: 'Roboto, sans-serif',
											fontWeight: '900',
											fontSize: '20px',
										}}
									>
										2. <br />
										Blended financing for social impact
									</Typography>
									<br />
									<Typography
										sx={{
											textAlign: 'left',
											font: 'normal normal normal 14px Roboto, sans-serif',
											letterSpacing: '0px',
											color: '#646464',
										}}
									>
										Our secure transaction deal room facilitates collaboration
										between lenders and grants funders to co-develop and finance
										projects in a unified and standardized way. We facilitate;
										(a) preparation and access to standardized project
										documentation including technical feasibility reports and
										financial models; (b) collateralization of project cashflows
										via an end-to-end billing, collection, and payment system;
										(c) real-time performance monitoring of projects in the
										lifecycle of the financing via a dynamic dashboard.
									</Typography>
									<Box
										sx={{
											mt: 4,
										}}
									>
										<img
											src={HandsTogether}
											alt=''
											width='100%'
											height='150%'
										/>
									</Box>
								</Box>
							</Grid>
							<Grid item md={4} xs={12}>
								<Box
									sx={{
										// p: 3,
										// m: 4,
									}}
								>
									<Typography
										sx={{
											color: '#0069DF',
											letterSpacing: '0px',
											fontFamily: 'Roboto, sans-serif',
											fontWeight: '900',
											fontSize: '20px',
										}}
									>
										3. <br />
										Impact management
									</Typography>
									<br />
									<Typography
										sx={{
											textAlign: 'left',
											font: 'normal normal normal 14px Roboto, sans-serif',
											letterSpacing: '0px',
											color: '#646464',
										}}
									>
										Over and above deal discovery, screening, and due diligence,
										the platform leverages technology to track and report the
										direct impact of the water infrastructure projects on the
										community in real-time to all stakeholders. FundMyWater will
										illustrate the value for money to all its partners by
										identifying and quantifying the positive effects of the
										financed projects on society
									</Typography>
									<Box
										sx={{
											mt: 15.5,
										}}
									>
										<img
											src={ComputerPhoto}
											alt=''
											width='100%'
											height='150%'
										/>
									</Box>
									<Box
										sx={{
											width: '100%',
										}}
									>
										<img src={Impact} alt='' style={{ width: '100%' }} />
									</Box>
								</Box>
							</Grid>
						</Grid> */}
          </Container>

          <Container
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              my: 3,
            }}
          >
            <Typography
              sx={{
                color: "#0069DF",
                letterSpacing: "0px",
                fontFamily: "Roboto, sans-serif",
                fontWeight: "900",
                fontSize: "28px",
                my: 5,
              }}
            >
              How it works
            </Typography>
          </Container>

          <Box
            sx={{
              backgroundColor: "#0069DF",
              width: "100%",
              height: "440px",
            }}
          >
            <Container
              maxWidth="lg"
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                my: 3,
              }}
            >
              <Typography
                sx={{
                  color: "#FFFFFF",
                  letterSpacing: "0px",
                  fontFamily: "Roboto, sans-serif",
                  fontWeight: "600",
                  fontSize: "20px",
                  mt: 8,
                }}
              >
                Get project funded
              </Typography>

							<Splide
								options={{
									rewind: true,
									width: '100%',
									perPage: 1,
									speed: 600,
									pagination: false,
									autoplay: true,
									pauseOnHover: true,
									// pauseOnFocus: true,
									type: 'loop',
								}}
								className='mt-10 lg:hidden'
								aria-label='Application process'
							>
								<SplideSlide>
									<Box
										className='pl-16 w-[98vw] h-[150px] bg-[#ffffff] flex justify-center flex-col justify-self-start '
										sx={{
											boxShadow: '0px 23px 26px #00000017',
										}}
									>
										<Typography
											sx={{
												color: '#0069df',
												fontWeight: 900,
												fontSize: '18px',
												mt: 2,
												mb: 0.5,
											}}
										>
											Step 1
										</Typography>
										<Box
											sx={{
												p: 0.2,
												backgroundColor: '#00c3e6',
												width: '9%',
												height: '0.1%',
												mb: 2,
											}}
										/>
										<Typography
											sx={{
												textAlign: 'left',
												fontSize: '17px',
												fontWeight: 'medium',
												fontFamily: 'Roboto, sans-serif',
												letterSpacing: '0px',
												color: '#484848',
											}}
										>
											Projection application
										</Typography>
									</Box>
								</SplideSlide>
								<SplideSlide>
									<Box
										className='pl-16 w-[98vw] h-[150px] bg-[#ffffff] flex justify-center  flex-col justify-self-start'
										sx={{
											boxShadow: '0px 23px 26px #00000017',
											background:
												'linear-gradient(180deg, #26deff 0%, #00baba 100%)',
										}}
									>
										<Typography
											sx={{
												color: '#ffffff',
												fontWeight: 900,
												fontSize: '18px',
												mt: 2,
												mb: 0.5,
											}}
										>
											Step 2
										</Typography>
										<Box
											sx={{
												p: 0.2,
												backgroundColor: '#0069df',
												width: '9%',
												height: '0.1%',
												mb: 2,
											}}
										/>
										<Typography
											sx={{
												textAlign: 'left',
												fontSize: '17px',
												fontWeight: 'medium',
												fontFamily: 'Roboto, sans-serif',
												letterSpacing: '0px',
												color: '#ffffff',
											}}
										>
											Screening of projects
										</Typography>
									</Box>
								</SplideSlide>

                <SplideSlide>
                  <Box
                    className="pl-16 w-[98vw] h-[150px] bg-[#ffffff] flex justify-center  flex-col justify-self-start"
                    sx={{
                      boxShadow: "0px 23px 26px #00000017",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#0069df",
                        fontWeight: 900,
                        fontSize: "18px",
                        mt: 2,
                        mb: 0.5,
                      }}
                    >
                      Step 3
                    </Typography>
                    <Box
                      sx={{
                        p: 0.2,
                        backgroundColor: "#00c3e6",
                        width: "9%",
                        height: "0.1%",
                        mb: 2,
                      }}
                    />
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontSize: "17px",
                        fontWeight: "medium",
                        fontFamily: "Roboto, sans-serif",
                        letterSpacing: "0px",
                        color: "#484848",
                        mb: 3,
                      }}
                    >
                      Development of project information document (PID)
                    </Typography>
                  </Box>
                </SplideSlide>

                <SplideSlide>
                  <Box
                    className="pl-16 w-[98vw] h-[150px] flex justify-center  flex-col justify-self-start"
                    sx={{
                      boxShadow: "0px 23px 26px #00000017",
                      background:
                        "linear-gradient(180deg, #26deff 0%, #00baba 100%)",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#ffffff",
                        fontWeight: 900,
                        fontSize: "18px",
                        mt: 2,
                        mb: 0.5,
                      }}
                    >
                      Step 4
                    </Typography>
                    <Box
                      sx={{
                        p: 0.2,
                        backgroundColor: "#0069df",
                        width: "9%",
                        height: "0.1%",
                        mb: 2,
                      }}
                    />
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontSize: "17px",
                        fontWeight: "medium",
                        fontFamily: "Roboto, sans-serif",
                        letterSpacing: "0px",
                        color: "#ffffff",
                      }}
                    >
                      Financial matchmaking with our partners
                    </Typography>
                  </Box>
                </SplideSlide>
              </Splide>

              <Container className="!hidden lg:!block">
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={0}
                  // sx={{ my: 4 }}
                >
                  <Box
                    sx={{
                      width: 250,
                      height: 140,
                      boxShadow: "0px 23px 26px #00000017",
                      position: "relative",
                      p: 3,
                      backgroundColor: "#ffffff",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#0069df",
                        fontWeight: 900,
                        fontSize: "14px",
                        mt: 2,
                        mb: 0.5,
                      }}
                    >
                      Step 1
                    </Typography>
                    <Box
                      sx={{
                        p: 0.2,
                        backgroundColor: "#00c3e6",
                        width: "9%",
                        height: "0.1%",
                        mb: 2,
                      }}
                    />
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontSize: "16px",
                        fontWeight: "medium",
                        fontFamily: "Roboto, sans-serif",
                        letterSpacing: "0px",
                        color: "#484848",
                      }}
                    >
                      Projection application
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      width: 250,
                      height: 140,
                      boxShadow: "0px 23px 26px #00000017",
                      position: "relative",
                      p: 3,
                      background:
                        "linear-gradient(180deg, #26deff 0%, #00baba 100%)",
                    }}
                  >
                    <span
                      style={{
                        width: 30,
                        height: 30,
                        position: "absolute",
                        top: 18,
                        left: -20,
                        backgroundColor: "#ffffff",
                        borderRadius: "4em",
                        p: 3,
                      }}
                    ></span>
                    <Typography
                      sx={{
                        color: "#ffffff",
                        fontWeight: 900,
                        fontSize: "14px",
                        mt: 2,
                        mb: 0.5,
                      }}
                    >
                      Step 2
                    </Typography>
                    <Box
                      sx={{
                        p: 0.2,
                        backgroundColor: "#0069df",
                        width: "9%",
                        height: "0.1%",
                        mb: 2,
                      }}
                    />
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontSize: "16px",
                        fontWeight: "medium",
                        fontFamily: "Roboto, sans-serif",
                        letterSpacing: "0px",
                        color: "#ffffff",
                      }}
                    >
                      Screening of projects
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      width: 250,
                      height: 140,
                      boxShadow: "0px 23px 26px #00000017",
                      position: "relative",
                      p: 3,
                      backgroundColor: "#ffffff",
                    }}
                  >
                    <span
                      style={{
                        width: 30,
                        height: 30,
                        position: "absolute",
                        top: 18,
                        left: -20,
                        backgroundColor: "#26deff",
                        borderRadius: "4em",
                      }}
                    ></span>
                    <Typography
                      sx={{
                        color: "#0069df",
                        fontWeight: 900,
                        fontSize: "14px",
                        mt: 2,
                        mb: 0.5,
                      }}
                    >
                      Step 3
                    </Typography>
                    <Box
                      sx={{
                        p: 0.2,
                        backgroundColor: "#00c3e6",
                        width: "9%",
                        height: "0.1%",
                        mb: 2,
                      }}
                    />
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontSize: "16px",
                        fontWeight: "medium",
                        fontFamily: "Roboto, sans-serif",
                        letterSpacing: "0px",
                        color: "#484848",
                        mb: 3,
                      }}
                    >
                      Development of project information document (PID)
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      width: 250,
                      height: 140,
                      boxShadow: "0px 23px 26px #00000017",
                      position: "relative",
                      p: 3,
                      background:
                        "linear-gradient(180deg, #26deff 0%, #00baba 100%)",
                    }}
                  >
                    <span
                      style={{
                        width: 30,
                        height: 30,
                        position: "absolute",
                        top: 18,
                        left: -20,
                        backgroundColor: "#ffffff",
                        borderRadius: "4em",
                        p: 3,
                      }}
                    ></span>
                    <Typography
                      sx={{
                        color: "#ffffff",
                        fontWeight: 900,
                        fontSize: "14px",
                        mt: 2,
                        mb: 0.5,
                      }}
                    >
                      Step 4
                    </Typography>
                    <Box
                      sx={{
                        p: 0.2,
                        backgroundColor: "#0069df",
                        width: "9%",
                        height: "0.1%",
                        mb: 2,
                      }}
                    />
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontSize: "16px",
                        fontWeight: "medium",
                        fontFamily: "Roboto, sans-serif",
                        letterSpacing: "0px",
                        color: "#ffffff",
                      }}
                    >
                      Financial matchmaking with our partners
                    </Typography>
                  </Box>
                </Stack>
              </Container>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  my: 4,
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    display: "flex",
                    backgroundColor: "#26DEFF",
                    borderRadius: "2px",
                    boxShadow: "0px 3px 6px #00000029",
                    fontFamily: "Roboto, sans-serif",
                    color: "#FFFFFF",
                    letterSpacing: "0px",
                    textTransform: "none",
                    fontSize: "0.95rem",
                  }}
                  onClick={() => navigate("/signup-wsp")}
                >
                  Finance my project
                </Button>
              </Box>
            </Container>
          </Box>

          <Container
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              my: 3,
              width: "99vw",
              height: "440px",
            }}
          >
            <Typography
              sx={{
                color: "#26DEFF",
                letterSpacing: "0px",
                fontFamily: "Roboto, sans-serif",
                fontWeight: "600",
                fontSize: "20px",
              }}
            >
              Become a partner
            </Typography>

            <Splide
              options={{
                rewind: true,
                width: "100%",
                perPage: 1,
                speed: 600,
                pagination: false,
                autoplay: true,
                pauseOnHover: true,
                type: "loop",
              }}
              className="mt-10 mb-20 lg:hidden"
              aria-label="Become a partner"
            >
              <SplideSlide>
                <Box
                  className="pl-16 w-[98vw] h-[150px] bg-[#ffffff] flex justify-center flex-col justify-self-start "
                  sx={{
                    boxShadow: "0px 23px 26px #00000017",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#0069df",
                      fontWeight: 900,
                      fontSize: "18px",
                      mt: 2,
                      mb: 0.5,
                    }}
                  >
                    Step 1
                  </Typography>
                  <Box
                    sx={{
                      p: 0.2,
                      backgroundColor: "#00c3e6",
                      width: "15%",
                      height: "0.1%",
                      mb: 3,
                    }}
                  />
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontSize: "17px",
                      fontWeight: "medium",
                      fontFamily: "Roboto, sans-serif",
                      letterSpacing: "0px",
                      color: "#484848",
                    }}
                  >
                    Partner application and selection
                  </Typography>
                </Box>
              </SplideSlide>

              <SplideSlide>
                <Box
                  className="pl-16 w-[98vw] h-[150px] flex justify-center flex-col justify-self-start "
                  sx={{
                    boxShadow: "0px 23px 26px #00000017",
                    background:
                      "linear-gradient(180deg, #26deff 0%, #00baba 100%)",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#ffffff",
                      fontWeight: 900,
                      fontSize: "18px",
                      mt: 2,
                      mb: 0.5,
                    }}
                  >
                    Step 2
                  </Typography>
                  <Box
                    sx={{
                      p: 0.2,
                      backgroundColor: "#0069df",
                      width: "15%",
                      height: "0.1%",
                      mb: 3,
                    }}
                  />
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontSize: "17px",
                      fontWeight: "medium",
                      fontFamily: "Roboto, sans-serif",
                      letterSpacing: "0px",
                      color: "#ffffff",
                    }}
                  >
                    Internal review by FundMyWater
                  </Typography>
                </Box>
              </SplideSlide>

              <SplideSlide>
                <Box
                  className="pl-16 w-auto h-[150px] bg-[#ffffff] flex justify-center flex-col justify-self-start "
                  sx={{
                    boxShadow: "0px 23px 26px #00000017",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#0069df",
                      fontWeight: 900,
                      fontSize: "18px",
                      mt: 2,
                      mb: 0.5,
                    }}
                  >
                    Step 3
                  </Typography>
                  <Box
                    sx={{
                      p: 0.2,
                      backgroundColor: "#00c3e6",
                      width: "15%",
                      height: "0.1%",
                      mb: 3,
                    }}
                  />
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontSize: "17px",
                      fontWeight: "medium",
                      fontFamily: "Roboto, sans-serif",
                      letterSpacing: "0px",
                      color: "#484848",
                      mb: 3,
                    }}
                  >
                    Introductory call between FundMyWater and partner to explore
                    synergies
                  </Typography>
                </Box>
              </SplideSlide>

              <SplideSlide>
                <Box
                  className="pl-16 w-auto h-[150px] flex justify-center flex-col justify-self-start "
                  sx={{
                    boxShadow: "0px 23px 26px #00000017",
                    background:
                      "linear-gradient(180deg, #26deff 0%, #00baba 100%)",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#ffffff",
                      fontWeight: 900,
                      fontSize: "18px",
                      mt: 2,
                      mb: 0.5,
                    }}
                  >
                    Step 4
                  </Typography>
                  <Box
                    sx={{
                      p: 0.2,
                      backgroundColor: "#0069df",
                      width: "15%",
                      height: "0.1%",
                      mb: 3,
                    }}
                  />
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontSize: "17px",
                      fontWeight: "medium",
                      fontFamily: "Roboto, sans-serif",
                      letterSpacing: "0px",
                      color: "#ffffff",
                    }}
                  >
                    Selection of viable projects
                  </Typography>
                </Box>
              </SplideSlide>

              <SplideSlide>
                <Box
                  className="pl-16 w-auto h-[150px] bg-[#ffffff] flex justify-center flex-col justify-self-start "
                  sx={{
                    boxShadow: "0px 23px 26px #00000017",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#0069df",
                      fontWeight: 900,
                      fontSize: "18px",
                      mt: 2,
                      mb: 0.5,
                    }}
                  >
                    Step 5
                  </Typography>
                  <Box
                    sx={{
                      p: 0.2,
                      backgroundColor: "#00c3e6",
                      width: "15%",
                      height: "0.1%",
                      mb: 3,
                    }}
                  />
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontSize: "17px",
                      fontWeight: "medium",
                      fontFamily: "Roboto, sans-serif",
                      letterSpacing: "0px",
                      color: "#484848",
                      my: 2,
                    }}
                  >
                    Provision of assistance to interested projects
                  </Typography>
                </Box>
              </SplideSlide>
            </Splide>

            <Box
              className="!block lg:!hidden"
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  display: "flex",
                  backgroundColor: "#0069DF",
                  borderRadius: "2px",
                  boxShadow: "0px 3px 6px #00000029",
                  fontFamily: "Roboto, sans-serif",
                  textTransform: "none",
                  color: "#FFFFFF",
                  letterSpacing: "0px",
                  fontSize: "0.95rem",
                }}
                onClick={() => navigate("/signup-partner")}
              >
                Become a Partner
              </Button>
            </Box>

            <Container className="!hidden lg:!block">
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={0}
              >
                <Box
                  sx={{
                    width: 230,
                    height: 200,
                    boxShadow: "0px 23px 26px #00000017",
                    position: "relative",
                    p: 3,
                    backgroundColor: "#ffffff",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#0069df",
                      fontWeight: 900,
                      fontSize: "14px",
                      mt: 2,
                      mb: 0.5,
                    }}
                  >
                    Step 1
                  </Typography>
                  <Box
                    sx={{
                      p: 0.2,
                      backgroundColor: "#00c3e6",
                      width: "15%",
                      height: "0.1%",
                      mb: 3,
                    }}
                  />
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "medium",
                      fontFamily: "Roboto, sans-serif",
                      letterSpacing: "0px",
                      color: "#484848",
                    }}
                  >
                    Partner application and selection
                  </Typography>
                </Box>

                <Box
                  sx={{
                    width: 230,
                    height: 200,
                    boxShadow: "0px 23px 26px #00000017",
                    position: "relative",
                    p: 3,
                    background:
                      "linear-gradient(180deg, #26deff 0%, #00baba 100%)",
                  }}
                >
                  <span
                    style={{
                      width: 30,
                      height: 30,
                      position: "absolute",
                      top: 18,
                      left: -20,
                      backgroundColor: "#ffffff",
                      borderRadius: "4em",
                      p: 3,
                    }}
                  ></span>
                  <Typography
                    sx={{
                      color: "#ffffff",
                      fontWeight: 900,
                      fontSize: "14px",
                      mt: 2,
                      mb: 0.5,
                    }}
                  >
                    Step 2
                  </Typography>
                  <Box
                    sx={{
                      p: 0.2,
                      backgroundColor: "#0069df",
                      width: "15%",
                      height: "0.1%",
                      mb: 3,
                    }}
                  />
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "medium",
                      fontFamily: "Roboto, sans-serif",
                      letterSpacing: "0px",
                      color: "#ffffff",
                    }}
                  >
                    Internal review by FundMyWater
                  </Typography>
                </Box>

                <Box
                  sx={{
                    width: 230,
                    height: 200,
                    boxShadow: "0px 23px 26px #00000017",
                    position: "relative",
                    p: 3,
                    backgroundColor: "#ffffff",
                  }}
                >
                  <span
                    style={{
                      width: 30,
                      height: 30,
                      position: "absolute",
                      top: 18,
                      left: -20,
                      backgroundColor: "#26deff",
                      borderRadius: "4em",
                    }}
                  ></span>
                  <Typography
                    sx={{
                      color: "#0069df",
                      fontWeight: 900,
                      fontSize: "14px",
                      mt: 2,
                      mb: 0.5,
                    }}
                  >
                    Step 3
                  </Typography>
                  <Box
                    sx={{
                      p: 0.2,
                      backgroundColor: "#00c3e6",
                      width: "15%",
                      height: "0.1%",
                      mb: 3,
                    }}
                  />
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "medium",
                      fontFamily: "Roboto, sans-serif",
                      letterSpacing: "0px",
                      color: "#484848",
                      mb: 3,
                    }}
                  >
                    Introductory call between FundMyWater and partner to explore
                    synergies
                  </Typography>
                </Box>

                <Box
                  sx={{
                    width: 230,
                    height: 200,
                    boxShadow: "0px 23px 26px #00000017",
                    position: "relative",
                    p: 3,
                    background:
                      "linear-gradient(180deg, #26deff 0%, #00baba 100%)",
                  }}
                >
                  <span
                    style={{
                      width: 30,
                      height: 30,
                      position: "absolute",
                      top: 18,
                      left: -20,
                      backgroundColor: "#ffffff",
                      borderRadius: "4em",
                      p: 3,
                    }}
                  ></span>
                  <Typography
                    sx={{
                      color: "#ffffff",
                      fontWeight: 900,
                      fontSize: "14px",
                      mt: 2,
                      mb: 0.5,
                    }}
                  >
                    Step 4
                  </Typography>
                  <Box
                    sx={{
                      p: 0.2,
                      backgroundColor: "#0069df",
                      width: "15%",
                      height: "0.1%",
                      mb: 3,
                    }}
                  />
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "medium",
                      fontFamily: "Roboto, sans-serif",
                      letterSpacing: "0px",
                      color: "#ffffff",
                    }}
                  >
                    Selection of viable projects
                  </Typography>
                </Box>

                <Box
                  sx={{
                    width: 230,
                    height: 200,
                    boxShadow: "0px 23px 26px #00000017",
                    position: "relative",
                    p: 3,
                    backgroundColor: "#ffffff",
                  }}
                >
                  <span
                    style={{
                      width: 30,
                      height: 30,
                      position: "absolute",
                      top: 18,
                      left: -20,
                      backgroundColor: "#26deff",
                      borderRadius: "4em",
                    }}
                  ></span>
                  <Typography
                    sx={{
                      color: "#0069df",
                      fontWeight: 900,
                      fontSize: "14px",
                      mt: 2,
                      mb: 0.5,
                    }}
                  >
                    Step 5
                  </Typography>
                  <Box
                    sx={{
                      p: 0.2,
                      backgroundColor: "#00c3e6",
                      width: "15%",
                      height: "0.1%",
                      mb: 3,
                    }}
                  />
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontSize: "16px",
                      fontWeight: "medium",
                      fontFamily: "Roboto, sans-serif",
                      letterSpacing: "0px",
                      color: "#484848",
                      my: 2,
                    }}
                  >
                    Provision of assistance to interested projects
                  </Typography>
                </Box>
              </Stack>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  my: 4,
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    display: "flex",
                    backgroundColor: "#0069DF",
                    borderRadius: "2px",
                    boxShadow: "0px 3px 6px #00000029",
                    fontFamily: "Roboto, sans-serif",
                    textTransform: "none",
                    color: "#FFFFFF",
                    letterSpacing: "0px",
                    fontSize: "0.95rem",
                  }}
                  onClick={() => navigate("/signup-partner")}
                >
                  Become a Partner
                </Button>
              </Box>
            </Container>
          </Container>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default Home;
