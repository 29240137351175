import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { handleFailure } from '../utils';
import offerService from './offerService';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	loading: false,
	error: '',
    offerAccepted: false,
    offerRejected: false,
    singleOffer: {},
	offers: [],
};

export const fetchAllWspOffers = createAsyncThunk(
	'offerSlice/fetchAllWspOffers',async (data, thunkAPI) => {
		try {
			return await offerService.fetchAllWspOffers(data)
		}catch(error){
            const message = handleFailure(error)
            return thunkAPI.rejectWithValue(message)
        }
	}
);



const offerSlice = createSlice({
	name: 'offer',
	initialState,
	reducers: {
		reset: (state) => {
			state.offers = [];
		},
	},

	extraReducers: (builder) => {
		builder.addCase(fetchAllWspOffers.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(fetchAllWspOffers.fulfilled, (state, action) => {
			state.loading = false;
			state.offers = action.payload.data.result;
			state.error = '';
		});
		builder.addCase(fetchAllWspOffers.rejected, (state, action) => {
			state.loading = false;
			state.offers = [];
			state.error = action.error.messages;
		});

	},
});

export default offerSlice.reducer;
export const { reset } = offerSlice.actions;
