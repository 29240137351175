import { Input, Typography } from "@mui/joy";
import { Box, Button, TableCell, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { postProjectScore } from "../../../features/expression_demand/expressionDemandSlice";
import { toast } from "react-toastify";

const MatrixInput = ({
  setMatrixInfo,
  matrixInfo,
  setOnblur,
  name,
  stepName,
  onBlur,
  id,
  maxScores,
  system,
}) => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);

  const changeHandler = (e) => {
    const inputValue = parseInt(e.target.value);
    const data = {
      scProjId: id,
      scScore: inputValue,
      scMethod: name,
    };
    const formData = { data, stepName };
    if (inputValue > maxScores) {
      e.target.setCustomValidity("Value must be less than or equal to 100");
      toast.error(`Maximum score is ${maxScores}`);
      !matrixInfo[name] &&
        setMatrixInfo((prevState) => ({
          ...prevState,
          [name]: e.target.value,
        }));
    } else {
      e.target.setCustomValidity("");

      const res = dispatch(postProjectScore(formData));
      setMatrixInfo((prevState) => ({
        ...prevState,
        [name]: e.target.value,
      }));
    }
  };

  useEffect(() => {
    console.log(matrixInfo[name]);
  }, []);

  return (
    <Typography>{matrixInfo[name]}</Typography>
        
      
      
  );
};

export default MatrixInput;
  {/* <Input
          disabled={true}
          color="red !important"
          type="number"
          value={matrixInfo[name]}
          onChange={changeHandler}
          onInvalid={(e) =>
            e.target.setCustomValidity("Please enter a valid number")
          }
          inputProps={{
            min: 1,
            max: 10,
            step: 1,
            pattern: "\\d+",
          }}
          error={!!(matrixInfo[name] && matrixInfo[name] > maxScores)}
          helperText={
            matrixInfo[name] && matrixInfo[name] > maxScores
              ? `Value must be less than or equal to ${maxScores}`
              : ""
          }
        /> */}