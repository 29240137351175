import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import { Box } from '@mui/joy';
import NoTableDataComponent from '../../components/NoTableDataComponent';





function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}





export default function TankCapacity() {
  const { storageInformation, pipeInformations, loading } = useSelector(
    (state) => state.expressionDemandData
  );
  const rows = [
    createData('Meter reader', 159),
    createData('Project manager', 237),
  ];
  return (
    <TableContainer component={Paper} elevation={0} sx={{ background: 'inherit' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow className='table'>
            <TableCell>Tank no</TableCell>
            <TableCell align="left">Material</TableCell>
            <TableCell align="left"><>Size (m<sup>3</sup>)</></TableCell>
          </TableRow>
        </TableHead>
        {storageInformation.length > 0 ? (<TableBody className='basic'>
          {storageInformation?.map((row) => (
            <TableRow
              key={row?.stId}
            >
              <TableCell component="th" scope="row" sx={{ borderLeft: 'none !important', width: '33.33%' }}>
                {row?.stId}
              </TableCell>
              <TableCell align="left" sx={{ width: '33.33%' }}>{row?.stMaterial}</TableCell>
              <TableCell align="left" sx={{ width: '33.33%' }} >{row?.stSize}</TableCell>
            </TableRow>
          ))}
        </TableBody>) : <Box sx={{ display: 'flex', width: "vw", justifyContent: "center" }}><NoTableDataComponent /></Box>}
      </Table>

    </TableContainer>
  );
}