import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { arrCleanUp } from '../../utils';
import { handleFailure } from '../utils';
import onboardPartnerService from './onboardPartnerService';



const initialState = {
    partnerApplications: [],
    partnerOnboard: {},
    partnerFiles: {},
    statutoryFilePdf: {},
    statutoryFile2Pdf: {},
    statutoryFile3Pdf: {},

    // partnerFiles: {},
    step3CopyId: {},
    step2MgmtToolPdf: {},
    step3WtrAnalysisPdf: {},
    step3MtrReadingPdf: {},
    step3PermitPdf: {},
    step3PumpPdf: {},

    step5AssetsFile: {},
    step5BankStmntFile: {},
    step5IncomeExpFile: {},


    stepGovFile: {},


    director: [],
    directorEdit: {},
    tankCapacity: [],
    pipeExtent: [],
    bankInfos: [],
    budgetInfo: [],
    operationalStaff: [],
    managementTool: [],
    wtrSrc: [],
    calculations: [],

    isLoadingAudited1: false,
    isSuccessAudited1: false,
    isErrorAudited1: false,

    isLoadingAudited2: false,
    isSuccessAudited2: false,
    isErrorAudited2: false,


    isLoadingAudited3: false,
    isSuccessAudited3: false,
    isErrorAudited3: false,

    isLoadingStatutory: false,
    isSuccessStatutory: false,
    isErrorStatutory: false,
    isLoadingStatutory2: false,
    isSuccessStatutory2: false,
    isErrorStatutory2: false,
    isLoadingStatutory3: false,
    isSuccessStatutory3: false,
    isErrorStatutory3: false,


    isLoadingSeekLoan: false,
    isSuccessSeekLoan: false,
    isErrorSeekLoan: false,

    isLoadingMinutesCom: false,
    isSuccessMinutesCom: false,
    isErrorMinutesCom: false,

    isLoadingMinutesCom2: false,
    isSuccessMinutesCom2: false,
    isErrorMinutesCom2: false,

    isLoadingMinutesCom3: false,
    isSuccessMinutesCom3: false,
    isErrorMinutesCom3: false,
    //step4
    isLoadingMembersList: false,
    isSuccessMembersList: false,
    isErrorMembersList: false,

    isLoadingActiveContract: false,
    isSuccessActiveContract: false,
    isErrorActiveContract: false,

    isLoadingInstitutionContract: false,
    isSuccessInstitutionContract: false,
    isErrorInstitutionContract: false,

    isLoadingKioskContract: false,
    isSuccessKioskContract: false,
    isErrorKioskContract: false,

    isLoadingMapPdf: false,
    isSuccessMapPdf: false,
    isErrorMapPdf: false,

    isLoadingObjPdf: false,
    isSuccessObjPdf: false,
    isErrorObjPdf: false,
    //step4


    isErrorCopyId: false,
    isLoadingCopyId: false,
    isSuccessCopyId: false,

    isErrorMgmtTool: false,
    isLoadingMgmtTool: false,
    isSuccessMgmtTool: false,

    isErrorWtrAnalysis: false,
    isLoadingWtrAnalysis: false,
    isSuccessWtrAnalysis: false,

    isError4MtrReading: false,
    isLoading4MtrReading: false,
    isSuccess4MtrReading: false,

    isErrorPermit: false,
    isLoadingPermit: false,
    isSuccessPermit: false,

    isErrorPump: false,
    isLoadingPump: false,
    isSuccessPump: false,

    isErrorAssetsFile: false,
    isLoadingAssetsFile: false,
    isSuccessAssetsFile: false,

    isErrorBankStmntFile: false,
    isLoadingBankStmntFile: false,
    isSuccessBankStmntFile: false,
    isSuccessUpdateDirector: false,



    isErrorIncomeExpFile: false,
    isLoadingIncomeExpFile: false,
    isSuccessIncomeExpFile: false,

    isErrorDirector: false,
    isLoadingDirector: false,
    isSuccessDirector: false,
    isLoadingDirectorUpdate: false,

    isLoadingTank: false,
    isLoadingPipe: false,
    isLoadingBudget: false,
    isLoadingBank: false,
    isLoadingManagementTool: false,
    isLoadingStaff: false,
    isLoadingWtrSrc: false,

    isSuccessSaveWtrSrc: false,
    isSuccessSaveManagementTool:false,

    isLoadingUpdateTank: false,
    isLoadingUpdatePipe: false,
    isLoadingUpdateBank: false,
    isLoadingUpdateBudget: false,
    isLoadingUpdateManagementTool: false,
    isLoadingUpdateStaff: false,
    isLoadingUpdateWtrSrc: false,

    isSuccessUpdateTank: false,
    isSuccessUpdatePipe: false,
    isSuccessUpdateBank: false,
    isSuccessUpdateBudget: false,
    isSuccessUpdateManagementTool: false,
    isSuccessUpdateStaff: false,
    isSuccessUpdateWtrSrc: false,

    isErrorTank: false,
    isErrorPipe: false,
    isErrorBank: false,
    isErrorBudget: false,
    isErrorManagementTool:false,
    isErrorStaff: false,
    isErrorWtrSrc: false,

    isErrorDelete: false,
    isLoadingDelete: false,
    isSuccessDelete: false,

    isErrorGov: false,
    isLoadingGov: false,
    isSuccessGov: false,


    corStep7: {},
    moaStep7: {},


    isError: false,
    isLoading: false,
    isSuccess: false,
    isSuccessS3: false,
    isSuccessS4: false,

    isSuccessS7Cor: false,
    isSuccessS7Moa: false,
    isSuccessS7Ooa: false,
    isSuccessS7Mol: false,
    isLoadingMol: false,
    isLoadingAoa: false,
    isSuccessS7Audited: false,

    message: '',
    errorEmail: true,

    step2: {
        projRegNo: '',
        projRegType: '',
        projRegTypeOther: '',
        projKraPin: '',
        projOrgPeriod: '',
    },
    step2AgmDate: {
        projAgmMinutesDate: '',
    },
    step2Date: {
        projRegDate: '',
    },
    step2CommitteeDate: {
        projCommitteeDate: '',
    },
    step2CommitteeDate2: {
        projCommitteeDate2: '',
    },
    step2CommitteeDate3: {
        projCommitteeDate3: '',
    },
    statutoryFile: {
        nameStatutory: '',
        dateStatutory: '',
        file_base64Statutory: undefined,
        typeStatutory: '',
    },
    statutoryFile2: {
        nameStatutory2: '',
        dateStatutory2: '',
        file_base64Statutory2: undefined,
        typeStatutory2: '',
    },
    statutoryFile3: {
        nameStatutory3: '',
        dateStatutory3: '',
        file_base64Statutory3: undefined,
        typeStatutory3: '',
    },


    step3: {
        wsSource:'',
        projExistingOperational: '',
        projReliabilityWaterSource: '',
        projOtherExistingWaterSources: '',
        projOtherExistingWaterSourcesDesc: '',
        projSswpAround: '',
        projSswpAroundDesc: '',
        projReliabilityOtherWaterSources: '',
        projOtherWspSswp: '',
        projOtherWspSswpDesc: '',
        projAverageVolumeDry: '',
        projAverageVolWet: '',
        projWaterQuality: '',
        projMasterMeterInstalledFunctional: '',
        projWraAbstrationPermit: '',
        projTypeOfSysytem: '',
        projPumpedPow: '',
    },
 
    step3Source: {
        wsSource: '',
        wsReliability: '',
        wsQuality: '',
        wsMasterMtr: '',
        wsWra: '',
        wsSystem: '',
        wsPower: '',
    },

    step3ProjWaterSources: {},
    step6ProjProbSolved: {},
    step6ProjPurpose: {},
    mgmtToolPdf: {
        nameMgmtTool: '',
        dateMgmtTool: '',
        file_base64MgmtTool: undefined,
        typeMgmtTool: '',
    },
    wtrAnalysisPdf: {
        nameWtrAnalysis: '',
        dateWtrAnalysis: '',
        file_base64WtrAnalysis: undefined,
        typeWtrAnalysis: '',
    },
    mtrReadingPdf: {
        nameMtrReading: '',
        dateMtrReading: '',
        file_base64MtrReading: undefined,
        typeMtrReading: '',
    },
    permitPdf: {
        namePermit: '',
        datePermit: '',
        file_base64Permit: undefined,
        typePermit: '',
    },
    pumpPdf: {
        namePump: '',
        datePump: '',
        file_base64Pump: undefined,
        typePump: '',
    },
    assetFile: {
        nameAsset: '',
        dateAsset: '',
        file_base64Asset: undefined,
        typeAsset: '',
    },
    bankFile: {
        nameBank: '',
        dateBank: '',
        file_base64Bank: undefined,
        typeBank: '',
    },

    step5IncomeExp: {
        nameIncomeExp: '',
        dateIncomeExp: '',
        file_base64IncomeExp: undefined,
        typeIncomeExp: '',
    },

    step5: {
        projHandCash: '',
        projBankCash: '',
        projTotalDebtors: '',
        projLiquidAssets: '',
        projLiabilities: '',
        projNetFinances: '',
        projBillingSystem: '',
        projAuditorName: '',
        projAuditorFirm: '',
        projAuditorTelephone: '',
        projAuditorEmail: '',
        projAssets: '',

    },
    step5Date: {
        projFinDate: '',
    },
    step5TariffType: {
        projTariffType: '',
    },
    step5Tariff: {
        projTariffSystem: '',
    },
    step5TariffMultiple: {
        projTariffSystemFlatRate: 'Flat rate',
        projTariffSystemVolumetric: 'Volumetric',
    },
    step5OtherCharges: {
        projOtherCharges: '',
    },
    step5File: {
        nameStep5: '',
        dateStep5: '',
        file_base64Step5: undefined,
        typeStep5: '',
    },

    step6: {
        projProjCategory: '',
        projDescSubProj: '',
        projProbSolved: '',
        projOtherProblemSolved: '',
        projWhoBenefit: '',
        projOtherBenefit: '',
        projOtherPartyFinance: '',
        projDonorPartyFinance: '',
        projEstCost: '',
        projLoanAmount: '',
        projLoanYears: '',
        projInfrastSubsidy: '',
        projMoneySource: '',
        projOtherFunds: '',
        projInformGov: '',
        projInformGovYesFile: '',
        projWspInformed: '',
        projLoanSum: '',
        projSswpSum: '',
        proj3rdpartySum: '',
        proj3rdpartyCalculated: '',
        projSswpCalculated: '',
        projLoanCalculated: '',
        projTotalSum: '',
        projTotalCalculated: '',
        projBudgetItem: '',
        projBudgetMoney: '',
        projBudgetComment: '',
        projPayCapable: '',
        projMonthlyPay: ''
    },
    step6File: {
        nameStep6: '',
        dateStep6: '',
        file_base64Step6: undefined,
        typeStep6: '',
    },


    step7: {
        value: undefined,
        projRegCertFile: '',
        projMemFile: '',
        projAssocArtFile: '',
        projAudiAccFile: '',
        projMinAgmFile: '',
        projAgmMinutesDate: ''
    },

    step7Cor: {
        nameCor: '',
        dateCor: '',
        file_base64Cor: undefined,
        typeCor: '',
    },
    step7Moa: {
        nameMoa: '',
        dateMoa: '',
        file_base64Moa: undefined,
        typeMoa: '',
    },
    step2Aoa: {
        nameAoa: '',
        dateAoa: '',
        file_base64Aoa: undefined,
        typeAoa: '',
    },
    step2AoaPdf: {},
    step2SeekLoan: {
        nameSeekLoan: '',
        dateSeekLoan: '',
        file_base64SeekLoan: undefined,
        typeSeekLoan: '',
    },
    step2SeekLoanPdf: {},
    step2MinutesCom: {
        nameMinutesCom: '',
        dateMinutesCom: '',
        file_base64MinutesCom: undefined,
        typeMinutesCom: '',
    },
    step2MinutesComPdf: {},

    step2MinutesCom2: {
        nameMinutesCom2: '',
        dateMinutesCom2: '',
        file_base64MinutesCom2: undefined,
        typeMinutesCom2: '',
    },
    step2MinutesCom2Pdf: {},

    step2MinutesCom3: {
        nameMinutesCom3: '',
        dateMinutesCom3: '',
        file_base64MinutesCom3: undefined,
        typeMinutesCom3: '',
    },
    step2MinutesCom3Pdf: {},


    step4: {
        projIndividualActiveConn: '',
        projIndividualClosedConn: '',
        projInstitutionCommercialConn: '',
        projNumberWaterKiosks: '',
        projNumberHouseholdsServed: '',
        projAproxPopulationServed: '',
        projTotalPopulation: '',
        projArea: '',
        projLat: '',
        projLong: '',
    },

    step4MembersList: {
        nameMembersList: '',
        dateMembersList: '',
        file_base64MembersList: undefined,
        typeMembersList: '',
    },
    step4MembersListPdf: {},

    step4ActiveContract: {
        nameActiveContract: '',
        dateActiveContract: '',
        file_base64ActiveContract: undefined,
        typeActiveContract: '',
    },
    step4ActiveContractPdf: {},

    step4InstitutionContract: {
        nameInstitutionContract: '',
        dateInstitutionContract: '',
        file_base64InstitutionContract: undefined,
        typeInstitutionContract: '',
    },
    step4InstitutionContractPdf: {},

    step4KioskContract: {
        nameKioskContract: '',
        dateKioskContract: '',
        file_base64KioskContract: undefined,
        typeKioskContract: '',
    },
    step4KioskContractPdf: {},

    step4Map: {
        nameMap: '',
        dateMap: '',
        file_base64Map: undefined,
        typeMap: '',
    },
    step4MapPdf: {},

    step5Audited1: {
        nameAudited: '',
        dateAudited: '',
        file_base64Audited: undefined,
        typeAudited: '',
    },
    audited1Step5Pdf: {},

    step5Audited2: {
        nameAudited2: '',
        dateAudited2: '',
        file_base64Audited2: undefined,
        typeAudited2: '',
    },
    audited2Step5Pdf: {},

    step5Audited3: {
        nameAudited3: '',
        dateAudited3: '',
        file_base64Audited3: undefined,
        typeAudited3: '',
    },
    audited3Step5Pdf: {},

    step6ObjLetter: {
        nameObjLetter: '',
        dateObjLetter: '',
        file_base64ObjLetter: undefined,
        typeObjLetter: '',
    },
    step6ObjLetterPdf: {},


    mol: {
        nameMol: '',
        dateMol: '',
        file_base64Mol: undefined,
        typeMol: '',
    },
    molStep2: {},

    errorPhoneStep1: false,
    errorPhoneAuditor: true,

    isCleanupForm: false,
    isCleanupFile: false,
    isCleanupWtrAnalysis: false,
    isCleanupMtrReading: false,
    isCleanupPermit: false,
    isCleanupStep2Aoa: false,
    isCleanupStep2SeekLoan: false,
    isCleanupStep2MinutesCom: false,
    isCleanupStep2MinutesCom2: false,
    isCleanupStep2MinutesCom3: false,
    isCleanupStep4MembersList: false,
    isCleanupStep4ActiveContract: false,
    isCleanupStep4InstitutionContract: false,
    isCleanupStep4KioskContract: false,
    isCleanupStep4Map: false,
    isCleanupStep6ObjLetter: false,





    isCleanupMol: false,
    isCleanupPump: false,
    isCleanupAsset: false,
    isCleanupBankStmnt: false,
    isCleanupIncomeExp: false,
    isCleanupNationalId: false,
    isCleanupStatutory: false,
    isCleanupStatutory2: false,
    isCleanupStatutory3: false,
    isCleanupGovernment: false,

}


//onboardPartner
export const onboardPartner = createAsyncThunk('partner/onboardPartner', async (_formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardPartner(_formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});


//onbaordFiles
export const onbaordFiles = createAsyncThunk('partner/onbaordFiles', async (formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
//StatutoryFile
export const onbaordStatutoryFile = createAsyncThunk('partner/StatutoryFile', async (formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
//StatutoryFile2
export const onbaordStatutoryFile2 = createAsyncThunk('partner/StatutoryFile2', async (formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
//StatutoryFile3
export const onbaordStatutoryFile3 = createAsyncThunk('partner/StatutoryFile3', async (formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
// copyIdStep3
export const onbaordCopyIdStep3 = createAsyncThunk('partner/onbaordCopyIdStep3', async (formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
// mgmtToolPdf
export const onbaordMgmtToolPdf = createAsyncThunk('partner/onbaordMgmtToolPdf', async (formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
// wtrAnalysisPdf
export const onbaordWtrAnalysisPdf = createAsyncThunk('partner/onbaordWtrAnalysisPdf', async (formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
// mtrReadingPdf
export const onbaordMtrReadingPdf = createAsyncThunk('partner/onbaordMtrReadingPdf', async (formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
//permitPdf
export const onbaordPermitPdf = createAsyncThunk('partner/onbaordPermitPdf', async (formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
//pumpPdf
export const onbaordPumpPdf = createAsyncThunk('partner/onbaordPumpPdf', async (formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
//assetFile
export const onbaordAssetFile = createAsyncThunk('partner/onbaordAssetFile', async (formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
//bankFile
export const onbaordBankFile = createAsyncThunk('partner/onbaordBankFile', async (formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
//incomeExpFile
export const onbaordIncomeExpFile = createAsyncThunk('partner/onbaordIncomeExpFile', async (formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
// corStep6
export const onbaordCorStep7 = createAsyncThunk('partner/onbaordCorStep7', async (formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

// onbaordFilesAssetsstep3
export const onbaordFilesAssets = createAsyncThunk('partner/onboardFilesAssets', async (formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
// corStep6
export const CopyIdStep3 = createAsyncThunk('partner/onbaordCorStep7', async (formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

// GovFile
export const onbaordGovFile = createAsyncThunk('partner/onbaordGovFile', async (formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
// moaStep6
export const onbaordMoaStep7 = createAsyncThunk('partner/onbaordMoaStep7', async (formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
// onbaordAoaStep2
export const onbaordAoaStep2 = createAsyncThunk('partner/onbaordAoaStep2', async (formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
// mol
export const onbaordMol = createAsyncThunk('partner/onbaordMol', async (formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
// onbaordSeekLoanStep2
export const onbaordSeekLoanStep2 = createAsyncThunk('partner/onbaordSeekLoanStep2', async (formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
// onbaordMinutesComStep2
export const onbaordMinutesComStep2 = createAsyncThunk('partner/onbaordMinutesComStep2', async (formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
// onbaordMinutesCom2Step2
export const onbaordMinutesCom2Step2 = createAsyncThunk('partner/onbaordMinutesCom2Step2', async (formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
// onbaordMinutesCom3Step2
export const onbaordMinutesCom3Step2 = createAsyncThunk('partner/onbaordMinutesCom3Step2', async (formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

// onbaordMembersList
export const onbaordMembersList = createAsyncThunk('partner/onbaordMembersList', async (formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
// onbaordActiveContract
export const onbaordActiveContract = createAsyncThunk('partner/onbaordActiveContract', async (formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
// onbaordInstitutionContract
export const onbaordInstitutionContract = createAsyncThunk('partner/onbaordInstitutionContract', async (formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
// onbaordKioskContract
export const onbaordKioskContract = createAsyncThunk('partner/onbaordKioskContract', async (formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
// onbaordMapPdf
export const onbaordMapPdf = createAsyncThunk('partner/onbaordMapPdf', async (formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
// onbaordObjPdf
export const onbaordObjPdf = createAsyncThunk('partner/onbaordObjPdf', async (formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
// onbaordAudited1Step5
export const onbaordAudited1Step5 = createAsyncThunk('partner/onbaordAudited1Step5', async (formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
// onbaordAudited2Step5
export const onbaordAudited2Step5 = createAsyncThunk('partner/onbaordAudited2Step5', async (formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
// onbaordAudited3Step5
export const onbaordAudited3Step5 = createAsyncThunk('partner/onbaordAudited3Step5', async (formData, thunkAPI) => {
    try {
        return await onboardPartnerService.onboardFiles(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
// fetchPartnerApplications
export const fetchPartnerApplications = createAsyncThunk('partner/applications', async (_, thunkAPI) => {
    try {
        const accId = thunkAPI.getState().auth.user.usrAccId;
        return await onboardPartnerService.fetchPartnerApplications(accId)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

//saveDirector
export const saveDirector = createAsyncThunk('partner/saveDirector', async (_formData, thunkAPI) => {
    try {
        return await onboardPartnerService.saveDirector(_formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

//fetchDirectors
export const fetchDirectors = createAsyncThunk('partner/fetchDirectors', async (projId, thunkAPI) => {
    try {
        return await onboardPartnerService.fetchDirectors(projId)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
//fetchDirectorsById
export const fetchDirectorsById = createAsyncThunk('partner/fetchDirectorsById', async (dirId, thunkAPI) => {
    try {
        return await onboardPartnerService.fetchDirectorsById(dirId)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
//updateDirector
export const updateDirector = createAsyncThunk('partner/updateDirector', async (_formData, thunkAPI) => {
    try {
        return await onboardPartnerService.updateDirector(_formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

//deleteDirector
export const deleteDirector = createAsyncThunk('partner/deleteDirector', async (dirId, thunkAPI) => {
    try {
        return await onboardPartnerService.deleteDirector(dirId)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});


//saveTotalCapacity
export const saveTotalCapacity = createAsyncThunk('partner/saveTotalCapacity', async (_formData, thunkAPI) => {
    try {
        return await onboardPartnerService.saveTotalCapacityOfStorage(_formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

//fetchTotalCapacity
export const fetchTotalCapacity = createAsyncThunk('partner/fetchTotalCapacity', async (projId, thunkAPI) => {
    try {
        return await onboardPartnerService.fetchTotalCapacityOfStorages(projId)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
//updateTotalCapacityOfStorages
export const updateTotalCapacityOfStorages = createAsyncThunk('partner/updateTotalCapacityOfStorages', async (_formData, thunkAPI) => {
    try {
        return await onboardPartnerService.updateTotalCapacityOfStorages(_formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
//deleteTotalCapacityOfStorage
export const deleteTotalCapacity = createAsyncThunk('partner/deleteTotalCapacity', async (stId, thunkAPI) => {
    try {
        return await onboardPartnerService.deleteTotalCapacityOfStorage(stId)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});


//savePipeExtent
export const savePipeExtent = createAsyncThunk('partner/savePipeExtent', async (_formData, thunkAPI) => {
    try {
        return await onboardPartnerService.savePipeExtent(_formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

//fetchPipeExtents
export const fetchPipeExtents = createAsyncThunk('partner/fetchPipeExtents', async (projId, thunkAPI) => {
    try {
        return await onboardPartnerService.fetchPipeExtents(projId)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
//updatePipeExtent
export const updatePipeExtent = createAsyncThunk('partner/updatePipeExtent', async (_formData, thunkAPI) => {
    try {
        return await onboardPartnerService.updatePipeExtent(_formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
//deletePipeExtent
export const deletePipeExtent = createAsyncThunk('partner/deletePipeExtent', async (peId, thunkAPI) => {
    try {
        return await onboardPartnerService.deletePipeExtent(peId)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

//saveBankInfo
export const saveBankInfo = createAsyncThunk('partner/saveBankInfo', async (_formData, thunkAPI) => {
    try {
        return await onboardPartnerService.saveBankInfo(_formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

//fetchBankInfos
export const fetchBankInfos = createAsyncThunk('partner/fetchBankInfos', async (projId, thunkAPI) => {
    try {
        return await onboardPartnerService.fetchBankInfos(projId)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
//updateBankInfos
export const updateBankInfos = createAsyncThunk('partner/updateBankInfos', async (_formData, thunkAPI) => {
    try {
        return await onboardPartnerService.updateBankInfos(_formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
//deleteBankInfo
export const deleteBankInfo = createAsyncThunk('partner/deleteBankInfo', async (bId, thunkAPI) => {
    try {
        return await onboardPartnerService.deleteBankInfo(bId)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

export const deleteBudgetInfo = createAsyncThunk('partner/deleteBudgetInfo', async (biId, thunkAPI) => {
    try {
        return await onboardPartnerService.deleteBudgetInfo(biId)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

export const saveBudgetInfo = createAsyncThunk('partner/saveBudgetInfo', async (_formData, thunkAPI) => {
    try {
        return await onboardPartnerService.saveBudgetInfo(_formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

export const fetchBudgetInfo = createAsyncThunk('partner/fetchBudgetInfo', async (projId, thunkAPI) => {
    try {
        return await onboardPartnerService.fetchBudgetInfo(projId)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
//updateBudgetInfo
export const updateBudgetInfo = createAsyncThunk('partner/updateBudgetInfo', async (_formData, thunkAPI) => {
    try {
        return await onboardPartnerService.updateBudgetInfo(_formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

export const fetchCalculate = createAsyncThunk('partner/calculate', async (formData, thunkAPI) => {
    try {
        // const accId = thunkAPI.getState().auth.user.usrAccId;
        return await onboardPartnerService.fetchCalculate(formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});


//saveManagementTool
export const saveManagementTool = createAsyncThunk('partner/saveManagementTool', async (_formData, thunkAPI) => {
    try {
        return await onboardPartnerService.saveManagementTool(_formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

//fetchManagementTool
export const fetchManagementTool = createAsyncThunk('partner/fetchManagementTool', async (projId, thunkAPI) => {
    try {
        return await onboardPartnerService.fetchManagementTool(projId)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
//updateManagementTool
export const updateManagementTool = createAsyncThunk('partner/updateManagementTool', async (_formData, thunkAPI) => {
    try {
        return await onboardPartnerService.updateManagementTool(_formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
//deleteManagementTool
export const deleteManagementTool = createAsyncThunk('partner/deleteManagementTool', async (mtId, thunkAPI) => {
    try {
        return await onboardPartnerService.deleteManagementTool(mtId)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

//saveStaff
export const saveStaff = createAsyncThunk('partner/saveStaff', async (_formData, thunkAPI) => {
    try {
        return await onboardPartnerService.saveStaff(_formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

//fetchStaff
export const fetchStaff = createAsyncThunk('partner/fetchStaff', async (projId, thunkAPI) => {
    try {
        return await onboardPartnerService.fetchStaff(projId)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
//updateStaff
export const updateStaff = createAsyncThunk('partner/updateStaff', async (_formData, thunkAPI) => {
    try {
        return await onboardPartnerService.updateStaff(_formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
//deleteStaff
export const deleteStaff = createAsyncThunk('partner/deleteStaff', async (osId, thunkAPI) => {
    try {
        return await onboardPartnerService.deleteStaff(osId)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

//saveWaterSrc
export const saveWaterSrc = createAsyncThunk('partner/saveWaterSrc', async (_formData, thunkAPI) => {
    try {
        return await onboardPartnerService.saveWaterSrc(_formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});

//fetchWaterSrc
export const fetchWaterSrc = createAsyncThunk('partner/fetchWaterSrc', async (projId, thunkAPI) => {
    try {
        return await onboardPartnerService.fetchWaterSrc(projId)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
//updateWaterSrc
export const updateWaterSrc = createAsyncThunk('partner/updateWaterSrc', async (_formData, thunkAPI) => {
    try {
        return await onboardPartnerService.updateWaterSrc(_formData)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});
//deleteWaterSrc
export const deleteWaterSrc = createAsyncThunk('partner/deleteWaterSrc', async (wsId, thunkAPI) => {
    try {
        return await onboardPartnerService.deleteWaterSrc(wsId)
    } catch (error) {
        const message = handleFailure(error)
        return thunkAPI.rejectWithValue(message)
    }
});




export const onboardPartnerSlice = createSlice({
    name: 'onboardPartner',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.isSuccessS3 = false

            state.isLoadingAudited1 = false
            state.isSuccessAudited1 = false
            state.isErrorAudited1 = false

            state.isLoadingAudited2 = false
            state.isSuccessAudited2 = false
            state.isErrorAudited2 = false

            state.isLoadingAudited3 = false
            state.isSuccessAudited3 = false
            state.isErrorAudited3 = false

            state.isLoadingStatutory = false
            state.isSuccessStatutory = false
            state.isErrorStatutory = false
            state.isLoadingStatutory2 = false
            state.isSuccessStatutory2 = false
            state.isErrorStatutory2 = false
            state.isLoadingStatutory3 = false
            state.isSuccessStatutory3 = false
            state.isErrorStatutory3 = false

            state.isLoadingSeekLoan = false
            state.isSuccessSeekLoan = false
            state.isErrorSeekLoan = false

            state.isLoadingMinutesCom = false
            state.isSuccessMinutesCom = false
            state.isErrorMinutesCom = false

            state.isLoadingMinutesCom2 = false
            state.isSuccessMinutesCom2 = false
            state.isErrorMinutesCom2 = false

            state.isLoadingMinutesCom3 = false
            state.isSuccessMinutesCom3 = false
            state.isErrorMinutesCom3 = false

            //step4
            state.isLoadingMembersList = false
            state.isSuccessMembersList = false
            state.isErrorMembersList = false

            state.isLoadingActiveContract = false
            state.isSuccessActiveContract = false
            state.isErrorActiveContract = false

            state.isLoadingInstitutionContract = false
            state.isSuccessInstitutionContract = false
            state.isErrorInstitutionContract = false

            state.isLoadingKioskContract = false
            state.isSuccessKioskContract = false
            state.isErrorKioskContract = false

            state.isLoadingMapPdf = false
            state.isSuccessMapPdf = false
            state.isErrorMapPdf = false
            //step4


            state.isLoadingObjPdf = false
            state.isSuccessObjPdf = false
            state.isErrorObjPdf = false

            state.isErrorCopyId = false
            state.isLoadingCopyId = false
            state.isSuccessCopyId = false

            state.isSuccessS4 = false


            state.isErrorMgmtTool = false
            state.isLoadingMgmtTool = false
            state.isSuccessMgmtTool = false

            state.isErrorWtrAnalysis = false
            state.isLoadingWtrAnalysis = false
            state.isSuccessWtrAnalysis = false

            state.isError4MtrReading = false
            state.isLoading4MtrReading = false
            state.isSuccess4MtrReading = false

            state.isErrorPermit = false
            state.isLoadingPermit = false
            state.isSuccessPermit = false

            state.isErrorPump = false
            state.isLoadingPump = false
            state.isSuccessPump = false

            state.isErrorAssetsFile = false
            state.isLoadingAssetsFile = false
            state.isSuccessAssetsFile = false

            state.isErrorBankStmntFile = false
            state.isLoadingBankStmntFile = false
            state.isSuccessBankStmntFile = false


            state.isErrorIncomeExpFile = false
            state.isLoadingIncomeExpFile = false
            state.isSuccessIncomeExpFile = false

            state.isErrorDirector = false
            state.isLoadingDirector = false
            state.isSuccessDirector = false
            state.isSuccessUpdateDirector = false
            state.isLoadingDirectorUpdate = false
            state.isLoadingTank = false
            state.isLoadingPipe = false
            state.isLoadingBudget = false
            state.isLoadingBank = false
            state.isLoadingManagementTool = false
            state.isLoadingStaff = false
            state.isLoadingWtrSrc = false

            state.isSuccessSaveManagementTool = false
            state.isSuccessSaveWtrSrc = false

            state.isLoadingUpdateTank = false
            state.isLoadingUpdatePipe = false
            state.isLoadingUpdateBank = false
            state.isLoadingUpdateBudget = false
            state.isLoadingUpdateManagementTool = false
            state.isLoadingUpdateStaff = false
            state.isLoadingUpdateWtrSrc = false

            state.isSuccessUpdateTank = false
            state.isSuccessUpdatePipe = false
            state.isSuccessUpdateBank = false
            state.isSuccessUpdateManagementTool = false
            state.isSuccessUpdateStaff = false
            state.isSuccessUpdateWtrSrc = false

            state.isErrorTank = false
            state.isErrorPipe = false
            state.isErrorBank = false
            state.isErrorBudget = false
            state.isErrorManagementTool = false
            state.isErrorStaff = false
            state.isErrorWtrSrc = false



            state.isErrorDelete = false
            state.isLoadingDelete = false
            state.isSuccessDelete = false

            state.isErrorGov = false
            state.isLoadingGov = false
            state.isSuccessGov = false

            state.isSuccessS7Cor = false
            state.isSuccessS7Moa = false
            state.isSuccessS7Ooa = false
            state.isSuccessS7Mol = false
            state.isLoadingMol = false
            state.isLoadingAoa = false

            state.isSuccessS7Audited = false

            state.message = ''
            state.isCleanupForm = false
            state.isCleanupWtrAnalysis = false
            state.isCleanupMtrReading = false
            state.isCleanupPermit = false
            state.isCleanupPump = false
            state.isCleanupAsset = false
            state.isCleanupBankStmnt = false
            state.isCleanupIncomeExp = false
            state.isCleanupNationalId = false
            state.isCleanupStatutory = false
            state.isCleanupStatutory2 = false
            state.isCleanupStatutory3 = false
            state.isCleanupGovernment = false

            state.cleanupStep7Cor = false
            state.cleanupStep7Moa = false
            state.isCleanupStep2Aoa = false
            state.isCleanupStep2SeekLoan = false
            state.isCleanupStep2MinutesCom = false
            state.isCleanupStep2MinutesCom2 = false
            state.isCleanupStep2MinutesCom3 = false

            state.isCleanupStep4MembersList = false
            state.isCleanupStep4ActiveContract = false
            state.isCleanupStep4InstitutionContract = false
            state.isCleanupStep4KioskContract = false
            state.isCleanupStep4Map = false
            state.isCleanupStep6ObjLetter = false


            state.isCleanupMol = false
            state.cleanupStep5Audited1 = false
            state.cleanupStep5Audited2 = false
            state.cleanupStep5Audited3 = false

        },
        setEmailErrorDetails: (state, action) => {
            state.errorEmail = action.payload
        },

        setStep2: (state, action) => {
            state.step2.projRegNo = action.payload.projRegNo
            state.step2.projRegType = action.payload.projRegType
            state.step2.projRegTypeOther = action.payload.projRegTypeOthe
            state.step2.projKraPin = action.payload.projKraPin
            state.step2.projOrgPeriod = action.payload.projOrgPeriod

        },

        setStatutoryFile: (state, action) => {
            state.statutoryFile = action.payload
        },
        setStatutoryFile2: (state, action) => {
            state.statutoryFile2 = action.payload
        },
        setStatutoryFile3: (state, action) => {
            state.statutoryFile3 = action.payload
        },
        setStep2Date: (state, action) => {
            state.step2Date = action.payload
        },
        setStep2AgmDate: (state, action) => {
            state.step2AgmDate = action.payload
        },
        setStep2CommitteeDate: (state, action) => {
            state.step2CommitteeDate = action.payload
        },
        setStep2CommitteeDate2: (state, action) => {
            state.step2CommitteeDate2 = action.payload
        },
        setStep2CommitteeDate3: (state, action) => {
            state.step2CommitteeDate3 = action.payload
        },


        setMol: (state, action) => {
            state.mol = action.payload
        },
        setStep2Aoa: (state, action) => {
            state.step2Aoa = action.payload
        },
        setStep2SeekLoan: (state, action) => {
            state.step2SeekLoan = action.payload
        },
        setStep2MinutesCom: (state, action) => {
            state.step2MinutesCom = action.payload
        },
        setStep2MinutesCom2: (state, action) => {
            state.step2MinutesCom2 = action.payload
        },
        setStep2MinutesCom3: (state, action) => {
            state.step2MinutesCom3 = action.payload
        },

        setStep4: (state, action) => {
            state.step4 = action.payload
        },

        setStep4MembersList: (state, action) => {
            state.step4MembersList = action.payload
        },
        setStep4ActiveContract: (state, action) => {
            state.step4ActiveContract = action.payload
        },
        setStep4InstitutionContract: (state, action) => {
            state.step4InstitutionContract = action.payload
        },
        setStep4KioskContract: (state, action) => {
            state.step4KioskContract = action.payload
        },
        setStep4Map: (state, action) => {
            state.step4Map = action.payload
        },
        setStep6ObjLetter: (state, action) => {
            state.step6ObjLetter = action.payload
        },




        setStep6File: (state, action) => {
            state.step6File = action.payload
        },

        setStep3: (state, action) => {
            state.step3 = action.payload
        },
        setStep3Source: (state, action) => {
            state.step3Source = action.payload
        },
      
        setStep3ProjWaterSources: (state, action) => {
            state.step3ProjWaterSources = action.payload
        },
        setStep6ProjProbSolved: (state, action) => {
            state.step6ProjProbSolved = action.payload
        },
        setStep6ProjPurpose: (state, action) => {
            state.step6ProjPurpose = action.payload
        },

        setMgmtToolFile: (state, action) => {
            state.mgmtToolPdf = action.payload
        },
        setWtrAnalysisFile: (state, action) => {
            state.wtrAnalysisPdf = action.payload
        },
        setMtrReadingFile: (state, action) => {
            state.mtrReadingPdf = action.payload
        },
        setPermitFile: (state, action) => {
            state.permitPdf = action.payload
        },
        setPumpFile: (state, action) => {
            state.pumpPdf = action.payload
        },
        setAssetFile: (state, action) => {
            state.assetFile = action.payload
        },
        setBankFile: (state, action) => {
            state.bankFile = action.payload
        },

        setStep5IncomeExp: (state, action) => {
            state.step5IncomeExp = action.payload
        },

        setStep5: (state, action) => {
            state.step5.projHandCash = action.payload.projHandCash
            state.step5.projBankCash = action.payload.projBankCash
            state.step5.projTotalDebtors = action.payload.projTotalDebtors
            state.step5.projLiquidAssets = action.payload.projLiquidAssets
            state.step5.projLiabilities = action.payload.projLiabilities
            state.step5.projNetFinances = action.payload.projNetFinances
            state.step5.projBillingSystem = action.payload.projBillingSystem
            state.step5.projAuditorName = action.payload.projAuditorName
            state.step5.projAuditorFirm = action.payload.projAuditorFirm
            state.step5.projAuditorTelephone = action.payload.projAuditorTelephone
            state.step5.projAuditorEmail = action.payload.projAuditorEmail
        },
        setStep5Date: (state, action) => {
            state.step5Date = action.payload
        },
        setStep5TariffType: (state, action) => {
            state.step5TariffType = action.payload
        },
        setStep5Tariff: (state, action) => {
            state.step5Tariff = action.payload
        },
        setStep5TariffMultiple: (state, action) => {
            state.step5TariffMultiple = action.payload
        },
        setStep5OtherCharges: (state, action) => {
            state.step5OtherCharges = action.payload
        },
        setStep5File: (state, action) => {
            state.step5File = action.payload
        },
        setStep6: (state, action) => {
            state.step6.projProjCategory = action.payload.projProjCategory
            state.step6.projProbSolved = action.payload.projProbSolved
            state.step6.projOtherProblemSolved = action.payload.projOtherProblemSolved
            state.step6.projDescSubProj = action.payload.projDescSubProj
            state.step6.projWhoBenefit = action.payload.projWhoBenefit
            state.step6.projOtherBenefit = action.payload.projOtherBenefit
            state.step6.projOtherPartyFinance = action.payload.projOtherPartyFinance
            state.step6.projDonorPartyFinance = action.payload.projDonorPartyFinance
            state.step6.projEstCost = action.payload.projEstCost
            state.step6.projLoanAmount = action.payload.projLoanAmount
            state.step6.projLoanYears = action.payload.projLoanYears
            state.step6.projMoneySource = action.payload.projMoneySource
            state.step6.projOtherFunds = action.payload.projOtherFunds
            state.step6.projInfrastSubsidy = action.payload.projInfrastSubsidy
            state.step6.projInformGov = action.payload.projInformGov
            state.step6.projInformGovYesFile = action.payload.projInformGovYesFile
            state.step6.projWspInformed = action.payload.projWspInformed
            state.step6.projLoanSum = action.payload.projLoanSum
            state.step6.projSswpSum = action.payload.projSswpSum
            state.step6.proj3rdpartySum = action.payload.proj3rdpartySum
            state.step6.proj3rdpartyCalculated = action.payload.proj3rdpartyCalculated
            state.step6.projSswpCalculated = action.payload.projSswpCalculated
            state.step6.projLoanCalculated = action.payload.projLoanCalculated
            state.step6.projTotalSum = action.payload.projTotalSum
            state.step6.projPayCapable = action.payload.projPayCapable
            state.step6.projMonthlyPay = action.payload.projMonthlyPay
        },

        // setStep6File: (state, action) => {
        //     state.step6File = action.payload
        // },

        setStep7: (state, action) => {
            state.step7 = action.payload
        },
        setPhoneErrorStep1: (state, action) => {
            state.errorPhoneStep1 = action.payload
        },
        setPhoneErrorAuditor: (state, action) => {
            state.errorPhoneAuditor = action.payload
        },



        cleanupForm: (state) => {
            state.step1 = initialState.step1
            state.step2 = initialState.step2
            state.step3 = initialState.step3
            state.step3Source = initialState.step3Source
            state.step5 = initialState.step5
            state.step6 = initialState.step6
            state.statutoryFile = initialState.statutoryFile
            state.statutoryFilePdf = initialState.statutoryFilePdf
            state.statutoryFile2 = initialState.statutoryFile2
            state.statutoryFile2Pdf = initialState.statutoryFile2Pdf
            state.statutoryFile3 = initialState.statutoryFile3
            state.statutoryFile3Pdf = initialState.statutoryFile3Pdf
            state.step3ProjWaterSources = initialState.step3ProjWaterSources
            state.step6ProjProbSolved = initialState.step6ProjProbSolved

            state.step6ProjPurpose = initialState.step6ProjPurpose

            state.mgmtToolPdf = initialState.mgmtToolPdf
            state.wtrAnalysisPdf = initialState.wtrAnalysisPdf
            state.mtrReadingPdf = initialState.mtrReadingPdf
            state.permitPdf = initialState.permitPdf
            state.pumpPdf = initialState.pumpPdf
            state.assetFile = initialState.assetFile
            state.bankFile = initialState.bankFile
            state.step5IncomeExp = initialState.step5IncomeExp

            state.step5Tariff = initialState.step5Tariff
            state.step5TariffType = initialState.step5TariffType
            state.step5TariffMultiple = initialState.step5TariffMultiple
            state.step5OtherCharges = initialState.step5OtherCharges

            state.step5File = initialState.step5File
            state.step6File = initialState.step6File
            state.step7Cor = initialState.step7Cor
            state.step7Moa = initialState.step7Moa
            state.step2Aoa = initialState.step2Aoa
            state.mol = initialState.mol
            state.molStep2 = initialState.molStep2
            state.molStep2 = initialState.molStep2
            state.step2AoaPdf = initialState.step2AoaPdf
            state.step2SeekLoan = initialState.step2SeekLoan
            state.step2SeekLoanPdf = initialState.step2SeekLoanPdf
            state.step2MinutesCom = initialState.step2MinutesCom
            state.step2MinutesComPdf = initialState.step2MinutesComPdf
            state.step2MinutesCom2 = initialState.step2MinutesCom2
            state.step2MinutesCom2Pdf = initialState.step2MinutesCom2Pdf
            state.step2MinutesCom3 = initialState.step2MinutesCom3
            state.step2MinutesCom3Pdf = initialState.step2MinutesCom3Pdf
            state.step2AgmDate = initialState.step2AgmDate
            state.step2Date = initialState.step2Date
            state.step2CommitteeDate = initialState.step2CommitteeDate
            state.step2CommitteeDate2 = initialState.step2CommitteeDate2
            state.step2CommitteeDate3 = initialState.step2CommitteeDate3
            state.step5Date = initialState.step5Date

            state.step4MembersList = initialState.step4MembersList
            state.step4MembersListPdf = initialState.step4MembersListPdf

            state.step4ActiveContract = initialState.step4ActiveContract
            state.step4ActiveContractPdf = initialState.step4ActiveContractPdf

            state.step4InstitutionContract = initialState.step4InstitutionContract
            state.step4InstitutionContractPdf = initialState.step4InstitutionContractPdf

            state.step4KioskContract = initialState.step4KioskContract
            state.step4KioskContractPdf = initialState.step4KioskContractPdf

            state.step4Map = initialState.step4Map
            state.step4MapPdf = initialState.step4MapPdf



            state.step5Audited1 = initialState.step5Audited1
            state.audited1Step5Pdf = initialState.audited1Step5Pdf

            state.step5Audited2 = initialState.step5Audited2
            state.audited2Step5Pdf = initialState.audited2Step5Pdf

            state.step5Audited3 = initialState.step5Audited3
            state.audited3Step5Pdf = initialState.audited3Step5Pdf

            state.partnerOnboard = initialState.partnerOnboard
            state.partnerFiles = initialState.partnerFiles
            state.step3CopyId = initialState.step3CopyId

            state.step2MgmtToolPdf = initialState.step2MgmtToolPdf
            state.step3WtrAnalysisPdf = initialState.step3WtrAnalysisPdf
            state.step3MtrReadingPdf = initialState.step3MtrReadingPdf
            state.step3PermitPdf = initialState.step3PermitPdf
            state.step3PumpPdf = initialState.step3PumpPdf

            state.step5AssetsFile = initialState.step5AssetsFile
            state.step5BankStmntFile = initialState.step5BankStmntFile
            state.step5IncomeExpFile = initialState.step5IncomeExpFile

            state.stepGovFile = initialState.stepGovFile
            state.director = initialState.director
            state.tankCapacity = initialState.tankCapacity
            state.pipeExtent = initialState.pipeExtent
            state.bankInfos = initialState.bankInfos
            state.budgetInfo = initialState.budgetInfo
            state.calculations = initialState.calculations
        },
        cleanupFile: (state) => {
            state.partnerFiles = initialState.partnerFiles
        },
        cleanupMgmtTool: (state) => {
            state.step2MgmtToolPdf = initialState.step2MgmtToolPdf
        },
        cleanupWtrAnalysis: (state) => {
            state.step3WtrAnalysisPdf = initialState.step3WtrAnalysisPdf
        },
        cleanupMtrReading: (state) => {
            state.step3MtrReadingPdf = initialState.step3MtrReadingPdf
        },
        cleanupPermit: (state) => {
            state.step3PermitPdf = initialState.step3PermitPdf
        },
        cleanupPump: (state) => {
            state.step3PumpPdf = initialState.step3PumpPdf
        },
        cleanupAsset: (state) => {
            state.step5AssetsFile = initialState.step5AssetsFile
        },
        cleanupBank: (state) => {
            state.step5BankStmntFile = initialState.step5BankStmntFile
        },
        cleanupIncomeExp: (state) => {
            state.step5IncomeExpFile = initialState.step5IncomeExpFile
        },
        cleanupNationalId: (state) => {
            state.step3CopyId = initialState.step3CopyId
        },
        cleanupStatutory: (state) => {
            state.statutoryFilePdf = initialState.statutoryFilePdf
        },
        cleanupStatutory2: (state) => {
            state.statutoryFile2Pdf = initialState.statutoryFile2Pdf
        },
        cleanupStatutory3: (state) => {
            state.statutoryFile3Pdf = initialState.statutoryFile3Pdf
        },

        cleanupGovernment: (state) => {
            state.stepGovFile = initialState.stepGovFile
        },

        cleanupStep7Cor: (state) => {
            state.step7Cor = initialState.step7Cor
        },
        cleanupStep7Moa: (state) => {
            state.step7Moa = initialState.step7Moa
        },
        cleanupStep2Aoa: (state) => {
            state.step2AoaPdf = initialState.step2AoaPdf
        },
        cleanupStep2SeekLoan: (state) => {
            state.step2SeekLoanPdf = initialState.step2SeekLoanPdf
        },
        cleanupStep2MinutesCom: (state) => {
            state.step2MinutesComPdf = initialState.step2MinutesComPdf
        },
        cleanupStep2MinutesCom2: (state) => {
            state.step2MinutesCom2Pdf = initialState.step2MinutesComPdf
        },
        cleanupStep2MinutesCom3: (state) => {
            state.step2MinutesCom3Pdf = initialState.step2MinutesComPdf
        },

        cleanupStep4MembersList: (state) => {
            state.step4MembersListPdf = initialState.step4MembersListPdf
        },
        cleanupStep4ActiveContract: (state) => {
            state.step4ActiveContractPdf = initialState.step4ActiveContractPdf
        },
        cleanupStep4InstitutionContract: (state) => {
            state.step4InstitutionContractPdf = initialState.step4InstitutionContractPdf
        },
        cleanupStep4KioskContract: (state) => {
            state.step4KioskContractPdf = initialState.step4KioskContractPdf
        },
        cleanupStep4Map: (state) => {
            state.step4MapPdf = initialState.step4MapPdf
        },
        cleanupStep6ObjLetter: (state) => {
            state.step6ObjLetterPdf = initialState.step6ObjLetterPdf
        },



        cleanupMol: (state) => {
            state.molStep2 = initialState.mol
        },
        cleanupStep5Audited1: (state) => {
            state.audited1Step5Pdf = initialState.audited1Step5Pdf
        },
        cleanupStep5Audited2: (state) => {
            state.audited2Step5Pdf = initialState.audited2Step5Pdf
        },
        cleanupStep5Audited3: (state) => {
            state.audited3Step5Pdf = initialState.audited3Step5Pdf
        },


        setStep7Cor: (state, action) => {
            state.step7Cor = action.payload
        },
        setStep7Moa: (state, action) => {
            state.step7Moa = action.payload
        },


        setStep5Audited1: (state, action) => {
            state.step5Audited1 = action.payload
        },
        setStep5Audited2: (state, action) => {
            state.step5Audited2 = action.payload
        },
        setStep5Audited3: (state, action) => {
            state.step5Audited3 = action.payload
        },


    },
    extraReducers: (builder) => {
        builder

            //  onboardPartner
            .addCase(onboardPartner.pending, (state) => {
                state.isLoading = true
            })
            .addCase(onboardPartner.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.message = "onboard partner save successfull."
                state.partnerOnboard = action.payload

                // state.isCleanupForm = true
            })
            .addCase(onboardPartner.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.partnerOnboard = {}
                state.message = action.payload
                // state.isCleanupForm = false
            })

            //  onbaordFilesstep3
            .addCase(onbaordFiles.pending, (state) => {
                state.isLoading = true
            })
            .addCase(onbaordFiles.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccessS3 = true
                state.message = "onboard S3 successfull."
                state.partnerFiles = action.payload
                state.isCleanupFile = true
            })
            .addCase(onbaordFiles.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.partnerFiles = {}
                state.message = action.payload
                state.isCleanupFile = false
            })
            // onbaordStatutoryFile
            .addCase(onbaordStatutoryFile.pending, (state) => {
                state.isLoadingStatutory = true
            })
            .addCase(onbaordStatutoryFile.fulfilled, (state, action) => {
                state.isLoadingStatutory = false
                state.isSuccessStatutory = true
                state.statutoryFilePdf = action.payload
            })
            .addCase(onbaordStatutoryFile.rejected, (state, action) => {
                state.isLoadingStatutory = false
                state.isErrorStatutory = true
                state.statutoryFilePdf = {}
                state.message = action.payload
            })
            // onbaordStatutoryFile2
            .addCase(onbaordStatutoryFile2.pending, (state) => {
                state.isLoadingStatutory2 = true
            })
            .addCase(onbaordStatutoryFile2.fulfilled, (state, action) => {
                state.isLoadingStatutory2 = false
                state.isSuccessStatutory2 = true
                state.statutoryFile2Pdf = action.payload
            })
            .addCase(onbaordStatutoryFile2.rejected, (state, action) => {
                state.isLoadingStatutory2 = false
                state.isErrorStatutory2 = true
                state.statutoryFile2Pdf = {}
                state.message = action.payload
            })
            // onbaordStatutoryFile3
            .addCase(onbaordStatutoryFile3.pending, (state) => {
                state.isLoadingStatutory3 = true
            })
            .addCase(onbaordStatutoryFile3.fulfilled, (state, action) => {
                state.isLoadingStatutory3 = false
                state.isSuccessStatutory3 = true
                state.statutoryFile3Pdf = action.payload
            })
            .addCase(onbaordStatutoryFile3.rejected, (state, action) => {
                state.isLoadingStatutory3 = false
                state.isErrorStatutory3 = true
                state.statutoryFile3Pdf = {}
                state.message = action.payload
            })
            //  onbaordCopyIdStep3
            .addCase(onbaordCopyIdStep3.pending, (state) => {
                state.isLoadingCopyId = true
            })
            .addCase(onbaordCopyIdStep3.fulfilled, (state, action) => {
                state.isLoadingCopyId = false
                state.isSuccessCopyId = true
                state.step3CopyId = action.payload
            })
            .addCase(onbaordCopyIdStep3.rejected, (state, action) => {
                state.isLoadingCopyId = false
                state.isErrorCopyId = true
                state.step3CopyId = {}
                state.message = action.payload
            })

            // onbaordMgmtToolPdf
            .addCase(onbaordMgmtToolPdf.pending, (state) => {
                state.isLoadingMgmtTool = true
            })
            .addCase(onbaordMgmtToolPdf.fulfilled, (state, action) => {
                state.isLoadingMgmtTool = false
                state.isSuccessMgmtTool = true
                state.step2MgmtToolPdf = action.payload
            })
            .addCase(onbaordMgmtToolPdf.rejected, (state, action) => {
                state.isLoadingMgmtTool = false
                state.isErrorMgmtTool = true
                state.step2MgmtToolPdf = {}
                state.message = action.payload
            })

            //  onbaordWtrAnalysisPdf
            .addCase(onbaordWtrAnalysisPdf.pending, (state) => {
                state.isLoadingWtrAnalysis = true
            })
            .addCase(onbaordWtrAnalysisPdf.fulfilled, (state, action) => {
                state.isLoadingWtrAnalysis = false
                state.isSuccessWtrAnalysis = true
                state.step3WtrAnalysisPdf = action.payload
            })
            .addCase(onbaordWtrAnalysisPdf.rejected, (state, action) => {
                state.isLoadingWtrAnalysis = false
                state.isErrorWtrAnalysis = true
                state.step3WtrAnalysisPdf = {}
                state.message = action.payload
            })

            //  onbaordMtrReadingPdf
            .addCase(onbaordMtrReadingPdf.pending, (state) => {
                state.isLoading4MtrReading = true
            })
            .addCase(onbaordMtrReadingPdf.fulfilled, (state, action) => {
                state.isLoading4MtrReading = false
                state.isSuccess4MtrReading = true
                state.step3MtrReadingPdf = action.payload
            })
            .addCase(onbaordMtrReadingPdf.rejected, (state, action) => {
                state.isLoading4MtrReading = false
                state.isError4MtrReading = true
                state.step3MtrReadingPdf = {}
                state.message = action.payload
            })


            //  onbaordPermitPdf
            .addCase(onbaordPermitPdf.pending, (state) => {
                state.isLoadingPermit = true
            })
            .addCase(onbaordPermitPdf.fulfilled, (state, action) => {
                state.isLoadingPermit = false
                state.isSuccessPermit = true
                state.step3PermitPdf = action.payload
            })
            .addCase(onbaordPermitPdf.rejected, (state, action) => {
                state.isLoadingPermit = false
                state.isErrorPermit = true
                state.step3PermitPdf = {}
                state.message = action.payload
            })

            //  onbaordGovFile
            .addCase(onbaordGovFile.pending, (state) => {
                state.isLoadingGov = true
            })
            .addCase(onbaordGovFile.fulfilled, (state, action) => {
                state.isLoadingGov = false
                state.isSuccessGov = true
                state.stepGovFile = action.payload
            })
            .addCase(onbaordGovFile.rejected, (state, action) => {
                state.isLoadingGov = false
                state.isErrorGov = true
                state.stepGovFile = {}
                state.message = action.payload
            })


            //  onbaordPumpPdf
            .addCase(onbaordPumpPdf.pending, (state) => {
                state.isLoadingPump = true
            })
            .addCase(onbaordPumpPdf.fulfilled, (state, action) => {
                state.isLoadingPump = false
                state.isSuccessPump = true
                state.step3PumpPdf = action.payload
            })
            .addCase(onbaordPumpPdf.rejected, (state, action) => {
                state.isLoadingPump = false
                state.isErrorPump = true
                state.step3PumpPdf = {}
                state.message = action.payload
            })

            //  projAssetsFilesstep3
            .addCase(onbaordFilesAssets.pending, (state) => {
                state.isLoading = true
            })
            .addCase(onbaordFilesAssets.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccessS4 = true
                state.message = "onboard S4 successfull."
                state.projAssetsFiles = action.payload
                state.isCleanupFile = true
            })
            .addCase(onbaordFilesAssets.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.projAssetsFiles = {}
                state.message = action.payload
                state.isCleanupFile = false
            })

            //  onbaordAssetFile
            .addCase(onbaordAssetFile.pending, (state) => {
                state.isLoadingAssetsFile = true
            })
            .addCase(onbaordAssetFile.fulfilled, (state, action) => {
                state.isLoadingAssetsFile = false
                state.isSuccessAssetsFile = true
                state.step5AssetsFile = action.payload
            })
            .addCase(onbaordAssetFile.rejected, (state, action) => {
                state.isLoadingAssetsFile = false
                state.isErrorAssetsFile = true
                state.step5AssetsFile = {}
                state.message = action.payload
            })

            //  onbaordBankFile
            .addCase(onbaordBankFile.pending, (state) => {
                state.isLoadingBankStmntFile = true
            })
            .addCase(onbaordBankFile.fulfilled, (state, action) => {
                state.isLoadingBankStmntFile = false
                state.isSuccessBankStmntFile = true
                state.step5BankStmntFile = action.payload
            })
            .addCase(onbaordBankFile.rejected, (state, action) => {
                state.isLoadingBankStmntFile = false
                state.isErrorBankStmntFile = true
                state.step5BankStmntFile = {}
                state.message = action.payload
            })

            //  onbaordIncomeExpFile
            .addCase(onbaordIncomeExpFile.pending, (state) => {
                state.isLoadingIncomeExpFile = true
            })
            .addCase(onbaordIncomeExpFile.fulfilled, (state, action) => {
                state.isLoadingIncomeExpFile = false
                state.isSuccessIncomeExpFile = true
                state.step5IncomeExpFile = action.payload
            })
            .addCase(onbaordIncomeExpFile.rejected, (state, action) => {
                state.isLoadingIncomeExpFile = false
                state.isErrorIncomeExpFile = true
                state.step5IncomeExpFile = {}
                state.message = action.payload
            })

            //  onbaordCorStep7
            .addCase(onbaordCorStep7.pending, (state) => {
                state.isLoading = true
            })
            .addCase(onbaordCorStep7.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccessS7Cor = true
                state.message = "Certificate of registration successfull."
                state.step7Cor = action.payload
                state.isCleanupFile = true
            })
            .addCase(onbaordCorStep7.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.step7Cor = {}
                state.message = action.payload
                state.isCleanupFile = false
            })

            //  onbaordMoaStep7
            .addCase(onbaordMoaStep7.pending, (state) => {
                state.isLoading = true
            })
            .addCase(onbaordMoaStep7.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccessS7Moa = true
                state.message = "Memorandum cert successfull."
                state.step7Moa = action.payload
                state.isCleanupFile = true
            })
            .addCase(onbaordMoaStep7.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.step7Moa = {}
                state.message = action.payload
                state.isCleanupFile = false
            })
            //  onbaordAoaStep2
            .addCase(onbaordAoaStep2.pending, (state) => {
                state.isLoadingAoa = true
            })
            .addCase(onbaordAoaStep2.fulfilled, (state, action) => {
                state.isLoadingAoa = false
                state.isSuccessS7Ooa = true
                state.message = "articles cert successfull."
                state.step2AoaPdf = action.payload
            })
            .addCase(onbaordAoaStep2.rejected, (state, action) => {
                state.isLoadingAoa = false
                state.isError = true
                state.step2AoaPdf = {}
                state.message = action.payload
            })

            //  onbaordSeekLoanStep2
            .addCase(onbaordSeekLoanStep2.pending, (state) => {
                state.isLoadingSeekLoan = true
            })
            .addCase(onbaordSeekLoanStep2.fulfilled, (state, action) => {
                state.isLoadingSeekLoan = false
                state.isSuccessSeekLoan = true
                state.message = "Seek loan cert successfull."
                state.step2SeekLoanPdf = action.payload
            })
            .addCase(onbaordSeekLoanStep2.rejected, (state, action) => {
                state.isLoadingSeekLoan = false
                state.isErrorSeekLoan = true
                state.step2SeekLoanPdf = {}
                state.message = action.payload
            })

            // onbaordMinutesComStep2
            .addCase(onbaordMinutesComStep2.pending, (state) => {
                state.isLoadingMinutesCom = true
            })
            .addCase(onbaordMinutesComStep2.fulfilled, (state, action) => {
                state.isLoadingMinutesCom = false
                state.isSuccessMinutesCom = true
                state.message = "Committee minutes cert successfull."
                state.step2MinutesComPdf = action.payload
            })
            .addCase(onbaordMinutesComStep2.rejected, (state, action) => {
                state.isLoadingMinutesCom = false
                state.isErrorMinutesCom = true
                state.step2MinutesComPdf = {}
                state.message = action.payload
            })
            // onbaordMinutesCom2Step2
            .addCase(onbaordMinutesCom2Step2.pending, (state) => {
                state.isLoadingMinutesCom2 = true
            })
            .addCase(onbaordMinutesCom2Step2.fulfilled, (state, action) => {
                state.isLoadingMinutesCom2 = false
                state.isSuccessMinutesCom2 = true
                state.message = "Committee minutes cert successfull."
                state.step2MinutesCom2Pdf = action.payload
            })
            .addCase(onbaordMinutesCom2Step2.rejected, (state, action) => {
                state.isLoadingMinutesCom2 = false
                state.isErrorMinutesCom2 = true
                state.step2MinutesCom2Pdf = {}
                state.message = action.payload
            })
            // onbaordMinutesCom3Step2
            .addCase(onbaordMinutesCom3Step2.pending, (state) => {
                state.isLoadingMinutesCom3 = true
            })
            .addCase(onbaordMinutesCom3Step2.fulfilled, (state, action) => {
                state.isLoadingMinutesCom3 = false
                state.isSuccessMinutesCom3 = true
                state.message = "Committee minutes cert successfull."
                state.step2MinutesCom3Pdf = action.payload
            })
            .addCase(onbaordMinutesCom3Step2.rejected, (state, action) => {
                state.isLoadingMinutesCom3 = false
                state.isErrorMinutesCom3 = true
                state.step2MinutesCom3Pdf = {}
                state.message = action.payload
            })


            // onbaordMembersList
            .addCase(onbaordMembersList.pending, (state) => {
                state.isLoadingMembersList = true
            })
            .addCase(onbaordMembersList.fulfilled, (state, action) => {
                state.isLoadingMembersList = false
                state.isSuccessMembersList = true
                state.message = "Members list cert successfull."
                state.step4MembersListPdf = action.payload
            })
            .addCase(onbaordMembersList.rejected, (state, action) => {
                state.isLoadingMembersList = false
                state.isErrorMembersList = true
                state.step4MembersListPdf = {}
                state.message = action.payload
            })

            // onbaordActiveContract
            .addCase(onbaordActiveContract.pending, (state) => {
                state.isLoadingActiveContract = true
            })
            .addCase(onbaordActiveContract.fulfilled, (state, action) => {
                state.isLoadingActiveContract = false
                state.isSuccessActiveContract = true
                state.message = "Active contract cert successfull."
                state.step4ActiveContractPdf = action.payload
            })
            .addCase(onbaordActiveContract.rejected, (state, action) => {
                state.isLoadingActiveContract = false
                state.isErrorActiveContract = true
                state.step4ActiveContractPdf = {}
                state.message = action.payload
            })

            // onbaordInstitutionContract
            .addCase(onbaordInstitutionContract.pending, (state) => {
                state.isLoadingInstitutionContract = true
            })
            .addCase(onbaordInstitutionContract.fulfilled, (state, action) => {
                state.isLoadingInstitutionContract = false
                state.isSuccessInstitutionContract = true
                state.message = "Institution contract cert successfull."
                state.step4InstitutionContractPdf = action.payload
            })
            .addCase(onbaordInstitutionContract.rejected, (state, action) => {
                state.isLoadingInstitutionContract = false
                state.isErrorInstitutionContract = true
                state.step4InstitutionContractPdf = {}
                state.message = action.payload
            })


            // onbaordKioskContract
            .addCase(onbaordKioskContract.pending, (state) => {
                state.isLoadingKioskContract = true
            })
            .addCase(onbaordKioskContract.fulfilled, (state, action) => {
                state.isLoadingKioskContract = false
                state.isSuccessKioskContract = true
                state.message = "Kiosk contract cert successfull."
                state.step4KioskContractPdf = action.payload
            })
            .addCase(onbaordKioskContract.rejected, (state, action) => {
                state.isLoadingKioskContract = false
                state.isErrorKioskContract = true
                state.step4KioskContractPdf = {}
                state.message = action.payload
            })

            // onbaordMapPdf
            .addCase(onbaordMapPdf.pending, (state) => {
                state.isLoadingMapPdf = true
            })
            .addCase(onbaordMapPdf.fulfilled, (state, action) => {
                state.isLoadingMapPdf = false
                state.isSuccessMapPdf = true
                state.message = "Map cert successfull."
                state.step4MapPdf = action.payload
            })
            .addCase(onbaordMapPdf.rejected, (state, action) => {
                state.isLoadingMapPdf = false
                state.isErrorMapPdf = true
                state.step4MapPdf = {}
                state.message = action.payload
            })

            // onbaordObjPdf
            .addCase(onbaordObjPdf.pending, (state) => {
                state.isLoadingObjPdf = true
            })
            .addCase(onbaordObjPdf.fulfilled, (state, action) => {
                state.isLoadingObjPdf = false
                state.isSuccessObjPdf = true
                state.message = "Wsp Objection cert successfull."
                state.step6ObjLetterPdf = action.payload
            })
            .addCase(onbaordObjPdf.rejected, (state, action) => {
                state.isLoadingObjPdf = false
                state.isErrorObjPdf = true
                state.step6ObjLetterPdf = {}
                state.message = action.payload
            })


            //  onbaordMol
            .addCase(onbaordMol.pending, (state) => {
                state.isLoadingMol = true
            })
            .addCase(onbaordMol.fulfilled, (state, action) => {
                state.isLoadingMol = false
                state.isSuccessS7Mol = true
                state.message = "agm  minutes successfull."
                state.molStep2 = action.payload
            })
            .addCase(onbaordMol.rejected, (state, action) => {
                state.isLoadingMol = false
                state.isError = true
                state.molStep2 = {}
                state.message = action.payload
            })


            //  onbaordAudited1Step5
            .addCase(onbaordAudited1Step5.pending, (state) => {
                state.isLoadingAudited1 = true
            })
            .addCase(onbaordAudited1Step5.fulfilled, (state, action) => {
                state.isLoadingAudited1 = false
                state.isSuccessAudited1 = true
                state.message = "audited1  accounts successfull."
                state.audited1Step5Pdf = action.payload
            })
            .addCase(onbaordAudited1Step5.rejected, (state, action) => {
                state.isLoadingAudited1 = false
                state.isErrorAudited1 = true
                state.audited1Step5Pdf = {}
                state.message = action.payload
            })

            //  onbaordAudited2Step5
            .addCase(onbaordAudited2Step5.pending, (state) => {
                state.isLoadingAudited2 = true
            })
            .addCase(onbaordAudited2Step5.fulfilled, (state, action) => {
                state.isLoadingAudited2 = false
                state.isSuccessAudited2 = true
                state.message = "audited2  accounts successfull."
                state.audited2Step5Pdf = action.payload
            })
            .addCase(onbaordAudited2Step5.rejected, (state, action) => {
                state.isLoadingAudited2 = false
                state.isErrorAudited2 = true
                state.audited2Step5Pdf = {}
                state.message = action.payload
            })


            //  onbaordAudited3Step5
            .addCase(onbaordAudited3Step5.pending, (state) => {
                state.isLoadingAudited3 = true
            })
            .addCase(onbaordAudited3Step5.fulfilled, (state, action) => {
                state.isLoadingAudited3 = false
                state.isSuccessAudited3 = true
                state.message = "audited3  accounts successfull."
                state.audited3Step5Pdf = action.payload
            })
            .addCase(onbaordAudited3Step5.rejected, (state, action) => {
                state.isLoadingAudited3 = false
                state.isErrorAudited3 = true
                state.audited3Step5Pdf = {}
                state.message = action.payload
            })

            // fetchPartnerApplications
            .addCase(fetchPartnerApplications.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchPartnerApplications.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.partnerApplications = action.payload
            })
            .addCase(fetchPartnerApplications.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.partnerApplications = []
                state.message = action.payload
            })

            //  saveDirector
            .addCase(
                saveDirector.pending, (state) => {
                    state.isLoadingDirector = true
                }
            )
            .addCase(
                saveDirector.fulfilled, (state, action) => {
                    state.isLoadingDirector = false
                    state.isSuccessDirector = true
                    state.director.unshift(action.payload)
                }
            )
            .addCase(
                saveDirector.rejected, (state, action) => {
                    state.isLoadingDirector = false
                    state.isErrorDirector = true
                    state.message = action.payload
                }
            )

            // fetchDirectors
            .addCase(
                fetchDirectors.pending, (state) => {
                    state.isLoading = true
                }
            )
            .addCase(
                fetchDirectors.fulfilled, (state, action) => {
                    state.isLoading = false
                    state.isSuccess = true
                    // state.director = arrCleanUp(action.payload, "entryNo")
                    state.director = arrCleanUp(action.payload, "dirId")
                }
            )
            .addCase(
                fetchDirectors.rejected, (state, action) => {
                    state.isLoading = false
                    state.isError = true
                    state.message = action.payload
                }
            )

            // fetchDirectorsById
            .addCase(
                fetchDirectorsById.pending, (state) => {
                    state.isLoading = true
                }
            )
            .addCase(
                fetchDirectorsById.fulfilled, (state, action) => {
                    state.isLoading = false
                    state.isSuccess = true
                    state.directorEdit = action.payload[0]
                }
            )
            .addCase(
                fetchDirectorsById.rejected, (state, action) => {
                    state.isLoading = false
                    state.isError = true
                    state.message = action.payload
                }
            )

            //  updateDirector
            .addCase(
                updateDirector.pending, (state) => {
                    state.isLoadingDirectorUpdate = true
                }
            )
            .addCase(
                updateDirector.fulfilled, (state, action) => {
                    state.isLoadingDirectorUpdate = false
                    state.isSuccessUpdateDirector = true
                    state.director = action.payload
                }
            )
            .addCase(
                updateDirector.rejected, (state, action) => {
                    state.isLoadingDirectorUpdate = false
                    state.isErrorDirector = true
                    state.message = action.payload
                }
            )

            // deleteDirector
            .addCase(
                deleteDirector.pending, (state) => {
                    state.isLoadingDelete = true
                }
            )
            .addCase(
                deleteDirector.fulfilled, (state, action) => {
                    state.isLoadingDelete = false
                    state.isSuccessDelete = true
                    state.director = action.payload
                }
            )
            .addCase(
                deleteDirector.rejected, (state, action) => {
                    state.isLoadingDelete = false
                    state.isErrorDelete = true
                    state.message = action.payload
                }
            )

            //  saveTotalCapacity
            .addCase(
                saveTotalCapacity.pending, (state) => {
                    state.isLoadingTank = true
                }
            )
            .addCase(
                saveTotalCapacity.fulfilled, (state, action) => {
                    state.isLoadingTank = false
                    state.isSuccess = true
                    state.tankCapacity.unshift(action.payload)
                }
            )
            .addCase(
                saveTotalCapacity.rejected, (state, action) => {
                    state.isLoadingTank = false
                    state.isError = true
                    state.message = action.payload
                }
            )

            //fetchTotalCapacity
            .addCase(
                fetchTotalCapacity.pending, (state) => {
                    state.isLoadingTank = true
                }
            )
            .addCase(
                fetchTotalCapacity.fulfilled, (state, action) => {
                    state.isLoadingTank = false
                    state.isSuccess = true
                    state.tankCapacity = arrCleanUp(action.payload, "stId")
                }
            )
            .addCase(
                fetchTotalCapacity.rejected, (state, action) => {
                    state.isLoadingTank = false
                    state.isError = true
                    state.message = action.payload
                }
            )

            //  updateTotalCapacityOfStorages
            .addCase(
                updateTotalCapacityOfStorages.pending, (state) => {
                    state.isLoadingUpdateTank = true
                }
            )
            .addCase(
                updateTotalCapacityOfStorages.fulfilled, (state, action) => {
                    state.isLoadingUpdateTank = false
                    state.isSuccessUpdateTank = true
                    state.tankCapacity = action.payload
                }
            )
            .addCase(
                updateTotalCapacityOfStorages.rejected, (state, action) => {
                    state.isLoadingUpdateTank = false
                    state.isErrorTank = true
                    state.message = action.payload
                }
            )

            // deleteTotalCapacity
            .addCase(
                deleteTotalCapacity.pending, (state) => {
                    state.isLoadingDelete = true
                }
            )
            .addCase(
                deleteTotalCapacity.fulfilled, (state, action) => {
                    state.isLoadingDelete = false
                    state.isSuccessDelete = true
                    state.tankCapacity = action.payload
                }
            )
            .addCase(
                deleteTotalCapacity.rejected, (state, action) => {
                    state.isLoadingDelete = false
                    state.isErrorDelete = true
                    state.message = action.payload
                }
            )


            //savePipeExtent
            .addCase(
                savePipeExtent.pending, (state) => {
                    state.isLoadingPipe = true
                }
            )
            .addCase(
                savePipeExtent.fulfilled, (state, action) => {
                    state.isLoadingPipe = false
                    state.isSuccess = true
                    state.pipeExtent.unshift(action.payload)
                }
            )
            .addCase(
                savePipeExtent.rejected, (state, action) => {
                    state.isLoadingPipe = false
                    state.isError = true
                    state.message = action.payload
                }
            )

            //fetchPipeExtents
            .addCase(
                fetchPipeExtents.pending, (state) => {
                    state.isLoadingPipe = true
                }
            )
            .addCase(
                fetchPipeExtents.fulfilled, (state, action) => {
                    state.isLoadingPipe = false
                    state.isSuccess = true
                    state.pipeExtent = arrCleanUp(action.payload)
                }
            )
            .addCase(
                fetchPipeExtents.rejected, (state, action) => {
                    state.isLoadingPipe = false
                    state.isError = true
                    state.message = action.payload
                }
            )

            //  updatePipeExtent
            .addCase(
                updatePipeExtent.pending, (state) => {
                    state.isLoadingUpdatePipe = true
                }
            )
            .addCase(
                updatePipeExtent.fulfilled, (state, action) => {
                    state.isLoadingUpdatePipe = false
                    state.isSuccessUpdatePipe = true
                    state.pipeExtent = action.payload
                }
            )
            .addCase(
                updatePipeExtent.rejected, (state, action) => {
                    state.isLoadingUpdatePipe = false
                    state.isErrorPipe = true
                    state.message = action.payload
                }
            )

            // deletePipeExtent
            .addCase(
                deletePipeExtent.pending, (state) => {
                    state.isLoadingDelete = true
                }
            )
            .addCase(
                deletePipeExtent.fulfilled, (state, action) => {
                    state.isLoadingDelete = false
                    state.isSuccessDelete = true
                    state.pipeExtent = action.payload
                }
            )
            .addCase(
                deletePipeExtent.rejected, (state, action) => {
                    state.isLoadingDelete = false
                    state.isErrorDelete = true
                    state.message = action.payload
                }
            )


            //saveBankInfo
            .addCase(
                saveBankInfo.pending, (state) => {
                    state.isLoadingBank = true
                }
            )
            .addCase(
                saveBankInfo.fulfilled, (state, action) => {
                    state.isLoadingBank = false
                    state.isSuccess = true
                    state.bankInfos.unshift(action.payload)
                }
            )
            .addCase(
                saveBankInfo.rejected, (state, action) => {
                    state.isLoadingBank = false
                    state.isError = true
                    state.message = action.payload
                }
            )

            //fetchBankInfos
            .addCase(
                fetchBankInfos.pending, (state) => {
                    state.isLoadingBank = true
                }
            )
            .addCase(
                fetchBankInfos.fulfilled, (state, action) => {
                    state.isLoadingBank = false
                    state.isSuccess = true
                    state.bankInfos = arrCleanUp(action.payload, "bId")
                }
            )
            .addCase(
                fetchBankInfos.rejected, (state, action) => {
                    state.isLoadingBank = false
                    state.isError = true
                    state.message = action.payload
                }
            )

            //updateBankInfos
            .addCase(
                updateBankInfos.pending, (state) => {
                    state.isLoadingUpdateBank = true
                }
            )
            .addCase(
                updateBankInfos.fulfilled, (state, action) => {
                    state.isLoadingUpdateBank = false
                    state.isSuccessUpdateBank = true
                    state.bankInfos = action.payload
                }
            )
            .addCase(
                updateBankInfos.rejected, (state, action) => {
                    state.isLoadingUpdateBank = false
                    state.isErrorBank = true
                    state.message = action.payload
                }
            )
            // deleteBankInfo
            .addCase(
                deleteBankInfo.pending, (state) => {
                    state.isLoadingDelete = true
                }
            )
            .addCase(
                deleteBankInfo.fulfilled, (state, action) => {
                    state.isLoadingDelete = false
                    state.isSuccessDelete = true
                    state.bankInfos = action.payload
                }
            )
            .addCase(
                deleteBankInfo.rejected, (state, action) => {
                    state.isLoadingDelete = false
                    state.isErrorDelete = true
                    state.message = action.payload
                }
            )

            // deleteBudgetInfo
            .addCase(
                deleteBudgetInfo.pending, (state) => {
                    state.isLoadingDelete = true
                }
            )
            .addCase(
                deleteBudgetInfo.fulfilled, (state, action) => {
                    state.isLoadingDelete = false
                    state.isSuccessDelete = true
                    state.budgetInfo = action.payload
                }
            )
            .addCase(
                deleteBudgetInfo.rejected, (state, action) => {
                    state.isLoadingDelete = false
                    state.isErrorDelete = true
                    state.message = action.payload
                }
            )


            //saveBudgetInfo
            .addCase(
                saveBudgetInfo.pending, (state) => {
                    state.isLoadingBudget = true
                }
            )
            .addCase(
                saveBudgetInfo.fulfilled, (state, action) => {
                    state.isLoadingBudget = false
                    state.isSuccess = true
                    state.budgetInfo.unshift(action.payload)
                }
            )
            .addCase(
                saveBudgetInfo.rejected, (state, action) => {
                    state.isLoadingBudget = false
                    state.isError = true
                    state.message = action.payload
                }
            )

            //fetchBudgetInfo
            .addCase(
                fetchBudgetInfo.pending, (state) => {
                    state.isLoadingBudget = true
                }
            )
            .addCase(
                fetchBudgetInfo.fulfilled, (state, action) => {
                    state.isLoadingBudget = false
                    state.isSuccess = true
                    state.budgetInfo = arrCleanUp(action.payload, "biId")
                }
            )
            .addCase(
                fetchBudgetInfo.rejected, (state, action) => {
                    state.isLoadingBudget = false
                    state.isError = true
                    state.message = action.payload
                }
            )

            //updateBudgetInfo
            .addCase(
                updateBudgetInfo.pending, (state) => {
                    state.isLoadingUpdateBudget = true
                }
            )
            .addCase(
                updateBudgetInfo.fulfilled, (state, action) => {
                    state.isLoadingUpdateBudget = false
                    state.isSuccessUpdateBudget = true
                    state.budgetInfo = action.payload
                }
            )
            .addCase(
                updateBudgetInfo.rejected, (state, action) => {
                    state.isLoadingUpdateBudget = false
                    state.isErrorBudget = true
                    state.message = action.payload
                }
            )

            //saveStaff
            .addCase(
                saveStaff.pending, (state) => {
                    state.isLoadingStaff = true
                }
            )
            .addCase(
                saveStaff.fulfilled, (state, action) => {
                    state.isLoadingStaff = false
                    state.isSuccess = true
                    state.operationalStaff.unshift(action.payload)
                }
            )
            .addCase(
                saveStaff.rejected, (state, action) => {
                    state.isLoadingStaff = false
                    state.isErrorStaff = true
                    state.message = action.payload
                }
            )

            //fetchStaff
            .addCase(
                fetchStaff.pending, (state) => {
                    state.isLoadingStaff = true
                }
            )
            .addCase(
                fetchStaff.fulfilled, (state, action) => {
                    state.isLoadingStaff = false
                    state.isSuccess = true
                    state.operationalStaff = arrCleanUp(action.payload, "osId")
                }
            )
            .addCase(
                fetchStaff.rejected, (state, action) => {
                    state.isLoadingStaff = false
                    state.isErrorStaff = true
                    state.message = action.payload
                }
            )

            //  updateStaff
            .addCase(
                updateStaff.pending, (state) => {
                    state.isLoadingUpdateStaff = true
                }
            )
            .addCase(
                updateStaff.fulfilled, (state, action) => {
                    state.isLoadingUpdateStaff = false
                    state.isSuccessUpdateStaff = true
                    state.operationalStaff = action.payload
                }
            )
            .addCase(
                updateStaff.rejected, (state, action) => {
                    state.isLoadingUpdateStaff = false
                    state.isErrorStaff = true
                    state.message = action.payload
                }
            )

            // deleteStaff
            .addCase(
                deleteStaff.pending, (state) => {
                    state.isLoadingDelete = true
                }
            )
            .addCase(
                deleteStaff.fulfilled, (state, action) => {
                    state.isLoadingDelete = false
                    state.isSuccessDelete = true
                    state.operationalStaff = action.payload
                }
            )
            .addCase(
                deleteStaff.rejected, (state, action) => {
                    state.isLoadingDelete = false
                    state.isErrorDelete = true
                    state.message = action.payload
                }
            )


              //saveManagementTool
              .addCase(
                saveManagementTool.pending, (state) => {
                    state.isLoadingManagementTool = true
                }
            )
            .addCase(
                saveManagementTool.fulfilled, (state, action) => {
                    state.isLoadingManagementTool = false
                    state.isSuccessSaveManagementTool = true
                    state.managementTool.unshift(action.payload)
                }
            )
            .addCase(
                saveManagementTool.rejected, (state, action) => {
                    state.isLoadingManagementTool = false
                    state.isErrorManagementTool = true
                    state.message = action.payload
                }
            )

            //fetchManagementTool
            .addCase(
                fetchManagementTool.pending, (state) => {
                    state.isLoadingManagementTool = true
                }
            )
            .addCase(
                fetchManagementTool.fulfilled, (state, action) => {
                    state.isLoadingManagementTool = false
                    state.isSuccess = true
                    state.managementTool = arrCleanUp(action.payload, "mtId")
                }
            )
            .addCase(
                fetchManagementTool.rejected, (state, action) => {
                    state.isLoadingManagementTool = false
                    state.isErrorManagementTool = true
                    state.message = action.payload
                }
            )

            //  updateManagementTool
            .addCase(
                updateManagementTool.pending, (state) => {
                    state.isLoadingUpdateManagementTool = true
                }
            )
            .addCase(
                updateManagementTool.fulfilled, (state, action) => {
                    state.isLoadingUpdateManagementTool = false
                    state.isSuccessUpdateManagementTool = true
                    state.managementTool = action.payload
                }
            )
            .addCase(
                updateManagementTool.rejected, (state, action) => {
                    state.isLoadingUpdateManagementTool = false
                    state.isErrorManagementTool = true
                    state.message = action.payload
                }
            )

            // deleteManagementTool
            .addCase(
                deleteManagementTool.pending, (state) => {
                    state.isLoadingDelete = true
                }
            )
            .addCase(
                deleteManagementTool.fulfilled, (state, action) => {
                    state.isLoadingDelete = false
                    state.isSuccessDelete = true
                    state.managementTool = action.payload
                }
            )
            .addCase(
                deleteManagementTool.rejected, (state, action) => {
                    state.isLoadingDelete = false
                    state.isErrorDelete = true
                    state.message = action.payload
                }
            )



            //saveWaterSrc
            .addCase(
                saveWaterSrc.pending, (state) => {
                    state.isLoadingWtrSrc = true
                }
            )
            .addCase(
                saveWaterSrc.fulfilled, (state, action) => {
                    state.isLoadingWtrSrc = false
                    state.isSuccessSaveWtrSrc = true
                    state.wtrSrc.unshift(action.payload)
                }
            )
            .addCase(
                saveWaterSrc.rejected, (state, action) => {
                    state.isLoadingWtrSrc = false
                    state.isErrorWtrSrc = true
                    state.message = action.payload
                }
            )

            //fetchWaterSrc
            .addCase(
                fetchWaterSrc.pending, (state) => {
                    state.isLoadingWtrSrc = true
                }
            )
            .addCase(
                fetchWaterSrc.fulfilled, (state, action) => {
                    state.isLoadingWtrSrc = false
                    state.isSuccess = true
                    state.wtrSrc = arrCleanUp(action.payload, "wsId")
                }
            )
            .addCase(
                fetchWaterSrc.rejected, (state, action) => {
                    state.isLoadingWtrSrc = false
                    state.isErrorWtrSrc = true
                    state.message = action.payload
                }
            )

            //  updateWaterSrc
            .addCase(
                updateWaterSrc.pending, (state) => {
                    state.isLoadingUpdateWtrSrc = true
                }
            )
            .addCase(
                updateWaterSrc.fulfilled, (state, action) => {
                    state.isLoadingUpdateWtrSrc = false
                    state.isSuccessUpdateWtrSrc = true
                    state.wtrSrc = action.payload
                }
            )
            .addCase(
                updateWaterSrc.rejected, (state, action) => {
                    state.isLoadingUpdateWtrSrc = false
                    state.isErrorWtrSrc = true
                    state.message = action.payload
                }
            )

            // deleteWaterSrc
            .addCase(
                deleteWaterSrc.pending, (state) => {
                    state.isLoadingDelete = true
                }
            )
            .addCase(
                deleteWaterSrc.fulfilled, (state, action) => {
                    state.isLoadingDelete = false
                    state.isSuccessDelete = true
                    state.wtrSrc = action.payload
                }
            )
            .addCase(
                deleteWaterSrc.rejected, (state, action) => {
                    state.isLoadingDelete = false
                    state.isErrorDelete = true
                    state.message = action.payload
                }
            )





            //Calculations
            .addCase(fetchCalculate.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchCalculate.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.calculations = action.payload
            })
            .addCase(fetchCalculate.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.calculations = []
                state.message = action.payload
            })

    }
})


export const { reset, setStep2,
    setStatutoryFile,
    setStatutoryFile2,
    setStatutoryFile3,
    setStep2Date, setStep2AgmDate, setStep2CommitteeDate, setStep2CommitteeDate2, setStep2CommitteeDate3,setStep3Source,
    setStep3, setStep3ProjWaterSources, setMgmtToolFile, setWtrAnalysisFile, setMtrReadingFile, setPermitFile, setPumpFile,
    setStep4,
    setStep4MembersList, setStep4ActiveContract, setStep4InstitutionContract, setStep4KioskContract, setStep4Map, setStep6ObjLetter,
    setStep5, setStep5Date, setAssetFile, setBankFile, setStep5TariffType, setStep5Tariff, setStep5TariffMultiple, setStep5OtherCharges,
    setStep6, setStep6File, setStep6ProjProbSolved, setStep6ProjPurpose,
    setStep7,
    setStep7Cor, setStep7Moa, setStep2Aoa, setMol, setStep2SeekLoan,
    setStep2MinutesCom,
    setStep2MinutesCom2,
    setStep2MinutesCom3,
    setStep5Audited1, setStep5Audited2, setStep5Audited3,
    cleanupForm, setStep5IncomeExp, setStep5File, cleanupFile,
    cleanupMgmtTool,
    cleanupWtrAnalysis, cleanupMtrReading, cleanupPermit, cleanupPump,
    cleanupAsset, cleanupBank, cleanupIncomeExp, cleanupNationalId, cleanupGovernment,
    cleanupStatutory,
    cleanupStatutory2,
    cleanupStatutory3,
    cleanupStep7Cor, cleanupStep2Aoa, cleanupStep2SeekLoan,
    cleanupStep2MinutesCom,
    cleanupStep2MinutesCom2,
    cleanupStep2MinutesCom3,
    cleanupStep7Moa, cleanupMol,
    cleanupStep5Audited1, cleanupStep5Audited2, cleanupStep5Audited3,
    setEmailErrorDetails,
    cleanupStep4MembersList, cleanupStep4ActiveContract, cleanupStep4InstitutionContract, cleanupStep4KioskContract,
    cleanupStep4Map, cleanupStep6ObjLetter,
    setPhoneErrorStep1, setPhoneErrorAuditor
} = onboardPartnerSlice.actions
export default onboardPartnerSlice.reducer

