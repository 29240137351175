import { Box, Divider, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import {

  AccessTime,
  House,
  HouseSiding,
  RoomOutlined,
} from "@mui/icons-material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Chip, Input, Typography } from "@mui/joy";
import PageTitle from "../../components/page_title/PageTitle";
import { getExpressionDemandData } from "../../features/expression_demand/expressionDemandSlice";
import { cashConverter, formatPhoneNumber } from "../../utils";

const IntroPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const location = useLocation();
  const subTitle = location.pathname.split("/")[3]
  const { id, status } = useParams();
  console.log(id, "**********************ID INSTANT****************************8");
  const handleScreening = () => {
    navigate(`/screening/${id}/${subTitle}`)
  }

  const [expressLoading, setExpressLoading] = useState(false);

  const projectData = useSelector(
    (state) => state.expressionDemandData.expressionData
  );
  // const { loading, filterParam } = useSelector(
  //   (state) => state.expressionDemandData
  // );
  // const { user } = useSelector((state) => state.auth);

  const singleProjectData = projectData?.find(
    (item) => item?.projId === Number(id)
  );

  // function navigateToSheet() {
  //   navigate(`/submit-termsheet/${id}`);
  // }

  const fetchExpressData = async () => {
    setExpressLoading(true);
    const res = await dispatch(getExpressionDemandData({ projId: id }));
    setExpressLoading(false);
  };

  useEffect(() => {
    fetchExpressData();
  }, [id]);

  useEffect(() => {
    const body = document.querySelector("#root");

    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  }, [projectData]);


  return (
    <>
      <Box sx={{ width: "100%" }}>
        {/* <CustomSeparator /> */}
        <PageTitle currentPage="Classification" subTitle={subTitle} urlSubTitle={`/expression-demand/${subTitle}`}
          subSubTitle={singleProjectData?.accTradeName} subHeader="Screening" />
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            my: "24px",
          }}
        >
          <Typography className="fmw-technical-label-committed" sx={{ pr: 3, borderRight: "3px solid" }}>
            {singleProjectData?.accTradeName}
          </Typography>

          <RoomOutlined sx={{ fontSize: "48px !important", color: 'var(--color-technical)' }} />
          <Typography className="fmw-technical-label-committed"  >{singleProjectData?.accCounty} County</Typography>
        </Box>
        <Box
          sx={{
            width: "80%",
            display: "flex",
            background: 'rgba(248, 253, 255, 0.80)',
            border: '1px solid var(--color-light-blue)',
            justifyContent: "space-around",
            p: 3,
            fontWeight: 700,
            fontSize: "18px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 0.5,
              alignItems: "center",
            }}
          >
            <AccessTime sx={{ fontSize: "48px", fontWeight: 700, color: 'var(--color-technical) !important' }} />
            <Typography className="fmw-technical-label-committed" >
              {singleProjectData?.projOrgPeriod}
            </Typography>
            <Typography className="fmw-technical-label-committed"  >
              Service time
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 0.5,
              alignItems: "center",
            }}
          >
            <House sx={{ fontSize: "48px", fontWeight: 700, color: 'var(--color-technical) !important' }} />
            <Typography className="fmw-technical-label-committed">
              {singleProjectData?.projNumberHouseholdsServed}
            </Typography>
            <Typography className="fmw-technical-label-committed">
              households served
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 0.5,
              alignItems: "center",
            }}
          >
            <HouseSiding sx={{ fontSize: "48px", fontWeight: 700, color: 'var(--color-technical) !important' }} />
            <Typography className="fmw-technical-label-committed">
              {singleProjectData?.projNumberWaterKiosks}
            </Typography>
            <Typography className="fmw-technical-label-committed">
              water kiosks
            </Typography>
          </Box>
        </Box>
        <Typography sx={{ fontSize: "18px", mt: "48px", fontWeight: 700 }}>
          Project Valuation
        </Typography>
        <Typography sx={{ fontSize: "16px", fontWeight: 400, mt: "3px" }}>
          {cashConverter(singleProjectData?.projCost ? singleProjectData?.projCost : singleProjectData?.projEstCost)}
        </Typography>
        <Divider sx={{ margin: '34px 0px' }} />
        <Typography sx={{ fontSize: "18px", fontWeight: 700 }}>
          Project Summary
        </Typography>
        <Typography sx={{ fontSize: "16px", fontWeight: 400, mt: "3px" }}>
          {singleProjectData?.projDesc ? singleProjectData?.projDesc : singleProjectData?.projDescSubProj}
        </Typography>
        <Typography sx={{ fontSize: "18px", mt: "42px", fontWeight: 700 }}>
          Individual connections
        </Typography>
        <Box sx={{ display: "flex", gap: 4, mt: "15px" }}>

          <Box>
            <Chip size="lg" sx={{
              background: "#CFF0FC", fontWeight: 500, fontSize: "16px", color: "#222", padding: '10px 2rem',
              display: "flex", alignItems: 'center', justifyContent: 'center', textAlign: 'center', width: '200px',
            }}>
              Active | {singleProjectData?.projIndividualActiveConn}</Chip>
          </Box>

          <Box>
            <Chip size="lg"
              sx={{
                background: "#F4FCFF", fontWeight: 500, fontSize: "16px", color: "#222",
                display: "flex", alignItems: 'center', justifyContent: 'center', textAlign: 'center', width: '200px',
                padding: '10px 2rem', border: 'solid .5px #CFF0FC'
              }}>
              Closed | {singleProjectData?.projIndividualClosedConn}</Chip>
          </Box>

        </Box>
        <Typography sx={{ fontSize: "20px", mt: "42px", fontWeight: 700 }}>
          Institution or Commercial Connections
        </Typography>


        <Box sx={{ mt: "15px" }} >
          <Chip size="lg"
            sx={{
              background: "#F4FCFF", fontWeight: 500, fontSize: "16px", color: "#222",
              display: "flex", alignItems: 'center', justifyContent: 'center', textAlign: 'center', width: '200px',
              padding: '10px 2rem', border: 'solid .5px #CFF0FC'
            }}>
            {singleProjectData?.projInstitutionCommercialConn}
          </Chip>
        </Box>
        <Grid container>
          <Grid item xs={4} sx={{ mt: "72px", }}>
            <Input
              disabled
              sx={{ '--Input-decoratorChildHeight': '50px' }}
              label=""
              type="text"
              value="Confirmation of loan repayment capabilities"
              size="lg"
              className="fmw-input-joy-technical"
              endDecorator={
                <Button
                  disabled
                  variant="solid"
                  color="primary"
                  type="submit"
                  sx={{ borderRadius: 0, backgroundColor: '#44BA41 !important' }}
                >
                  {singleProjectData?.projPayCapable}
                </Button>
              }
            />
          </Grid>
          <Grid item xs={8}></Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            display: "flex",
            background: "#F8FDFF",
            p: 2,
            fontWeight: 200,
            fontSize: "16px",
            color: "#000000",
            border: 'solid 1px var(--color-light-blue)',
            borderRadius: '12px',
            mt: "42px",
            py: "38px",
            px: "41px",
          }}
        >
          <Typography sx={{ mb: "36px", fontWeight: 500, fontSize: '18px' }}>Contact & Address Info</Typography>
          <Box sx={{ display: "flex", gap: 5, width: "100%" }} className="basic-intro">
            <Box sx={{ width: "50%" }}>
              <Table>
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component='th' scope='row' sx={{ fontWeight: '500 !important' }}>
                      Contact Person
                    </TableCell>
                    <TableCell>{singleProjectData?.usrFirstName} {singleProjectData?.usrLastName}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component='th' scope='row' sx={{ fontWeight: '500 !important' }}>
                      Email
                    </TableCell>
                    <TableCell>{singleProjectData?.usrEmail}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component='th' scope='row' sx={{ fontWeight: '500 !important' }}>
                      Phone Number
                    </TableCell>
                    <TableCell>{formatPhoneNumber(singleProjectData?.usrMobileNumber)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component='th' scope='row' sx={{ fontWeight: '500 !important' }}>
                      Alternative Phone Number
                    </TableCell>
                    <TableCell>{formatPhoneNumber(singleProjectData?.usrAltPhoneNo)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
            <Box sx={{ width: "50%" }}>
              <Table>
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component='th' scope='row' sx={{ fontWeight: '500 !important' }}>
                      County
                    </TableCell>
                    <TableCell>{singleProjectData?.accCounty}</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component='th' scope='row' sx={{ fontWeight: '500 !important' }}>
                      Sub County
                    </TableCell>
                    <TableCell>{singleProjectData?.accSubCounty}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component='th' scope='row' sx={{ fontWeight: '500 !important' }}>
                      Location
                    </TableCell>
                    <TableCell>{singleProjectData?.accLocation}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Box>


        <Box sx={{ mt: 5, mb: 10, display: "flex", justifyContent: 'flex-end', }}>
          <Button
            onClick={handleScreening}
            sx={{
              color: 'neutral',
              mr: 10,
              p: 1.9,
              width: "10%",
              textTransform: "capitalize",
              backgroundColor: "var(--color-technical) !important",
              alignSelf: "center",
              borderRadius: "0px",
              width: "200px",
              fontSize: "16px",
              borderRadius: "2px !important",
              fontWeight: 500
            }}
          >
            Start Screening
          </Button>
        </Box>
      </Box>

    </>
  );
};

export default IntroPage;
