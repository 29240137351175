import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NoTableDataComponent from '../../components/NoTableDataComponent';
import SingleTableItem from '../../components/single_table_item';



function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Meter reader', 159),
  createData('Project manager', 237),
];



export default function OperationStaff({ handleChangeInput, trust, data,
  userComment,
  setUserComment,
  setMatrixInfo,
  matrixInfo,
  name,
  stepName,
  id,
  status,
  comment,
  setComment,
  setUserMatrixInfo,
  userMatrixInfo
}) {
  const location = useLocation();
  const category = location.pathname.split('/')[3]
  const { isLoadingStaff, operationalStaff } = useSelector(
    (state) => state.onboardPartner
  );

  return (
    <TableContainer component={Paper} elevation={0} sx={{ background: 'inherit' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow className='table'>
            <TableCell>Type of staff</TableCell>
            <TableCell align="left">Number</TableCell>
            <TableCell align="left">Type of contract</TableCell>
          </TableRow>
        </TableHead>
        {operationalStaff?.length > 0 ? (<TableBody className='basic-comment'>
          {operationalStaff?.map((row) => (
            <TableRow
              key={row?.osId}
            >
              <TableCell component="th" scope="row" sx={{ borderLeft: 'none !important', width: '33.33%' }}>
                {row?.osStaff}
              </TableCell>
              <TableCell align="left" sx={{ width: '33.33%' }}>{row?.osNumber}</TableCell>
              <TableCell align="left" sx={{ width: '33.33%' }} >{row?.osContract}</TableCell>
            </TableRow>
          ))}
        </TableBody>) : <NoTableDataComponent />}
      </Table>
      {
        (category == "Viable" || category == "Partner" || category == "poor" || category == "Assistance") && (
          <Box sx={{ padding: '20px', mt: 2 }}>
            <SingleTableItem
              overallComment="true"
              userComment={userComment}
              setUserComment={setUserComment}
              setMatrixInfo={setMatrixInfo}
              matrixInfo={matrixInfo}
              name="operationsScores"
              stepName={stepName}
              id={data?.projId}
              maxScores="5"
              status={category}
              comment={comment}
              setComment={setComment}

              setUserMatrixInfo={setUserMatrixInfo}
              userMatrixInfo={userMatrixInfo}

            />

          </Box>
        )
      }

    </TableContainer>
  );
}