import React, { useState } from 'react';
import MonitoringTable from './components/MonitoringTable';

import PageHeader from '../../../components/global/PageHeader';
import Search from '../../../components/global/Search';
import { Breadcrumb } from 'antd';


const breadList = [
	{
		title: 'Home',
		href: '/',
	},
	{
		title: 'Impact Dashboard',
		href: '/',
	},
	{
		title: 'Monitoring and Evaluation',
	},
];

const Monitoring = () => {
	const [value, setvalue] = useState('');

	function handleValueChange(val) {
		setvalue(val);
	}

	return (
		<div className='flex flex-col h-screen  w-[75vw]'>
			<PageHeader header={'Monitoring and Evaluation'} />
			<div className='mt-[.94rem]'>
				<Breadcrumb routes={breadList} />
			</div>

			<div className='mt-[3rem] text-blk text-[1.125rem] font-[600]'>
				M&E Report
			</div>

			<div className='mt-[1.19rem]'>
				<Search text={true} handleValueChange={handleValueChange} />
			</div>

			<MonitoringTable />
		</div>
	);
};

export default Monitoring;
