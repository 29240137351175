import React, { useEffect, useState } from "react";
import { Typography } from "@mui/joy";
import { Box, Grid, Table, TableBody, TableCell, TableRow } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import {
  DragIndicator,
  ExpandMore,
} from "@mui/icons-material";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { fetchProjectScore, postScoreStep, setProjectScore } from "../../../../features/expression_demand/expressionDemandSlice";
import ParticularsTable from "./ParticularsTable";
import SingleAttachmentComplete from "../../components/SingleAttachmentComplete";
import SingleItem from "../../components/single_item";
import TextareaComment from "../../components/TextareaComment";

const ApplicantParticulars = () => {
  const { singleProject } = useSelector((state) => state.global);
  const { scores } = useSelector((state) => state.expressionDemandData);

  const [expanded, setExpanded] = React.useState('panel1');
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  const dispatch = useDispatch();
  const stepName = "CUSTOMER_DETAIL";
  const [comment, setComment] = useState("");
  const [onBlur, setOnBlur] = useState(true);
  const [matrixInfo, setMatrixInfo] = useState({});
  const [userMatrixInfo, setUserMatrixInfo] = useState({});
  const [userComment, setUserComment] = useState({});

  function createData(name, description, variableName, maxScores) {
    return { name, description, variableName, maxScores };
  }


  const fetchProjectScores = async () => {
    const info = { projId: singleProject?.projId, page: stepName };
    await dispatch(fetchProjectScore(info));
  };

  useEffect(() => {
    fetchProjectScores();
  }, []);




  const values = Object.values(matrixInfo)?.map((v) => parseInt(v) || 0);

  const total = values.reduce((acc, val) => acc + val, 0);


  const handleTotalScores = () => {
    dispatch(setProjectScore({ [stepName]: total }));
  };


  const {
    projIndividualActiveConn,
    projIndividualClosedConn,
    projArea,
    projLat,
    projLong,
    projTotalPopulation,
    projAproxPopulationServed,
    projInstitutionCommercialConn,
    projNumberHouseholdsServed,
    projNumberWaterKiosks,
    projMapAreaFile,
    projKioskConnFile,
    projInstConnFile,
    projIndActiveConnMbrFile,
    projIndActiveConnCustFile,
  } = singleProject;


  function handleCommentScore() {
    scores?.forEach((score) => {
      const { scScore, scMethod, scUsrScore, scUsrComment } = score;
      setMatrixInfo((prevMatrixInfo) => ({
        ...prevMatrixInfo,
        [scMethod]: scScore,
      }));
      setUserMatrixInfo((prevMatrixInfo) => ({
        ...prevMatrixInfo,
        [scMethod]: scUsrScore,
      }));
      setUserComment((prevMatrixInfo) => ({
        ...prevMatrixInfo,
        [scMethod]: scUsrComment,
      }));
    });
  }

  useEffect(() => {
    fetchProjectScores();
    handleCommentScore();
  }, []);

  useEffect(() => {
    handleCommentScore();
  }, [singleProject, scores]);

  useEffect(() => {
    handleTotalScores();
  }, [total]);

  const customerDetails = [
    createData(
      "Individual active connections",
      projIndividualActiveConn,
      "projIndividualActiveConn",
      10
    ),

    createData(
      "Institution or commercial connections",
      projInstitutionCommercialConn,
      "projInstitutionCommercialConn",
      10
    ),
    createData(
      "Number of water kiosks",
      projNumberWaterKiosks,
      "projNumberWaterKiosks",
      10
    ),

  ];

  const customerDetails2 = [
    createData(
      "Individual closed connections",
      projIndividualClosedConn,
      "projIndividualClosedConn"
    ),
    createData(
      "Number of households served",
      projNumberHouseholdsServed,
      "projNumberHouseholdsServed"
    ),
    createData(
      "Approximate population served",
      projAproxPopulationServed,
      "projAproxPopulationServed"
    ),
    createData(
      "Approximate total population in service area",
      projTotalPopulation,
      "projTotalPopulation"
    ),
    createData(
      <>
        Service provision area (km<sup>2</sup>)
      </>,
      projArea,
      "projArea"
    ),
    createData(
      "Grid reference of office / infrastructure",
      `Latitude: ${projLat ?? "N/A"}, longitude: ${projLong ?? "N/A"}`,
      "projLat"
    ),
  ];

  const customerAttachments = [
    createData(
      "PDF of map of service area(pdf)",
      projMapAreaFile,
      "projMapAreaFile"
    ),
    createData(
      "PDF of sample kiosks contract(pdf)",
      projKioskConnFile,
      "projKioskConnFile"
    ),
    createData(
      "PDF of sample Institution contract(pdf)",
      projInstConnFile,
      "projInstConnFile"
    ),
    createData(
      "PDF of list of members or accounts(pdf)",
      projIndActiveConnMbrFile,
      "projIndActiveConnMbrFile"
    ),
    createData(
      "PDF of sample customer contract(pdf)",
      projIndActiveConnCustFile,
      "projIndActiveConnCustFile"
    ),
  ];



  return (
    <Box sx={{ display: "flex", gap: "30.03px", flexDirection: "column" }}>
      {
        singleProject?.projStatus == 'complete' || singleProject?.projStatus == "incomplete" ? (
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            className='fmw-accrdion-table fmw-accrd'
            elevation={0}
          >
            <AccordionSummary
              expandIcon={<ExpandMore sx={{ color: expanded == 'panel1' ? '#7E93AE' : 'var(--color-technical)' }}
                className='fmw-accrdion-svg'
              />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >

              <Typography
                className='gap-5 accordion-title'
                sx={{ width: '33%', flexShrink: 0, paddingBottom: '15px !important' }}>
                <DragIndicator className='fmw-accrdion-drag-svg' />
                Particulars of the applicant
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '0px !important' }}>
              <ParticularsTable />
            </AccordionDetails>
          </Accordion>
        ) : (
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            className='fmw-accrdion'
            elevation={0}
          >
            <AccordionSummary
              expandIcon={<ExpandMore sx={{ color: expanded == 'panel1' ? '#7E93AE' : 'var(--color-technical)' }}
                className='fmw-accrdion-svg'
              />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >

              <Typography
                className='gap-5 accordion-title'
                sx={{ width: '33%', flexShrink: 0, }}>
                <DragIndicator className='fmw-accrdion-drag-svg' />
                Particulars of the applicant
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <Table>
                  <TableBody>
                    {
                      customerDetails2?.map((row) => (
                        <TableRow
                        >
                          <TableCell component='th' scope='row'
                            className="gap-5"
                            sx={{ fontWeight: '400 !important', fontSize: '16px', display: 'flex', alignItems: 'center' }}>
                            <span>{row?.name}</span>
                            <div style={{ height: '1px', width: '20px', background: '#000000DE', fontSize: '16px', fontWeight: 400 }}></div>
                            <span>{row?.description}</span>
                          </TableCell>

                        </TableRow>
                      ))
                    }
                  </TableBody>

                  <TableBody>
                    {
                      customerAttachments?.map((row) => (
                        <TableRow
                        >
                          <TableCell component='th' scope='row'
                            className="gap-5"
                            sx={{ fontWeight: '400 !important', fontSize: '16px', display: 'flex', alignItems: 'center' }}>
                            <span>{row?.name}</span>
                            <div style={{ height: '1px', width: '20px', background: '#000000DE', fontSize: '16px', fontWeight: 400 }}></div>
                            <span> <SingleAttachmentComplete
                              title={row?.description}
                              tableFile="true"
                            /></span>
                          </TableCell>

                        </TableRow>
                      ))
                    }
                  </TableBody>
                </Table>

                {
                  customerDetails?.map((row) => (
                    <SingleItem
                      row={row}
                      userComment={userComment}
                      setUserComment={setUserComment}
                      setMatrixInfo={setMatrixInfo}
                      matrixInfo={matrixInfo}
                      name={row.variableName}
                      stepName={stepName}
                      id={singleProject?.projId}
                      maxScores={row.maxScores}
                      setUserMatrixInfo={setUserMatrixInfo}
                      userMatrixInfo={userMatrixInfo}

                    />
                  ))
                }

              </Box>
            </AccordionDetails>
          </Accordion>
        )
      }




      {/* <Grid item xs={12}>
            <Grid item xs={12}>
              <TextareaComment
                comment={comment}
              />
            </Grid>
          </Grid> */}

    </Box>
  );
};

export default ApplicantParticulars;
