import React, { useEffect, useState } from 'react';
import { Textarea, Typography } from '@mui/joy';
import {
	Box,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableRow,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { DragIndicator, ExpandMore } from '@mui/icons-material';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cashConverter, dateConverter } from '../../../../utils';
import {
	fetchProjectScore,
	getbankInformation,
	postScoreStep,
	setProjectScore,
} from '../../../../features/expression_demand/expressionDemandSlice';
import { toast } from 'react-toastify';
import FinancialTable from './FinancialTable';
import SingleItem from '../../components/single_item';
import TarrifTable from '../../components/TarrifTable';
import AuditorTable from './AuditorTable';
import BankTable from './BankTable';
import Attachments from '../water_source_infrastracture/Attachments';
import SingleAttachment from '../../components/single_attachment';
import TextareaComment from '../../components/TextareaComment';

const FinancialPosition = () => {
	const { singleProject } = useSelector((state) => state.global);
	const { scores, projectData } = useSelector(
		(state) => state.expressionDemandData,
	);

	const [expanded, setExpanded] = React.useState('panel1');
	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const stepName = 'FINANCIAL_SUMMARY';
	const [matrixInfo, setMatrixInfo] = useState({});
	const [userMatrixInfo, setUserMatrixInfo] = useState({});
	const [userComment, setUserComment] = useState({});
	const [onBlur, setOnBlur] = useState(true);
	const [comment, setComment] = useState('');

	const values = Object.values(matrixInfo).map((v) => parseInt(v) || 0);

	const total = values.reduce((acc, val) => acc + val, 0);
	const dispatch = useDispatch();

	const {
		projHandCash,
		projBankCash,
		projTotalDebtors,
		projLiquidAssets,
		projLiabilities,
		projNetFinances,
		projFinDate,
		projAuditorName,
		projAuditorFirm,
		projAuditorTelephone,
		projAuditorEmail,
		projIncomeExpenditureFile,
		projBankStatementFile,
		projAudiAccFile3,
		projAudiAccFile,
		projAudiAccFile2,
		projAssets,
		projBillingSystem,
		projTariffSystem,
		projId,
	} = singleProject;

	function createData(name, description, variableName, maxScores) {
		return { name, description, variableName, maxScores };
	}

	const fetchReduxData = () => {
		dispatch(getbankInformation(projId));
	};

	const fetchProjectScores = async () => {
		const info = { projId: singleProject?.projId, page: stepName };
		await dispatch(fetchProjectScore(info));
	};

	const tableOneData = [
		createData(
			'As on',
			projFinDate ? dateConverter(projFinDate) : 'N/A',
			'projFinDate',
		),
		createData(
			'Cash in hand (petty cash)',
			cashConverter(projHandCash),
			'projHandCash',
		),
		createData(
			'Cash in bank account(s)',
			cashConverter(projBankCash),
			'projBankCash',
		),
		createData(
			'Total debtors (Money owed to project)',
			cashConverter(projTotalDebtors),
			'projTotalDebtors',
		),
		createData(
			'Total liquid assets [(a) + (b) + (c)]',
			cashConverter(projLiquidAssets),
			'projLiquidAssets',
		),
		createData(
			'Liabilities(Money owed by project to others)',
			cashConverter(projLiabilities),
			'projLiabilities',
		),
		createData(
			'Net financial position [(d) - (e)]',
			cashConverter(projNetFinances),
			'projNetFinances',
		),
	];
	const tableOneData2 = [
		createData(
			'Type of billing system',
			projBillingSystem,
			'projBillingSystem',
			5,
		),
	];
	const auditorInfo = [
		createData('Name', projAuditorName ?? 'N/A', 'projAuditorName'),
		createData('Firm', projAuditorFirm ?? 'N/A', 'projAuditorFirm'),
		createData(
			'Telephone',
			projAuditorTelephone ?? 'N/A',
			'projAuditorTelephone',
		),
		createData('Email', projAuditorEmail ?? 'N/A', 'projAuditorEmail'),
	];

	const matrixFields = [
		'projIndividualActiveConn',
		'projInstitutionCommercialConn',
		'projNumberWaterKisosks',
		'projIncomeExpenditureFile',
		'projAudiAccFile3',
		'projAudiAccFile2',
		'projAudiAccFile',
		'projBillingSystem',
	];

	function handleCommentScore() {
		scores?.forEach((score) => {
			const { scScore, scMethod, scUsrScore, scUsrComment } = score;
			setMatrixInfo((prevMatrixInfo) => ({
				...prevMatrixInfo,
				[scMethod]: scScore,
			}));
			setUserMatrixInfo((prevMatrixInfo) => ({
				...prevMatrixInfo,
				[scMethod]: scUsrScore,
			}));
			setUserComment((prevMatrixInfo) => ({
				...prevMatrixInfo,
				[scMethod]: scUsrComment,
			}));
		});
	}

	useEffect(() => {
		fetchProjectScores();
		handleCommentScore();
	}, []);

	useEffect(() => {
		handleCommentScore();
	}, [singleProject, scores]);

	useEffect(() => {
		fetchReduxData();
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		setTimeout(() => {
			fetchReduxData();
		}, 1000);
	}, []);

	const attachments = [
		createData(
			'Most recent months financial data',
			projIncomeExpenditureFile,
			'projIncomeExpenditureFile',
			10,
		),
		// createData("List of all assets owned", projAssets, "projAssets"),
		// createData(
		//   "PDF of bank statements for last 12 months",
		//   projBankStatementFile,
		//   "projBankStatementFile"
		// ),
		createData('First Audit Report', projAudiAccFile, 'projAudiAccFile', 1),
		createData('Second audit report', projAudiAccFile2, 'projAudiAccFile2', 2),
		createData('Third audit report', projAudiAccFile3, 'projAudiAccFile3', 2),
	];

	return (
		<Box sx={{ display: 'flex', gap: '30.03px', flexDirection: 'column' }}>
			{singleProject?.projStatus == 'complete' ||
				singleProject?.projStatus == 'incomplete' ? (
				<Accordion
					expanded={expanded === 'panel1'}
					onChange={handleChange('panel1')}
					className='fmw-accrdion-table fmw-accrd'
					elevation={0}
				>
					<AccordionSummary
						expandIcon={
							<ExpandMore
								sx={{
									color:
										expanded == 'panel1' ? '#7E93AE' : 'var(--color-technical)',
								}}
								className='fmw-accrdion-svg'
							/>
						}
						aria-controls='panel1bh-content'
						id='panel1bh-header'
					>
						<Typography
							className='gap-5 accordion-title'
							sx={{
								width: '33%',
								flexShrink: 0,
								paddingBottom: '15px !important',
							}}
						>
							<DragIndicator className='fmw-accrdion-drag-svg' />
							Summary of financial positions
						</Typography>
					</AccordionSummary>
					<AccordionDetails sx={{ padding: '0px !important' }}>
						<FinancialTable />
					</AccordionDetails>
				</Accordion>
			) : (
				<Accordion
					expanded={expanded === 'panel1'}
					onChange={handleChange('panel1')}
					className='fmw-accrdion'
					elevation={0}
				>
					<AccordionSummary
						expandIcon={
							<ExpandMore
								sx={{
									color:
										expanded == 'panel1' ? '#7E93AE' : 'var(--color-technical)',
								}}
								className='fmw-accrdion-svg'
							/>
						}
						aria-controls='panel1bh-content'
						id='panel1bh-header'
					>
						<Typography
							className='gap-5 accordion-title'
							sx={{ width: '33%', flexShrink: 0 }}
						>
							<DragIndicator className='fmw-accrdion-drag-svg' />
							Summary of financial positions
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Box
							sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
						>
							<Table>
								<TableBody>
									{tableOneData?.map((row) => (
										<TableRow>
											<TableCell
												component='th'
												scope='row'
												className='gap-5'
												sx={{
													fontWeight: '400 !important',
													fontSize: '16px',
													display: 'flex',
													alignItems: 'center',
												}}
											>
												<span>{row?.name}</span>
												<div
													style={{
														height: '1px',
														width: '20px',
														background: '#000000DE',
														fontSize: '16px',
														fontWeight: 400,
													}}
												></div>
												<span>{row?.description}</span>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>

							{tableOneData2?.map((row) => (
								<SingleItem
									row={row}
									userComment={userComment}
									setUserComment={setUserComment}
									setMatrixInfo={setMatrixInfo}
									matrixInfo={matrixInfo}
									name={row.variableName}
									stepName={stepName}
									id={singleProject?.projId}
									maxScores={row.maxScores}
									setUserMatrixInfo={setUserMatrixInfo}
									userMatrixInfo={userMatrixInfo}
								/>
							))}
						</Box>
					</AccordionDetails>
				</Accordion>
			)}

			<Accordion
				expanded={expanded === 'panel2'}
				onChange={handleChange('panel2')}
				className='fmw-accrdion-table fmw-accrd'
				elevation={0}
			>
				<AccordionSummary
					expandIcon={
						<ExpandMore
							sx={{
								color:
									expanded == 'panel2' ? '#7E93AE' : 'var(--color-technical)',
							}}
							className='fmw-accrdion-svg'
						/>
					}
					aria-controls='panel2bh-content'
					id='panel2bh-header'
				>
					<Typography
						className='gap-5 accordion-title'
						sx={{
							width: '50%',
							flexShrink: 0,
							paddingBottom: '15px !important',
						}}
					>
						<DragIndicator className='fmw-accrdion-drag-svg' />
						Tariff system in place -{' '}
						{singleProject?.projTariffSystem === 'Volumetric'
							? 'Volumetric (Based on volume consumed)'
							: 'Flat rate (same rate every month)'}
					</Typography>
				</AccordionSummary>
				<AccordionDetails sx={{ padding: '0px !important' }}>
					<TarrifTable />
				</AccordionDetails>
			</Accordion>

			<Accordion
				expanded={expanded === 'panel3'}
				onChange={handleChange('panel3')}
				className='fmw-accrdion-table fmw-accrd'
				elevation={0}
			>
				<AccordionSummary
					expandIcon={
						<ExpandMore
							sx={{
								color:
									expanded == 'panel3' ? '#7E93AE' : 'var(--color-technical)',
							}}
							className='fmw-accrdion-svg'
						/>
					}
					aria-controls='panel3bh-content'
					id='panel3bh-header'
				>
					<Typography
						className='gap-5 accordion-title'
						sx={{
							width: '50%',
							flexShrink: 0,
							paddingBottom: '15px !important',
						}}
					>
						<DragIndicator className='fmw-accrdion-drag-svg' />
						Auditor contacts
					</Typography>
				</AccordionSummary>
				<AccordionDetails sx={{ padding: '0px !important' }}>
					<AuditorTable
						auditorInfo={auditorInfo}
						userComment={userComment}
						setUserComment={setUserComment}
						setMatrixInfo={setMatrixInfo}
						matrixInfo={matrixInfo}
						name='auditScores'
						stepName={stepName}
						id={singleProject?.projId}
						comment={comment}
						setComment={setComment}
						setUserMatrixInfo={setUserMatrixInfo}
						userMatrixInfo={userMatrixInfo}
					/>
				</AccordionDetails>
			</Accordion>

			<Accordion
				expanded={expanded === 'panel4'}
				onChange={handleChange('panel4')}
				className='fmw-accrdion-table fmw-accrd'
				elevation={0}
			>
				<AccordionSummary
					expandIcon={
						<ExpandMore
							sx={{
								color:
									expanded == 'panel4' ? '#7E93AE' : 'var(--color-technical)',
							}}
							className='fmw-accrdion-svg'
						/>
					}
					aria-controls='panel4bh-content'
					id='panel4bh-header'
				>
					<Typography
						className='gap-5 accordion-title'
						sx={{
							width: '50%',
							flexShrink: 0,
							paddingBottom: '15px !important',
						}}
					>
						<DragIndicator className='fmw-accrdion-drag-svg' />
						Bank information
					</Typography>
				</AccordionSummary>
				<AccordionDetails sx={{ padding: '0px !important' }}>
					<BankTable
						userComment={userComment}
						setUserComment={setUserComment}
						setMatrixInfo={setMatrixInfo}
						matrixInfo={matrixInfo}
						name='director'
						stepName={stepName}
						id={singleProject?.projId}
						comment={comment}
						setComment={setComment}
						setUserMatrixInfo={setUserMatrixInfo}
						userMatrixInfo={userMatrixInfo}
					/>
				</AccordionDetails>
			</Accordion>

			{singleProject?.projStatus == 'complete' ||
				singleProject?.projStatus == 'incomplete' ? (
				<Accordion
					expanded={expanded === 'panel1'}
					onChange={handleChange('panel1')}
					className='fmw-accrdion-table fmw-accrd'
					elevation={0}
				>
					<AccordionSummary
						expandIcon={
							<ExpandMore
								sx={{
									color:
										expanded == 'panel1' ? '#7E93AE' : 'var(--color-technical)',
								}}
								className='fmw-accrdion-svg'
							/>
						}
						aria-controls='panel1bh-content'
						id='panel1bh-header'
					>
						<Typography
							className='gap-5 accordion-title'
							sx={{
								width: '33%',
								flexShrink: 0,
								paddingBottom: '15px !important',
							}}
						>
							<DragIndicator className='fmw-accrdion-drag-svg' />
							Financial attachments
						</Typography>
					</AccordionSummary>
					<AccordionDetails sx={{ padding: '0px !important' }}>
						<Attachments />
					</AccordionDetails>
				</Accordion>
			) : (
				<Accordion
					expanded={expanded === 'panel1'}
					onChange={handleChange('panel1')}
					className='fmw-accrdion'
					elevation={0}
				>
					<AccordionSummary
						expandIcon={
							<ExpandMore
								sx={{
									color:
										expanded == 'panel1' ? '#7E93AE' : 'var(--color-technical)',
								}}
								className='fmw-accrdion-svg'
							/>
						}
						aria-controls='panel1bh-content'
						id='panel1bh-header'
					>
						<Typography
							className='gap-5 accordion-title'
							sx={{ width: '33%', flexShrink: 0 }}
						>
							<DragIndicator className='fmw-accrdion-drag-svg' />
							Financial attachments
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Box
							sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
						>
							{attachments?.map((row) => (
								<SingleAttachment
									row={row}
									userComment={userComment}
									setUserComment={setUserComment}
									setMatrixInfo={setMatrixInfo}
									matrixInfo={matrixInfo}
									name={row.variableName}
									stepName={stepName}
									id={singleProject?.projId}
									setOnblur={setOnBlur}
									onBlur={onBlur}
									maxScores={row.maxScores}
									comment={comment}
									setComment={setComment}
									setUserMatrixInfo={setUserMatrixInfo}
									userMatrixInfo={userMatrixInfo}
								/>
							))}
						</Box>
					</AccordionDetails>
				</Accordion>
			)}
			{/* 
			<Grid item xs={12}>
				<Grid item xs={12}>
					<TextareaComment comment={comment} />
				</Grid>
			</Grid> */}
		</Box>
	);
};

export default FinancialPosition;
