import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SingleAttachmentComplete from '../../components/SingleAttachmentComplete';
import { useSelector } from 'react-redux';



function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}


export default function ParticularsTable({ data }) {
  const { singleProject } = useSelector((state) => state.global);
  const rows = [
    createData('Individual active connections', singleProject?.projIndividualActiveConn),
    createData('Individual closed connections', singleProject?.projIndividualClosedConn),
    createData('Institution or commercial connections', singleProject?.projInstitutionCommercialConn),
    createData('Number of water kiosks', singleProject?.projNumberWaterKiosks),
    createData('Number of households served', singleProject?.projNumberHouseholdsServed),
    createData('Approximate population served', singleProject?.projAproxPopulationServed),
    createData('Approximate total population in service area', singleProject?.projTotalPopulation),
    createData(<>Service provision area (km<sup>2</sup>)</>, singleProject?.projArea),
    createData(
      "Grid reference of office / infrastructure",
      `Latitude: ${singleProject?.projLat ?? "N/A"}, longitude: ${singleProject?.projLong ?? "N/A"}`,
      "projLat"
    ),
    createData('PDF of map of service area(pdf))', singleProject?.projMapAreaFile),
    createData('PDF of sample kiosks contract(pdf)', singleProject?.projKioskConnFile),
    createData('PDF of sample Institution contract(pdf)', singleProject?.projInstConnFile),
    createData('PDF of list of members or accounts(pdf)', singleProject?.projIndActiveConnMbrFile),
    createData('PDF of sample customer contract(pdf)', singleProject?.projIndActiveConnCustFile),
  ];
  return (
    <TableContainer component={Paper} elevation={0} sx={{ background: 'inherit' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow className='table'>
            <TableCell>Item</TableCell>
            <TableCell align="left">Details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className='basic' >
          {rows.map((row) => (
            <TableRow
              key={row.name}
            >
              <TableCell component="th" scope="row" sx={{ borderLeft: 'none !important', width: '50%' }}>
                {row.name}
              </TableCell>
              {
                row?.name == 'PDF of map of service area(pdf)' || row?.name == "PDF of sample kiosks contract(pdf)" ||
                  row?.name == 'PDF of sample Institution contract(pdf)' || row?.name == 'PDF of list of members or accounts(pdf)' ||
                  row?.name == 'PDF of sample customer contract(pdf)'
                  ? (
                    <TableCell align="left">
                      <SingleAttachmentComplete
                        title={row?.calories}
                        tableFile="true"
                      />
                    </TableCell>
                  ) : (

                    <TableCell component="th" scope="row" sx={{ width: '50%' }}>
                      {row?.calories}
                    </TableCell>
                  )
              }

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}