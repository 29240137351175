import * as React from "react";
import {
  People,
  BarChart,
  Layers,
  Campaign,
  Home,
  Chat,
  Circle,
  ExpandLess,
  ExpandMore,
  Dashboard,
} from "@mui/icons-material";

import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  List,
  Collapse,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import ExtensionIcon from "@mui/icons-material/Extension";
import PersonIcon from "@mui/icons-material/Person";
import BalanceIcon from "@mui/icons-material/Balance";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import HourglassEmptyOutlinedIcon from "@mui/icons-material/HourglassEmptyOutlined";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import LibraryAddCheckOutlinedIcon from "@mui/icons-material/LibraryAddCheckOutlined";
import RuleOutlinedIcon from "@mui/icons-material/RuleOutlined";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import RedeemOutlinedIcon from "@mui/icons-material/RedeemOutlined";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const MainListItems = () => {
  const [nlOpen1, setnlOpen1] = useState(false);
  const toggleNestedList1 = () => {
    setnlOpen1(!nlOpen1);
  };

  const { user } = useSelector((state) => state.auth);

  const nlOpen1Urls = ["/offers", "/Letter of Intent"];

  const toggleList = () => {
    setnlOpen1(false);
  };

  useEffect(() => {
    if (nlOpen1Urls.includes(window.location.pathname)) {
      setnlOpen1(!nlOpen1);
    } else {
      setnlOpen1(false);
    }
  }, []);

  return (
    <React.Fragment>
      <NavLink
        to={`/impact-funder-account/${user?.usrId}/${user?.usrAccId}`}
        // className={({ isActive }) => (isActive ? 'lib-active' : '')}
      >
        <ListItemButton sx={{ mb: 1, pl: 5 }}>
          <ListItemIcon sx={{ minWidth: "36px" }}>
            <PersonIcon sx={{ fontSize: "26px", color: "#7E93AE" }} />
          </ListItemIcon>
          <ListItemText
            sx={{ color: "#7E93AE", fontSize: "16px", fontWeight: "400" }}
            primary='Home'
          />
          <Typography variant='span' className='indicator' />
        </ListItemButton>
      </NavLink>

      <NavLink
        to='/messages'
        style={{ background: "red" }}
        onClick={toggleList}
      >
        <ListItemButton sx={{ mb: 1, pl: 5 }}>
          <ListItemIcon sx={{ minWidth: "36px" }}>
            <AlternateEmailIcon sx={{ fontSize: "26px", color: "#7E93AE" }} />
          </ListItemIcon>
          <ListItemText
            sx={{ color: "#7E93AE", fontSize: "16px", fontWeight: "400" }}
            primary='Messages'
          />
          <Typography variant='span' className='indicator' />
        </ListItemButton>
      </NavLink>

      <NavLink
        to='#'
        className={({ isActive }) => (isActive ? "lib-active" : "")}
        onClick={toggleList}
      >
        <ListItemButton sx={{ mb: 2, pl: 5 }}>
          <ListItemIcon sx={{ minWidth: "36px" }}>
            <BarChart sx={{ fontSize: "26px", color: "#7E93AE" }} />
          </ListItemIcon>
          <ListItemText
            sx={{ color: "#7E93AE", fontSize: "16px", fontWeight: "400" }}
            primary='Reports'
          />
          <Typography variant='span' className='indicator' />
        </ListItemButton>
      </NavLink>

      <ListItemButton
        sx={{
          border: "1px solid #CFF0FC !important",
          pl: 4,
          mt: 2,
        }}
        className='is-expandable '
      >
        {/* <ListItemIcon sx={{ minWidth: '36px' }}>
					<Dashboard />
				</ListItemIcon> */}
        <ListItemText>
          <span
            style={{ color: "#0873B9", fontSize: "16px", fontWeight: "700" }}
          >
            Pipeline
          </span>
        </ListItemText>

        <Typography variant='span' className='indicator' />
      </ListItemButton>
      <Collapse
        in={true}
        timeout='auto'
        unmountOnExit
        className='collapsible-menu open'
      >
        <List component='div' disablePadding>
          <NavLink
            to='/new-project'
            // className={({ isActive }) => (isActive ? 'lib-active' : '')}
          >
            <ListItemButton sx={{ mb: 1, pl: 5 }}>
              <ListItemIcon sx={{ minWidth: "36px" }}>
                <FiberNewIcon sx={{ fontSize: "24px", color: "#7E93AE" }} />
              </ListItemIcon>
              <ListItemText
                sx={{ color: "#7E93AE", fontSize: "16px", fontWeight: "400" }}
                primary='New Projects'
              />
            </ListItemButton>
          </NavLink>
          <NavLink
            to='/deal-sourcing'
            // className={({ isActive }) => (isActive ? 'lib-active' : '')}
          >
            <ListItemButton sx={{ pl: 5 }}>
              <ListItemIcon sx={{ minWidth: "36px" }}>
                <HandshakeOutlinedIcon
                  sx={{ fontSize: "24px", color: "#7E93AE" }}
                />
              </ListItemIcon>
              <ListItemText
                sx={{ color: "#7E93AE", fontSize: "16px", fontWeight: "400" }}
                primary='Deal sourcing'
              />
            </ListItemButton>
          </NavLink>
          <NavLink
            to='/project-preparation/commited-projects'
            // className={({ isActive }) => (isActive ? 'lib-active' : '')}
          >
            <ListItemButton sx={{ mb: 1, pl: 5 }}>
              <ListItemIcon sx={{ minWidth: "36px" }}>
                <HourglassEmptyOutlinedIcon
                  sx={{ fontSize: "24px", color: "#7E93AE" }}
                />
              </ListItemIcon>
              <ListItemText
                sx={{ color: "#7E93AE", fontSize: "16px", fontWeight: "400" }}
                primary='Project preparation'
              />
            </ListItemButton>
          </NavLink>
        </List>
      </Collapse>

      <ListItemButton
        sx={{
          border: "1px solid #CFF0FC !important",
          pl: 4,
          mt: 2,
        }}
        className='is-expandable '
      >
        {/* <ListItemIcon sx={{ minWidth: '36px' }}>
					<Dashboard />
				</ListItemIcon> */}
        <ListItemText>
          <span
            style={{ color: "#0873B9", fontSize: "16px", fontWeight: "700" }}
          >
            Expression of demand
          </span>
        </ListItemText>
        <Typography variant='span' className='indicator' />
      </ListItemButton>
      <Collapse
        in={true}
        timeout='auto'
        unmountOnExit
        className='collapsible-menu open'
      >
        <List component='div' disablePadding>
          <NavLink
            to='/ssp-accounts'
            // className={({ isActive }) => (isActive ? 'lib-active' : '')}
          >
            <ListItemButton className='' sx={{ pl: 5 }}>
              <ListItemIcon sx={{ minWidth: "36px" }}>
                <GroupOutlinedIcon
                  sx={{ fontSize: "24px", color: "#7E93AE" }}
                />
              </ListItemIcon>
              <ListItemText
                sx={{ color: "#7E93AE", fontSize: "16px", fontWeight: "400" }}
                primary='WSP accounts'
              />
            </ListItemButton>
          </NavLink>
          <NavLink
            to='/complete'
            // className={({ isActive }) => (isActive ? 'lib-active' : '')}
          >
            <ListItemButton sx={{ mb: 1, pl: 5 }}>
              <ListItemIcon sx={{ minWidth: "36px" }}>
                <LibraryAddCheckOutlinedIcon
                  sx={{ fontSize: "24px", color: "#7E93AE" }}
                />
              </ListItemIcon>
              <ListItemText
                sx={{ color: "#7E93AE", fontSize: "16px", fontWeight: "400" }}
                primary='Complete projects'
              />
            </ListItemButton>
          </NavLink>

          <NavLink
            to='/incomplete'
            // className={({ isActive }) => (isActive ? 'lib-active' : '')}
          >
            <ListItemButton sx={{ mb: 1, pl: 5 }}>
              <ListItemIcon sx={{ minWidth: "36px" }}>
                <RuleOutlinedIcon sx={{ fontSize: "24px", color: "#7E93AE" }} />
              </ListItemIcon>
              <ListItemText
                sx={{ color: "#7E93AE", fontSize: "16px", fontWeight: "400" }}
                primary='Incomplete projects'
              />
            </ListItemButton>
          </NavLink>
        </List>
      </Collapse>

      <ListItemButton
        sx={{
          border: "1px solid #CFF0FC !important",
          pl: 4,
          mt: 2,
        }}
        className='is-expandable '
      >
        {/* <ListItemIcon sx={{ minWidth: '36px' }}>
					<Dashboard />
				</ListItemIcon> */}
        <ListItemText>
          <span
            style={{ color: "#0873B9", fontSize: "16px", fontWeight: "700" }}
          >
            Impact Dashboard
          </span>
        </ListItemText>

        <Typography variant='span' className='indicator' />
      </ListItemButton>
      <Collapse
        in={true}
        timeout='auto'
        unmountOnExit
        className='collapsible-menu open'
      >
        <List component='div' disablePadding>
          <NavLink
            to='/impact-dashboard'
            // className={({ isActive }) => (isActive ? 'lib-active' : '')}
          >
            <ListItemButton sx={{ mb: 1, pl: 5 }}>
              <ListItemIcon sx={{ minWidth: "36px" }}>
                <DataUsageIcon sx={{ fontSize: "24px", color: "#7E93AE" }} />
              </ListItemIcon>
              <ListItemText
                sx={{ color: "#7E93AE", fontSize: "16px", fontWeight: "400" }}
                primary='Dashboard'
              />
            </ListItemButton>
          </NavLink>
          <NavLink
            to='/water-sector'
            // className={({ isActive }) => (isActive ? 'lib-active' : '')}
          >
            <ListItemButton sx={{ pl: 5 }}>
              <ListItemIcon sx={{ minWidth: "36px" }}>
                <AutoGraphIcon sx={{ fontSize: "24px", color: "#7E93AE" }} />
              </ListItemIcon>
              <ListItemText
                sx={{ color: "#7E93AE", fontSize: "16px", fontWeight: "400" }}
                primary='Performance'
              />
            </ListItemButton>
          </NavLink>
          <NavLink
            to='/monitoring'
            // className={({ isActive }) => (isActive ? 'lib-active' : '')}
          >
            <ListItemButton sx={{ mb: 1, pl: 5 }}>
              <ListItemIcon sx={{ minWidth: "36px" }}>
                <DisplaySettingsIcon
                  sx={{ fontSize: "24px", color: "#7E93AE" }}
                />
              </ListItemIcon>
              <ListItemText
                sx={{ color: "#7E93AE", fontSize: "16px", fontWeight: "400" }}
                primary='Monitoring'
              />
            </ListItemButton>
          </NavLink>
        </List>
      </Collapse>

      <ListItemButton
        sx={{
          border: "1px solid #CFF0FC !important",
          pl: 4,
          mt: 2,
        }}
        className='is-expandable'
      >
        {/* <ListItemIcon sx={{ minWidth: '36px' }}>
					<Dashboard />
				</ListItemIcon> */}

        <ListItemText>
          <span
            style={{ color: "#0873B9", fontSize: "16px", fontWeight: "700" }}
          >
            Deal room
          </span>
        </ListItemText>
        <Typography variant='span' className='indicator' />
      </ListItemButton>
      <Collapse
        in={true}
        timeout='auto'
        unmountOnExit
        className='collapsible-menu open'
      >
        <List component='div' disablePadding>
          <NavLink
            to='/viable'
            className={({ isActive }) => (isActive ? "lib-active" : "")}
          >
            <ListItemButton className='' sx={{ pl: 5 }}>
              <ListItemIcon sx={{ minWidth: "36px" }}>
                <AddTaskOutlinedIcon
                  sx={{ fontSize: "24px", color: "#7E93AE" }}
                />
              </ListItemIcon>
              <ListItemText
                sx={{ color: "#7E93AE", fontSize: "16px", fontWeight: "400" }}
                primary='Viable'
              />
            </ListItemButton>
          </NavLink>
          <NavLink
            to='#'
            className={({ isActive }) => (isActive ? "lib-active" : "")}
          >
            <ListItemButton sx={{ mb: 1, pl: 5 }}>
              <ListItemIcon sx={{ minWidth: "36px" }}>
                <RedeemOutlinedIcon
                  sx={{ fontSize: "24px", color: "#7E93AE" }}
                />
              </ListItemIcon>
              <ListItemText
                sx={{ color: "#7E93AE", fontSize: "16px", fontWeight: "400" }}
                primary='Offers'
              />
            </ListItemButton>
            <ListItemButton
              sx={{
                border: "1px solid #CFF0FC !important",
                pl: 4,
                mt: 2,
              }}
              className='is-expandable '
            >
              {/* <ListItemIcon sx={{ minWidth: '36px' }}>
					<Dashboard />
				</ListItemIcon> */}
              <ListItemText>
                <span
                  style={{
                    color: "#0873B9",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  Wash Prospects
                </span>
              </ListItemText>

              <Typography variant='span' className='indicator' />
            </ListItemButton>
            <Collapse
              in={true}
              timeout='auto'
              unmountOnExit
              className='collapsible-menu open'
            >
              <List component='div' disablePadding>
                <NavLink to='/wash'>
                  <ListItemButton className='' sx={{ pl: 5 }}>
                    <ListItemIcon sx={{ minWidth: "36px" }}>
                      <BalanceIcon
                        sx={{ fontSize: "24px", color: "#7E93AE" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        color: "#7E93AE",
                        fontSize: "16px",
                        fontWeight: "400",
                      }}
                      primary='wash prospects'
                    />
                  </ListItemButton>
                </NavLink>
              </List>
            </Collapse>
          </NavLink>
          <NavLink
            to='#'
            className={({ isActive }) => (isActive ? "lib-active" : "")}
          >
            <ListItemButton
              sx={{
                border: "1px solid #CFF0FC !important",
                pl: 4,
                mt: 2,
              }}
              className='is-expandable '
            >
              {/* <ListItemIcon sx={{ minWidth: '36px' }}>
					<Dashboard />
				</ListItemIcon> */}
              <ListItemText>
                <span
                  style={{
                    color: "#0873B9",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  Account
                </span>
              </ListItemText>

              <Typography variant='span' className='indicator' />
            </ListItemButton>
            <Collapse
              in={true}
              timeout='auto'
              unmountOnExit
              className='collapsible-menu open'
            >
              <List component='div' disablePadding>
                <NavLink to='/dashboard'>
                  <ListItemButton className='' sx={{ pl: 5 }}>
                    <ListItemIcon sx={{ minWidth: "36px" }}>
                      <GroupOutlinedIcon
                        sx={{ fontSize: "24px", color: "#7E93AE" }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        color: "#7E93AE",
                        fontSize: "16px",
                        fontWeight: "400",
                      }}
                      primary='my account'
                    />
                  </ListItemButton>
                </NavLink>
              </List>
            </Collapse>
          </NavLink>
        </List>
      </Collapse>
    </React.Fragment>
  );
};

export default MainListItems;

// export const secondaryListItems = (
//   <React.Fragment>
//     <ListSubheader component="div" inset>
//     <Box
//           sx={{
//             mb: 1, mt: 1.5,
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'space-between',
//           }}
//         >
//           <Typography
//             className="nav-list-text"
//             textColor="neutral.500"
//             fontWeight={700}
//             sx={{
//               fontSize: '10px',
//               textTransform: 'uppercase',
//               letterSpacing: '.1rem',
//             }}
//           >
//             Browse
//           </Typography>
//           <IconButton
//             size="sm"
//             variant="plain"
//             color="primary"
//             sx={{ '--IconButton-size': '24px' }}
//           >
//             <KeyboardArrowDownRounded fontSize="small" color="primary" />
//           </IconButton>
//         </Box>
//     </ListSubheader>

//     <ListItemButton sx={{mb: 1}}>
//       <ListItemIcon sx={{ minWidth: '36px'}}>
//         <Assignment />
//       </ListItemIcon>
//       <ListItemText sx={{ color: '#7E93AE', fontSize: '16px', fontWeight: '400' }}primary="Current month" />
//     </ListItemButton>
//     <ListItemButton sx={{mb: 1}}>
//       <ListItemIcon sx={{ minWidth: '36px'}}>
//         <Assignment />
//       </ListItemIcon>
//       <ListItemText sx={{ color: '#7E93AE', fontSize: '16px', fontWeight: '400' }}primary="Last quarter" />
//     </ListItemButton>
//     <ListItemButton sx={{mb: 1}}>
//       <ListItemIcon sx={{ minWidth: '36px'}}>
//         <Assignment />
//       </ListItemIcon>
//       <ListItemText sx={{ color: '#7E93AE', fontSize: '16px', fontWeight: '400' }}primary="Year-end sale" />
//     </ListItemButton>
//   </React.Fragment>
// );
