import axios from "axios"

const API_URL  = `${process.env.REACT_APP_API_BASE_URL}`;
 

const login =async(userData)=>{
    const response=await axios.post(`${API_URL}/usr/post_authenticate_user.action`, userData, {
        headers: {
            'Accept': 'application/json',
        }
    })

    if (!response.data.success) {
        return Promise.reject(response.data.messages.message) 
    }
    
    return response.data.jsonData;
}

const loginDetails =async(id)=>{
    const response=await axios.get(`${API_URL}/usr/fetchUser.action?usrId=${id}`, {
        headers: {
            'Accept': 'application/json',
        }
    })

    if (!response.data.success) {
        return Promise.reject(response.data.messages.message) 
    }
    
    return response.data.jsonData;
}

const sendLoginOTP =async(formData)=>{
    const response = await axios.post(`${API_URL}/usr/verify_User.action`, formData)

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }

    return response.data.jsonData;
}

const resendLoginOTP =async({usrId})=>{
    const response = axios.post(`${API_URL}/usr/resend_otp_sign_in.action?usrId=${usrId}`, {
        headers: {
            'Accept': 'application/json',
        }
    })
    
    if (!response.data.success) {
        return Promise.reject(response.data.messages.message)
    }

    return response.data.jsonData;
}

const addUser =async(newUser)=>{
    const response=await axios.post(`${API_URL}/usr/saveUser.action`, newUser)
    if (!response.data.success) {
        return Promise.reject(response.data.message) 
    }
    
    return response.data.jsonData;
}
 
const fetchNewUser =async({accId,usrId})=>{
    const response = await axios.get(`${API_URL}/usr/fetchUsers.action?accId=${accId}&usrId=${usrId}`)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.data.result;
}
 
const deleteNewUser =async({usrId})=>{
    const response = await axios.get(`${API_URL}/usr/deleteUser.action?usrId=${usrId}`)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.success;
}

 
const searchPhoneNumber =async({userPhone})=>{
    const response = await axios.get(`${API_URL}/usr/searchPhoneNumber.action?userPhone=${userPhone}`)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.messages.message;
}

const fetchWsp =async()=>{
    const response = await axios.get(`${API_URL}/usr/fetchWsp.action`)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.data.result;
}

const fetchCurrentUser =async({accId,usrId})=>{
    const response = await axios.get(`${API_URL}/usr/fetchUsers.action?accId=${accId}&usrId=${usrId}`)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.data.result;
}

const authService={
    login,
    sendLoginOTP,
    resendLoginOTP,
    addUser,
    fetchNewUser,
    loginDetails,
    deleteNewUser,
    searchPhoneNumber,
    fetchWsp,
    fetchCurrentUser
}
export default authService
