import React from "react";
import {
  AppBar,
  Box,
  Container,
  Toolbar,
  Typography,
  Button,
} from "@mui/material";
import { IconButton, Menu, MenuItem } from "@mui/joy";
// import Button from "@mui/joy/Button";
import AdbIcon from "@mui/icons-material/Adb";
import MenuIcon from "@mui/icons-material/Menu";
// import Logo from '../../assets/img/WhatsApp Image 2023-04-25 at 09.31.12.jpeg';
import Logo from "../../assets/img/WhatsApp Image 2023-04-25 at 09.31.12 (1).jpeg";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Signin from "../../pages/signin/Signin";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import storage from "redux-persist/lib/storage";
import { cleanupFormWithPartner } from "../../features/wsp/wspSlice";
import { cleanupForm } from "../../features/onboard_partner/onboardPartnerSlice";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { cleanupFormCommitted } from "../../features/committed_project/committedSlice";

const pages = [
  {
    name: "Home",
    url: "/",
    active: true,
  },
  // {
  //     name: 'Financing',
  //     url: '/',
  //     active: false
  // },
  // {
  //   name: "News/Events",
  //   url: "/news-events",
  //   active: false,
  // },
  // {
  //     name: 'Publications',
  //     url: '/publications',
  //     active: false
  // },
  {
    name: "Finance my Project",
    url: "/signup-wsp",
    active: false,
  },
  {
    name: "Become a Partner",
    url: "/signup-partner",
    active: false,
  },
  // 'Home',
  // 'Financing',
  //  'News/Events',
  //  'Publications',
  //  'Finance my Project',
  //  'Become a Partner'
];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

const StyledButton = styled(Button)`
  color: #484848;
  &:hover {
    color: #00baba;
    text-shadow: #e6fafd;
  }
  &:focus {
    background-color: #61dafb;
    box-shadow: #e6fafd;
  }
`;

const LinkButton = styled(Button)`
  background-color: #00baba;
  &:hover {
    background-color: #00baba;
    //color: #00BABA;
    text-shadow: #e6fafd;
  }
  //&:focus {
  //  background-color: #61dafb;
  //  box-shadow: #e6fafd;
  //}
`;

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width:480px)" });
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [openSignin, setOpenSignin] = React.useState(false);
  const handleClickOpenSignin = () => {
    storage.removeItem("persist:root");
    dispatch(cleanupForm());
    dispatch(cleanupFormWithPartner());
    dispatch(cleanupFormCommitted());
    setOpenSignin(true);
  };
  const handleCloseSignin = () => {
    setOpenSignin(false);
  };

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: "#ffffff" }}>
        <Container className="head" maxWidth="xl">
          <Toolbar
            disableGutters
            sx={{
              backgroundColor: "#ffffff",
              display: "flex",
            }}
          >
            <img
              src={Logo}
              alt="logo"
              // width="17%"
              // height= "17%"
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: 40,
                height: "17%",
                width: isMobile ? "60%" : "17%",
              }}
            />

            <Box
              sx={{
                display: { xs: "flex", md: "none", flexDirection: "column" },
                justifyItems: "space-between",
                ml: "auto",
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon sx={{ color: "#25252D" }} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: {
                    xs: "block",
                    md: "none",
                  },
                  color: "#25252D",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.url} onClick={handleCloseNavMenu}>
                    <Button
                      onClick={() => navigate(page.url)}
                      style={{
                        textDecoration: "none !important",
                        textTransform: "capitalize",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography sx={{ color: "#25252D" }}>
                        {page.name}
                      </Typography>
                    </Button>
                  </MenuItem>
                ))}
                <MenuItem onClick={handleCloseNavMenu}>
                  <Button
                    onClick={handleClickOpenSignin}
                    sx={{ color: "#25252D", justifyContent: "flex-start" }}
                  >
                    <Typography textAlign="center" textTransform="capitalize">
                      Log In
                    </Typography>
                  </Button>
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Button
                    onClick={() => navigate("/signup-wsp")}
                    sx={{ color: "#25252D", justifyContent: "flex-start" }}
                  >
                    <Typography textAlign="center" textTransform="capitalize">
                      FundMyWater
                    </Typography>
                  </Button>
                </MenuItem>
              </Menu>
            </Box>

            <Box
              sx={{
                flexGrow: 3,
                display: { xs: "none", md: "inline flex" },
                flexDirection: "row",
                alignItems: "center",
                backgroundColor: "#e6fafd",
                width: "650px",
                height: "76px",
                // height: '70px',
                pl: 2,
              }}
            >
              {pages.map((page) => (
                <Link
                  key={page.url}
                  to={page.url}
                  style={{ textDecoration: "none" }}
                >
                  <StyledButton onClick={handleCloseNavMenu}>
                    <Typography
                      sx={{
                        fontSize: "15px",
                        textTransform: "capitalize",
                        fontFamily: "Roboto, sans-serif",
                      }}
                    >
                      {page.name}
                    </Typography>
                  </StyledButton>
                </Link>
              ))}
            </Box>

            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                gap: 2,
                ml: 4,
              }}
            >
              <Button
                variant="contained"
                sx={{
                  borderRadius: "1px",
                  backgroundColor: "#0069DF %0 %0",
                  fontFamily: "Helvetica Neue, sans-serif",
                  textTransform: "capitalize",
                  fontSize: "12px",
                }}
                onClick={handleClickOpenSignin}
              >
                Log in
              </Button>
              <LinkButton
                variant="contained"
                sx={{
                  borderRadius: "2px",
                  textTransform: "capitalize",
                  fontFamily: "Helvetica Neue, sans-serif",
                  fontSize: "12px",
                }}
                onClick={() => navigate("/signup-wsp")}
              >
                FundMyWater
              </LinkButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Signin
        open={openSignin}
        handleClickOpen={handleClickOpenSignin}
        handleClose={handleCloseSignin}
      />
    </>
  );
};

export default Header;
