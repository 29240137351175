import { Box, Typography } from '@mui/material';
import Reac from 'react';
import MatrixInput from './MatrixInput';
import UserMatrix from './UserMatrix';

const SingleItem = ({
	name,
	stepName,
	id,
	userComment,
	setMatrixInfo,
	matrixInfo,
	row,
	userMatrixInfo,
	setUserMatrixInfo,
}) => {
	return (
		<>
			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				<Typography
					sx={{
						fontWeight: 700,
						fontSize: '16px',
						my: 2,
						display: 'flex',
						alignItems: 'center',
					}}
				>
					{row?.name} | {row?.description}
				</Typography>

				<>
					{userComment[name] ? (
						<>
							<Typography>{userComment[name]}</Typography>
						</>
					) : (
						<Typography>No Comment</Typography>
					)}

					<Box
						sx={{
							display: 'flex',
							mt: 2,
							mb: 2,
							gap: 6,
							alignItems: 'center',
						}}
					>
						<Typography
							className='gap-5'
							sx={{
								color: 'var(--color-blue-grey)',
								fontWeight: 500,
								fontSize: '14px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							System score:
							<MatrixInput
								setMatrixInfo={setMatrixInfo}
								matrixInfo={matrixInfo}
								name={row?.variableName}
								stepName={stepName}
								id={id}
								maxScores={row?.maxScores}
							/>
						</Typography>
						<Box
							sx={{
								width: '300px',
								display: 'flex',
								alignItems: 'center',
								bgColor: 'red',
								gap: 2,
							}}
						>
							<Typography
								sx={{
									display: 'flex',
									alignItems: 'center',
									gap: 1,
									color: '#0873B9',
								}}
							>
								Technical advisor score:
								<UserMatrix
									setMatrixInfo={setUserMatrixInfo}
									matrixInfo={userMatrixInfo}
									name={row?.variableName}
									stepName={stepName}
									id={id}
									maxScores={row?.maxScores}
								/>
							</Typography>
						</Box>
					</Box>
				</>
			</Box>
		</>
	);
};

export default SingleItem;
