import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { Visibility } from "@mui/icons-material";
import { previewPaper } from "../../../utils";


function FileButton({ title, titlePreview, viable }) {
  console.log(title, "****************************************");
  return (
    <>
      {title !== null ? (
        <Box
          sx={{ cursor: 'pointer' }}
          variant='plain'
          style={{ textTransform: "capitalize", padding: viable ? "3px 0px" : "3px 0px" }}
          onClick={() => previewPaper(titlePreview)}
        >
          <Typography sx={{ display: "flex", gap: 1, justifyContent: 'start' }}>
            <
              Visibility
              sx={{ color: 'var(--color-technical)', fontSize: '23px', }}
            />
            <Typography sx={{ color: 'var(--color-technical)', textDecoration: "underline" }}>
              View File
            </Typography>
          </Typography>
        </Box>
      ) : (
        <Typography sx={{}}>No File</Typography>
      )}
    </>
  );
}

export default FileButton;
