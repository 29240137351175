import * as React from 'react';
import Avatar from '@mui/joy/Avatar';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListDivider from '@mui/joy/ListDivider';
import Select, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { Flag, KeyboardArrowDown } from '@mui/icons-material';
import { useState } from 'react';
import { useEffect } from 'react';
import { resetLoader, saveProjectPriority } from '../../features/expression_demand/expressionDemandSlice';
import { useDispatch, useSelector } from 'react-redux';

const options = [
    { value: 'High', label: 'High' },
    { value: 'Medium', label: 'Medium' },
    { value: 'Low', label: 'Low' },
];

function renderValue(option) {
    if (!option) {
        return null;
    }

    let flagColor = 'var(--color-technical)';
    if (option.label === 'High') {
        flagColor = '#C04544';
    } else if (option.label === 'Medium') {
        flagColor = '#FF921C';
    }
    else if (option.label === 'Low') {
        flagColor = '#FFD347';
    }


    return (
        <React.Fragment>
            <ListItemDecorator>
                <Flag
                    alt={option.label}
                    src={options.find((o) => o.value === option.value)?.src}
                    style={{ color: flagColor, marginRight: '3px' }}
                />
            </ListItemDecorator>
            {option.label}
        </React.Fragment>
    );
}

export default function PrioritySelector({priorityProjId}) {
 
    const dispatch=useDispatch()
    const { isSuccessPriority } = useSelector((state) => state.expressionDemandData)
    const [isUpdating, setIsUpdating] = useState(false)
    const [formData, setFormData] = useState({
        projAdequacy: '',
    })
    const { projAdequacy } = formData
    const handleChangeSelect = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
        setIsUpdating(true);
    };
    const getSelectBorder = () => {
        let borderColor = '';

        if (projAdequacy === 'High') {
            borderColor = '1px solid #C04544 !important';
        } else if (projAdequacy === 'Medium') {
            borderColor = '1px solid #FF921C !important';
        } else if (projAdequacy === 'Low') {
            borderColor = '1px solid #FFD347 !important';
        } else if (projAdequacy === '') {
            borderColor = '1px dashed var(--color-technical) !important';
        }
        return `${borderColor}`;
    };

    useEffect(() => {
        if (!isUpdating) {
        } else {
            dispatch(
                saveProjectPriority({
                    priProjId: priorityProjId,
                    prDesc: projAdequacy,
                }),
            );
            setIsUpdating(false)
            dispatch(resetLoader())
        }
    }, [dispatch, projAdequacy, isUpdating])


  


    return (
        <>
            <Select
                disabled
                onChange={(e, newval) => {
                    handleChangeSelect({
                        target: {
                            value: newval,
                            name: 'projAdequacy',
                        },
                    });
                }}
                name='projAdequacy'
                placeholder="Priority"
                startDecorator={!projAdequacy ? <Flag sx={{color:'var(--color-technical)'}}/> : ''}
                slotProps={{
                    listbox: {
                        sx: {
                            '--ListItemDecorator-size': '44px',
                        },
                    },
                }}
                sx={{
                    '--ListItemDecorator-size': '44px',
                    minWidth: 24,
                    width: 120,
                    borderRadius: '25px',
                    border: getSelectBorder(),
                    [`& .${selectClasses.indicator}`]: {
                        transition: '0.2s',
                        [`&.${selectClasses.expanded}`]: {
                            transform: 'rotate(-180deg)',
                        },
                    },
                }}
                indicator={<KeyboardArrowDown />}
                renderValue={renderValue}
            >
                {options.map((option, index) => (
                    <React.Fragment key={option.value}>
                        <Option value={option.value} label={option.label}>
                            <ListItemDecorator>
                                <Flag size="sm" src={option.src}
                                    style={{
                                        color:
                                            option.label == 'High' ? '#C04544' :
                                                option.label == 'Medium' ? '#FF921C' :
                                                    '#FFD347'
                                    }}
                                />
                            </ListItemDecorator>
                            {option.label}
                        </Option>
                    </React.Fragment>
                ))}
            </Select>
        </>
    );
}