import { Box, Typography } from '@mui/material'
import React from 'react'

const PersonalDetails = ({ data }) => {
    const { usrFirstName, usrFullNames, usrLastName, usrEmail, usrAltFirstName, usrAltLastName, usrMobileNumber, usrAltPhoneNo, usrNationalId, accCounty, accSubCounty, accLocation,
    } = data;


    console.log("*********AccOUNT*********************", usrFullNames)

    return (
        <Box
            sx={{
                backgroundColor: "#FEFEFF",
                display: "flex",
                flexDirection: "column",
                p: 4,
                borderRadius: 4,
                mt: 5
            }}
        >
            <Typography sx={{ fontWeight: "bold", mb: 1 }}>
                Basic Details
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 5
                }}
            >
                <Box
                    sx={{
                        backgroundColor: "#",
                        display: "flex",
                        gap: 1,
                        flexDirection: "column",
                    }}
                >
                    <Typography sx={{ fontWeight: "450" }}>
                        Contact person name
                    </Typography>
                    <Typography sx={{}}>ID/ Passport No.</Typography>
                    <Typography sx={{}}>Email</Typography>
                    <Typography sx={{}}>Phone number</Typography>
                    <Typography sx={{}}>
                        Name of alternate contact person
                    </Typography>
                    <Typography sx={{}}>
                        Phone no. of Alternate Contact Person
                    </Typography>
                </Box>

                <Box
                    sx={{
                        backgroundColor: "#",
                        display: "flex",
                        gap: 1,
                        flexDirection: "column",
                    }}
                >
                    <Typography sx={{ color: "#667085" }}>{usrFullNames ?? 'N/A'}</Typography>
                    <Typography sx={{ color: "#667085" }}>{usrNationalId ?? 'N/A'}</Typography>
                    <Typography sx={{ color: "#667085" }}>{usrEmail ?? 'N/A'}</Typography>
                    <Typography sx={{ color: "#667085" }}>{usrMobileNumber ?? 'N/A'}</Typography>
                    <Typography sx={{ color: "#667085" }}>{usrAltFirstName} {usrAltLastName}</Typography>
                    <Typography sx={{ color: "#667085" }}>{usrAltPhoneNo ?? 'N/A'}</Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default PersonalDetails