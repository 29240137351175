import * as React from 'react';
import Avatar from '@mui/joy/Avatar';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListDivider from '@mui/joy/ListDivider';
import Select, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { Flag, KeyboardArrowDown, PersonAdd } from '@mui/icons-material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { resetLoader, saveProjectPriority } from '../../features/expression_demand/expressionDemandSlice';
import { useEffect } from 'react';
import { fetchNewUser } from '../../features/auth/authSlice';

const options = [
    { value: 'James Mwangi', label: 'James Mwangi' },
    { value: 'John Kamau', label: 'John Kamau' },
    { value: 'Mercy Njeri', label: 'Mercy Njeri' },
];


export default function AssigneeSelector({ assigned, setAssigned, assignedProjId }) {
    const dispatch = useDispatch()


    const { isSuccessPriority } = useSelector((state) => state.expressionDemandData)
    const { user, newUsers } = useSelector((state) => state.auth)


    const [formData, setFormData] = useState({
        projAdequacy: '',
    })

    const { projAdequacy } = formData

    const [isUpdating, setIsUpdating] = useState(false)
    const handleChangeSelect = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
        setAssigned(true);
        setIsUpdating(true)
        // handleSaveAssignee();
    };

    const getSelectBorder = () => {
        let borderColor = '1px dashed var(--color-technical) !important';
        if (projAdequacy !== '') {
            borderColor = '1px solid var(--color-technical) !important';
        }
        return `${borderColor}`;
    };

    const getSelectBackground = () => {
        let backgroundColor = '1px dashed var(--color-technical) !important';
        if (projAdequacy !== '') {
            backgroundColor = '#E8FAFF !important';
        }
        return `${backgroundColor}`;
    };

    useEffect(() => {
        if (!isUpdating) {
        } else {
            dispatch(
                saveProjectPriority({
                    priProjId: assignedProjId,
                    priAssignee: projAdequacy,
                }),
            );
            setIsUpdating(false)
            dispatch(resetLoader())
        }
    }, [dispatch, projAdequacy, isUpdating])


    useEffect(() => {
        if (isSuccessPriority) {
            setFormData({
                projAdequacy: '',
            })
        }
        dispatch(resetLoader())
    }, [dispatch, isSuccessPriority])

    useEffect(() => {
        dispatch(
            fetchNewUser({
                accId: user.usrAccId,
                usrId: user.usrId,
            })
        );
    }, [dispatch])


    return (
        <>
            <Select
                disabled
                onChange={(e, newval) => {
                    handleChangeSelect({
                        target: {
                            value: newval,
                            name: 'projAdequacy',
                        },
                    });

                }}

                name='projAdequacy'
                placeholder="Assign"
                startDecorator={!projAdequacy ? <PersonAdd sx={{ color: 'var(--color-technical)' }} /> : ''}
                slotProps={{
                    listbox: {
                        sx: {
                            '--ListItemDecorator-size': '44px',
                        },
                    },
                }}
                sx={{
                    '--ListItemDecorator-size': '44px',
                    // minWidth: 24,
                    width: 150,
                    borderRadius: '25px',
                    border: getSelectBorder(),
                    backgroundColor: getSelectBackground(),
                    [`& .${selectClasses.indicator}`]: {
                        transition: '0.2s',
                        [`&.${selectClasses.expanded}`]: {
                            transform: 'rotate(-180deg)',
                        },
                    },
                }}
                indicator={<KeyboardArrowDown />}
            // renderValue={renderValue}
            >
                {newUsers?.map((option, index) => (
                    <React.Fragment key={option.usrId}>
                        <Option value={option.usrId} label={option.usrFirstName}>
                            {option.usrFirstName}  {option.usrLastName}
                        </Option>
                    </React.Fragment>
                ))}
            </Select>
        </>
    );
}