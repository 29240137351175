import axios from "axios"

const API_URL  = `${process.env.REACT_APP_API_BASE_URL}`;

const fetchAccountInfo = async (accId) => {
    const response = await axios.get(`${API_URL}/usr/fetchUser.action?usrId=${accId}`);
 
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }

    return response.data.jsonData;
};

const updateProfileImage = async (formData) => {
    const response = await axios.post(`${API_URL}/usr/updateAccount.action`, formData)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }

    console.log("Here is data from Update method For Image---->>>---",response.data.jsonData)
    return response.data.jsonData;

};

const editProfileImage = async (formData) => {
    const response = await axios.post(`${API_URL}/usr/postImages.action`, formData)

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }

    return response.data.jsonData;

}
 
const registerTech = async(formData)=>{
    const response = await axios.post(`${API_URL}/usr/create_account.action`, formData)

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }

    return response.data.jsonData;
}

const registerTechDetails =async(formData)=>{
    const response = await axios.post(`${API_URL}/usr/save_account.action`, formData)

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }

    return response.data.jsonData;
}

const techFiles =async(formData)=>{
    const response=await axios.post(`${API_URL}/usr/postImages.action`, formData)

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.jsonData
}



const techAdvService={
    editProfileImage,
    fetchAccountInfo,
    updateProfileImage,
    registerTech,
    registerTechDetails,
    techFiles
}
export default techAdvService

 
 