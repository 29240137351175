import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useLocation } from 'react-router-dom';
import { cashConverter } from '../../../../utils';
import SingleAttachmentComplete from '../../components/SingleAttachmentComplete';
import { useSelector } from 'react-redux';



function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}


export default function FinanceTable() {
  const { singleProject } = useSelector((state) => state.global);
  const rows = [
    createData('Description of sub-project for which the loan is applied', singleProject?.projDescSubProj),
    createData('Problem being solved', singleProject?.projProbSolved),
    createData('Beneficiaries', singleProject?.projWhoBenefit),
    createData('Total estimated project cost', singleProject?.projEstCost ? cashConverter(singleProject?.projEstCost) : "N/A"),
    createData('Amount of loan required', singleProject?.projLoanAmount ? cashConverter(singleProject?.projLoanAmount) : "N/A"),
    createData('Purpose of the Project', singleProject?.projPurpose),
    createData('How funds will be generated to repay the loan', singleProject?.projMoneySource),
    createData('Source of 3rd party financing', singleProject?.projOtherPartyFinance),
    createData('Has county government been informed', singleProject?.projInformGov),

  ];
  const rowsAtt = [
    createData('Description of sub-project for which the loan is applied', singleProject?.projDescSubProj),
    createData('Problem being solved', singleProject?.projProbSolved),
    createData('Beneficiaries', singleProject?.projWhoBenefit),
    createData('Total estimated project cost', singleProject?.projEstCost ? cashConverter(singleProject?.projEstCost) : "N/A"),
    createData('Amount of loan required', singleProject?.projLoanAmount ? cashConverter(singleProject?.projLoanAmount) : "N/A"),
    createData('Purpose of the Project', singleProject?.projPurpose),
    createData('How funds will be generated to repay the loan', singleProject?.projMoneySource),
    createData('Source of 3rd party financing', singleProject?.projOtherPartyFinance),
    createData('Has county government been informed', singleProject?.projInformGov),
    createData('County government Information File', singleProject?.projInformGovYesFile),
  ];

  return (
    <TableContainer component={Paper} elevation={0} sx={{ background: 'inherit' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow className='table'>
            <TableCell>Item</TableCell>
            <TableCell align="left">Details</TableCell>
          </TableRow>
        </TableHead>
        {
          singleProject?.projInformGov !== "Yes" ? (

            <TableBody className='basic'>
              {rows?.map((row) => (
                <TableRow
                  key={row.name}
                >
                  <TableCell component="th" scope="row" sx={{ borderLeft: 'none !important' }}>
                    {row.name}
                  </TableCell>
                  <TableCell align="left">{row.calories}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) :
            (
              <TableBody className='basic'>
                {rowsAtt?.map((row) => (
                  <TableRow
                    key={row.name}
                  >
                    <TableCell component="th" scope="row" sx={{ borderLeft: 'none !important' }}>
                      {row.name}
                    </TableCell>
                    {
                      row.name == 'County government Information File' ? (
                        <TableCell align="left">
                          <SingleAttachmentComplete
                            title={row?.calories}
                            tableFile="true"
                          />
                        </TableCell>
                      ) :
                        (
                          <TableCell align="left">{row.calories}</TableCell>
                        )
                    }


                  </TableRow>
                ))}
              </TableBody>
            )

        }
      </Table>

    </TableContainer>
  );
}