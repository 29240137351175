import React, { useEffect } from 'react';

const RedirectToHTTPS = () => {
  useEffect(() => {
    if (window.location.protocol !== 'https:' && window.location.hostname !== 'localhost') {
      window.location.replace(`https://${window.location.hostname}${window.location.pathname}`);
    }
  }, []);

  return null;
};

export default RedirectToHTTPS;
