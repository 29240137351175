import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, {
	stepConnectorClasses,
} from '@mui/material/StepConnector';
import React, { useEffect, useRef, useState } from 'react';
import { Box, StepButton } from '@mui/material';
import { Add } from '@mui/icons-material';
import backArrow from '../../assets/backArrow.svg';
import forwardArrow from '../../assets/forwardArrow.svg';
import Step1CommittedProjects from '../components/step1/Step1CommittedProjects';
import Step2 from '../components/step2/Step2';
import Step3 from '../components/step3/Step3';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
	fetchCommittedDetails,
	saveCommittedDetails,
} from '../../features/committed_project/committedSlice';
import { getExpressionDemandData } from '../../features/expression_demand/expressionDemandSlice';
import ViableStep1 from '../components/step1/viable_step1';
import ViableStep2 from '../components/step2/viable_step2';
import ViableStep3 from '../components/step3/viable_step3';

const ColorlibConnector = styled(StepConnector)(() => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 22,
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor: 'var(--color-technical)',
			alignItems: 'center',
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor: 'var(--color-technical)',
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		height: 3,
		border: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.12)',

		borderRadius: 1,
	},
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
	backgroundColor:
		theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#fff',
	zIndex: 1,
	color: 'var(--color-technical)',
	fontSize: '17px',
	fontWeight: 600,
	width: 50,
	height: 50,
	display: 'flex',
	borderRadius: '50%',
	border: '1px solid var(--color-technical)',
	justifyContent: 'center',
	alignItems: 'center',
	cursor: 'pointer',
	...(ownerState.active && {
		backgroundColor: 'var(--color-active-stepper)',
		color: 'var(--color-technical)',
		fontWeight: 700,
		fontSize: '17px',
	}),
	...(ownerState.completed && {
		backgroundColor: 'var(--color-technical)',
		color: '#fff',
		fontWeight: 700,
		fontSize: '17px',
	}),
}));

const steps = [
	'Governance',
	'Water source & infrastructure',
	'Applicant particulars',
];

const ActiveStepContent = ({ step }) => {
	const { status } = useParams();
	switch (step) {
		case 0:
			return status === 'committed' ? (
				<Step1CommittedProjects />
			) : (
				<ViableStep1 steps={step} />
			);
		case 1:
			return status === 'committed' ? <Step2 /> : <ViableStep2 steps={step} />;
		case 2:
			return status === 'committed' ? <Step3 /> : <ViableStep3 steps={step} />;
		// case 3:
		//     return <FinancialPosition steps={step} />;

		default:
			throw new Error('Unknown step');
	}
};

export default function CommittedProjects() {
	const [activeStep, setActiveStep] = useState(0);
	const [initialStep, setInitialStep] = useState(true);
	const previousStep = useRef(0);
	const { id } = useParams();
	const dispatch = useDispatch();

	const {
		committedd,
		specialMinute,
		waterDemandAssesment,
		wrSelectors,
		waterQuality,
		waterCompliance,
		nemaCompliance,
		waterRisks,
		hydrologicalReport,
		waterAnalysis,
	} = useSelector((state) => state.committedProj);

	function ColorlibStepIcon(props) {
		const { active, completed } = props;

		const icons = {
			1: 1,
			2: 2,
			3: 3,
		};
		return (
			<ColorlibStepIconRoot
				ownerState={{ completed, active }}
				className={`${active ? '#fff' : 'green'}`}
			>
				{icons[String(props.icon)]}
			</ColorlibStepIconRoot>
		);
	}

	const handleNext = () => {
		setActiveStep(activeStep + 1);
	};

	const handleBack = () => {
		setActiveStep(activeStep - 1);
	};

	const handleSpecialMinutes = () => {
		dispatch(
			saveCommittedDetails({
				wrProjId: id,
				wrSpecialMin: specialMinute,
			}),
		);
	};

	const submitStep1 = () => {
		handleSpecialMinutes();
		handleNext();
	};

	const submitTestDetails = () => {
		handleNext();
	};
	const submitClinicalNotes = () => {
		handleNext();
	};

	const submitReview = () => {
		handleNext();
	};

	const handleStep = (step) => () => {
		setActiveStep(step);
	};

	const fetchCommittedData = async () => {
		const res = await dispatch(fetchCommittedDetails({ wrProjId: id }));
	};

	const fetchExpressData = async () => {
		// setExpressLoading(true);
		const res = await dispatch(getExpressionDemandData({ projId: id }));
		// setExpressLoading(false);
	};
	useEffect(() => {
		fetchExpressData();
		fetchCommittedData();
	}, [dispatch, id]);

	useEffect(() => {
		previousStep.current = activeStep;
	}, [activeStep]);

	return (
		<Stack spacing={8}>
			<div>
				<React.Fragment>
					<Box className='preq-steps'>
						<ActiveStepContent step={activeStep} />
					</Box>

					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							bgcolor: 'inherit',
						}}
					>
						{activeStep === 0 ? (
							<div
								className='flex items-center gap-20'
								style={{
									color: 'var(--color-technical)',
									fontSize: '18px',
									fontWeight: 500,
								}}
							>
								{activeStep + 1}/{steps.length}
								<img
									onClick={submitStep1}
									src={forwardArrow}
									alt='Forward'
									style={{ height: '48px', width: '48px', cursor: 'pointer' }}
								/>
							</div>
						) : null}

						{activeStep === 1 && (
							<div
								className='flex items-center gap-20'
								style={{
									color: 'var(--color-technical)',
									fontSize: '18px',
									fontWeight: 500,
								}}
							>
								<img
									onClick={handleBack}
									src={backArrow}
									alt='Backward'
									style={{ height: '48px', width: '48px', cursor: 'pointer' }}
								/>
								{activeStep + 1}/{steps.length}
								<img
									onClick={() => submitTestDetails()}
									src={forwardArrow}
									alt='Forward'
									style={{ height: '48px', width: '48px', cursor: 'pointer' }}
								/>
							</div>
						)}
						{activeStep === 2 && (
							<div
								className='flex items-center gap-20'
								style={{
									color: 'var(--color-technical)',
									fontSize: '18px',
									fontWeight: 500,
								}}
							>
								<img
									onClick={handleBack}
									src={backArrow}
									alt='Backward'
									style={{
										height: '48px',
										width: '48px',
										cursor: 'pointer',
										color: 'red',
									}}
								/>
								{activeStep + 1}/{steps.length}
							</div>
						)}
					</Box>
				</React.Fragment>
			</div>
		</Stack>
	);
}
