import axios from "axios"

const API_URL  = `${process.env.REACT_APP_API_BASE_URL}`;
 

const onboardPartner =async(formData)=>{
    const response=await axios.post(`${API_URL}/usr/save_project.action`, formData)

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    
    return response.data.jsonData
}


const onboardFiles =async(formData)=>{
    const response=await axios.post(`${API_URL}/usr/postImages.action`, formData)

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.jsonData
}


const fetchPartnerApplications =async(accId)=>{
    const response = await axios.get(`${API_URL}/usr/fetch_projects.action?accId=${accId}`)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }

    return response.data.data.result;
}



const saveDirector =async(formData)=>{
    const response=await axios.post(`${API_URL}/usr/saveDirector.action`, formData)

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.jsonData
}
const fetchDirectors =async({projId})=>{
    const response = await axios.get(`${API_URL}/usr/fetchDirectors.action?projId=${projId}`)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.data.result;
}
// fetchDirectorsById
const fetchDirectorsById =async({dirId})=>{
    const response = await axios.get(`${API_URL}/usr/fetchDirectors.action?dirId=${dirId}`)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.data.result;
}
// updateDirector
const updateDirector =async(formData)=>{
    const response=await axios.post(`${API_URL}/usr/saveDirector.action`, formData)

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.jsonData
}

const deleteDirector =async({dirId})=>{
    const response = await axios.get(`${API_URL}/usr/deleteDirector.action?dirId=${dirId}`)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.success;
}


//saveManagementTool
const saveManagementTool =async(formData)=>{
    const response=await axios.post(`${API_URL}/usr/saveManagementTool.action`, formData)

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.jsonData
}
//fetchManagementTool
const fetchManagementTool =async({projId})=>{
    const response = await axios.get(`${API_URL}/usr/fetchManagementTool.action?mtProjId=${projId}`)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.data.result;
}
//updateManagementTool
const updateManagementTool =async(formData)=>{
    const response=await axios.post(`${API_URL}/usr/saveManagementTool.action`, formData)

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.jsonData
}

//deleteManagementTool
const deleteManagementTool =async({mtId})=>{
    const response = await axios.get(`${API_URL}/usr/deleteManagementTool.action?mtId=${mtId}`)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.success;
}

const saveTotalCapacityOfStorage =async(formData)=>{
    const response=await axios.post(`${API_URL}/usr/saveTotalCapacityOfStorage.action`, formData)

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.jsonData
}
const fetchTotalCapacityOfStorages =async({projId})=>{
    const response = await axios.get(`${API_URL}/usr/fetchTotalCapacityOfStorages.action?projId=${projId}`)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.data.result;
}
// updateTotalCapacityOfStorages
const updateTotalCapacityOfStorages =async(formData)=>{
    const response=await axios.post(`${API_URL}/usr/saveTotalCapacityOfStorage.action`, formData)

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.jsonData
}

const deleteTotalCapacityOfStorage =async({stId})=>{
    const response = await axios.get(`${API_URL}/usr/deleteTotalCapacityOfStorage.action?stId=${stId}`)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.success;
}

const savePipeExtent =async(formData)=>{
    const response=await axios.post(`${API_URL}/usr/savePipeExtent.action`, formData)

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.jsonData
}
const fetchPipeExtents =async({projId})=>{
    const response = await axios.get(`${API_URL}/usr/fetchPipeExtents.action?projId=${projId}`)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.data.result;
}
// updatePipeExtent
const updatePipeExtent =async(formData)=>{
    const response=await axios.post(`${API_URL}/usr/savePipeExtent.action`, formData)

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.jsonData
}


const deletePipeExtent =async({peId})=>{
    const response = await axios.get(`${API_URL}/usr/deletePipeExtent.action?peId=${peId}`)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.success;
}

const saveBankInfo =async(formData)=>{
    const response=await axios.post(`${API_URL}/usr/saveBankInfo.action`, formData)

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.jsonData
}
const fetchBankInfos =async({projId})=>{
    const response = await axios.get(`${API_URL}/usr/fetchBankInfos.action?projId=${projId}`)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.data.result;
}
// updateBankInfos
const updateBankInfos =async(formData)=>{
    const response=await axios.post(`${API_URL}/usr/saveBankInfo.action`, formData)

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.jsonData
}

const deleteBankInfo =async({bId})=>{
    const response = await axios.get(`${API_URL}/usr/deleteBankInfo.action?bId=${bId}`)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.success;
}

const saveBudgetInfo =async(formData)=>{
    const response=await axios.post(`${API_URL}/usr/saveBudgetItem.action`, formData)

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.jsonData
}
const fetchBudgetInfo =async({projId})=>{
    const response = await axios.get(`${API_URL}/usr/fetchBudgetItems.action?projId=${projId}`)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.data.result;
}
// updateBudgetInfo 
const updateBudgetInfo =async(formData)=>{
    const response=await axios.post(`${API_URL}/usr/saveBudgetItem.action`, formData)

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.jsonData
}

const fetchCalculate =async({projId, id, sum})=>{
    const response = await axios.get(`${API_URL}/usr/calculate.action?projId=${projId}&id=${id}&sum=${sum}`)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.data.result;
}

const deleteBudgetInfo =async({biId})=>{
    const response = await axios.get(`${API_URL}/usr/deleteBudgetItem.action?biId=${biId}`)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.success;
}

//saveStaff
const saveStaff =async(formData)=>{
    const response=await axios.post(`${API_URL}/usr/saveOperationsStaff.action`, formData)

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.jsonData
}
const fetchStaff =async({projId})=>{
    const response = await axios.get(`${API_URL}/usr/fetchOperationsStaff.action?osProjId=${projId}`)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.data.result;
}
// updateStaff
const updateStaff =async(formData)=>{
    const response=await axios.post(`${API_URL}/usr/saveOperationsStaff.action`, formData)

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.jsonData
}

//deleteStaff
const deleteStaff =async({osId})=>{
    const response = await axios.get(`${API_URL}/usr/deleteOperationsStaff.action?osId=${osId}`)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.success;
}



//saveWaterSrc
const saveWaterSrc =async(formData)=>{
    const response=await axios.post(`${API_URL}/usr/saveWaterSource.action`, formData)

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.jsonData
}
//fetchWaterSrc
const fetchWaterSrc =async({projId})=>{
    const response = await axios.get(`${API_URL}/usr/fetchWaterSource.action?wsProjId=${projId}`)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.data.result;
}
//updateWaterSrc
const updateWaterSrc =async(formData)=>{
    const response=await axios.post(`${API_URL}/usr/saveWaterSource.action`, formData)

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.jsonData
}

//deleteWaterSrc
const deleteWaterSrc =async({wsId})=>{
    const response = await axios.get(`${API_URL}/usr/deleteWaterSource.action?wsId=${wsId}`)
    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data.success;
}





const onboardPartnerService={
    onboardPartner,
    onboardFiles,
    fetchPartnerApplications,
    saveDirector,
    fetchDirectors,
    fetchDirectorsById,
    updateDirector,
    saveManagementTool,
    fetchManagementTool,
    updateManagementTool,
    deleteManagementTool,
    saveTotalCapacityOfStorage,
    fetchTotalCapacityOfStorages,
    updateTotalCapacityOfStorages,
    savePipeExtent,
    fetchPipeExtents,
    updatePipeExtent,
    saveBankInfo,
    fetchBankInfos,
    updateBankInfos,
    saveBudgetInfo,
    fetchBudgetInfo,
    updateBudgetInfo,
    fetchCalculate,
    deleteDirector,
    deleteTotalCapacityOfStorage,
    deletePipeExtent,
    deleteBudgetInfo,
    deleteBankInfo,
    saveStaff,
    fetchStaff,
    updateStaff,
    deleteStaff,
    saveWaterSrc,
    fetchWaterSrc,
    updateWaterSrc,
    deleteWaterSrc,

}
export default onboardPartnerService
