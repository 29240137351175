import * as React from 'react';
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Chip, FormHelperText, Input, Typography } from '@mui/joy';
import { CircularProgress, Grid } from '@mui/material';
import { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { addUser, reset } from '../../features/auth/authSlice';
import { useEffect } from 'react';
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AddUserModal = ({ open, handleClickOpen, handleClose }) => {
  const [formData, setFormData] = useState('')
  const { usrFirstName, usrEmail, usrLastName, usrMiddleName, usrUsername, usrMobileNumber, usrNationalId } = formData
  const [error, setError] = useState('')
  const [isUpdating, setIsUpdating] = useState(false);
  const { user, isLoadingNewUser, isSuccess, isSuccessNewUser, isErrorNewUser, messageNewUser } = useSelector(state => state.auth)
  const dispatch = useDispatch()


  const isValidEmail = (usrEmail) => {
    return /\S+@\S+\.\S+/.test(usrEmail);
  }
  const [phoneNo,setPhoneNo] =  useState('');
  const [isValidPhone, setIsValidPhone] = useState(false);
  
  const handlePhone=(e)=>{
      const isKenyanPhoneNumber = /^254[17]\d{8}$|^2547\d{8}$/.test(e);
      setPhoneNo(e);
      setIsValidPhone(isKenyanPhoneNumber);
      setIsUpdating(true);
  }

  const handleChange = (e) => {
		if (!isValidEmail(usrEmail)) {
			setError('Enter a valid email address');
		} else {
			setError(null);
		}
		e.target.value < 0
			? (e.target.value = 0)
			: setFormData((prevState) => ({
					...prevState,
					[e.target.name]: e.target.value,
			  }));
		setIsUpdating(true);
	};

	const saveUser =async (e) => {
		if (!usrFirstName || !usrLastName || !phoneNo || !usrEmail) {
			toast.warning('Please fill all details about new member');
		} else if (!isValidPhone) {
			toast.warning('Enter a valid phone number');
		} else if (error) {
			toast.warning('Enter a valid email address');
		} else {
		const res = await dispatch(
				addUser({
					usrAccId: user.usrAccId,
					usrFirstName: usrFirstName,
					usrLastName: usrLastName,
					usrMobileNumber: phoneNo,
					usrEmail: usrEmail,
					usrMiddleName,
					usrMiddleName,
					usrNationalId: usrNationalId,
					usrEncryptedPassword: usrEmail,
					usrUsername: usrEmail,
				}),
			); 
		}
	};
 
	useEffect(() => {
		if (isSuccessNewUser) {
			toast.success(messageNewUser);
			handleClose();
			setFormData({
				usrFirstName: '',
				usrLastName: '',
				usrEmail: '',
				usrMiddleName: '',
				usrUsername: '',
				usrMobileNumber: '',
				usrNationalId: '',
			});
			setPhoneNo('');
		} else if (isErrorNewUser) {
			// handleClose();
			toast.error(messageNewUser);
		} else {
		}
		return () => {
			dispatch(reset());
		};
	}, [isSuccessNewUser, isErrorNewUser, messageNewUser]);

	return (
		<div>
			<BootstrapDialog
				onClose={handleClose}
				aria-labelledby='customized-dialog-title'
				open={open}
				fullWidth={true}
				maxWidth='md'
			>
				<BootstrapDialogTitle
					id='customized-dialog-title'
					onClose={handleClose}
				>
					<Typography component='h4' className='page-account-sub-title'>
						Add User 
					</Typography>
				</BootstrapDialogTitle>
				<DialogContent dividers>
					<Grid container spacing={2}>
						<Grid item xs={6} sx={{ mb: 1 }}>
							<Input
								label=''
								type='text'
								value={usrFirstName}
								name='usrFirstName'
								onChange={handleChange}
								placeholder='First Name *'
								size='lg'
								className='fmw-input-joy'
							/>
						</Grid>
						<Grid item xs={6} sx={{ mb: 1 }}>
							<Input
								label=''
								type='text'
								value={usrMiddleName}
								name='usrMiddleName'
								onChange={handleChange}
								placeholder='Middle Name *'
								size='lg'
								className='fmw-input-joy'
							/>
						</Grid>
						<Grid item xs={12} sx={{ mb: 1 }}>
							<Input
								label=''
								type='text'
								value={usrLastName}
								name='usrLastName'
								onChange={handleChange}
								placeholder='Last Name *'
								size='lg'
								className='fmw-input-joy'
							/>
						</Grid>

						<Grid item xs={12} sx={{ mb: 1 }}>
							<ReactPhoneInput
								defaultCountry='ke'
								country='ke'
								inputClass='fmw-phone'
								required
								searchClass='search-class'
								searchStyle={{
									margin: '0',
									width: '100% !important',
									height: '80px !important',
								}}
								enableSearchField
								disableSearchIcon
								value={phoneNo}
								onChange={handlePhone}
							/>
							{phoneNo && !isValidPhone && (
								<FormHelperText className='upload-sub-label-error'>
									Enter a valid phone number
								</FormHelperText>
							)}
						</Grid>
						<Grid item xs={12} sx={{ mb: 1 }}>
							<Input
								label=''
								type='text'
								value={usrEmail}
								name='usrEmail'
								onChange={handleChange}
								placeholder='Email *'
								size='lg'
								className='fmw-input-joy'
							/>
							{usrEmail && error && (
								<FormHelperText className='upload-sub-label-error'>
									{error}
								</FormHelperText>
							)}
						</Grid>
						<Grid item xs={12} sx={{ mb: 1 }}>
							<Input
								label=''
								type='number'
								value={usrNationalId}
								name='usrNationalId'
								onChange={handleChange}
								placeholder='Enter ID/Passport number *'
								size='lg'
								className='fmw-input-joy'
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions sx={{ mt: 1 }}>
					{!isLoadingNewUser ? (
						<Button
							variant='outlined'
							color='primary'
							sx={{
								textTransform: 'capitalize',
								backgroundColor: 'var(--bg-primary) !important',
							}}
							onClick={saveUser}
						>
							Save User
						</Button>
					) : (
						<Button disabled variant='contained' sx={{ textTransform: 'none' }}>
							<CircularProgress size={17} color='inherit' />
							&nbsp;Adding...
						</Button>
					)}
				</DialogActions>
			</BootstrapDialog>
		</div>
	);
}

export default AddUserModal