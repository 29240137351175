import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { AccountCircle, FirstPage, Flag, KeyboardArrowLeft, KeyboardArrowRight, LastPage, Visibility } from '@mui/icons-material';
import { cashConverter } from '../../utils';
import PropTypes from 'prop-types';
import { Box, IconButton, TableFooter, TablePagination, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import PrioritySelector from './PrioritySelector';
import AssigneeSelector from './AssigneeSelector';
import { useState } from 'react';
import eclipse from '../../assets/Ellipse.png'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F4FCFF',
    color: '#222',
    fontSize: '16px', fontWeight: 500,
    fontFamily: 'DM Sans',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};



export default function ViableTable({ expressionData, searchField, category }) {
  const navigate = useNavigate()
  const [assigned, setAssigned] = useState(false)
  const [assignedProjId, setAssignedProjId] = useState('')
  console.log("assigned--------", assigned)
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - expressionData?.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  console.log("expressionData", expressionData)
  const filteredData = expressionData?.filter((item) => {
    return item?.accTradeName?.toString().toLowerCase().includes(searchField) ||
      item?.views?.toString().includes(searchField.toString())
  })
  const handleProjDetails = (projId) => {
    navigate(`/intro-page/${projId}/${category}`)
  }
  // useEffect(() => {
  //     dispatch(fetchProjectPriority({
  //         priProjId:priorityProjId
  //     }))
  // }, [dispatch, isSuccessPriority])




  return (
    <>
      <TableContainer component={Paper} elevation={0}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table"
          className='intro-tables'
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Project</StyledTableCell>
              <StyledTableCell align="left">Application cost</StyledTableCell>
              <StyledTableCell align="left">Assignee</StyledTableCell>
              <StyledTableCell align="left">Priority</StyledTableCell>
              <StyledTableCell align="left">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {filteredData?.length === 0 ? (
              <TableRow>
                <StyledTableCell colSpan={4}>No data found</StyledTableCell>
              </TableRow>
            ) : (
              <>
                {(rowsPerPage > 0
                  ? filteredData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : filteredData
                ).map((row) => (

                  <StyledTableRow key={row.projId}>
                    <StyledTableCell component="th" scope="row"
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }} className='gap-5' >
                        {
                          !assigned ? (
                            <div><img src={eclipse} alt="eclipse" /></div>
                          ) : (
                            <div>{<AccountCircle sx={{ fontSize: '3rem', color: 'var(--color-technical)' }} />}</div>
                          )
                        }
                        <div>{row.accTradeName}</div>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell align="left">{cashConverter(row.projLoanAmount)}</StyledTableCell>
                    <StyledTableCell align="left"
                      onClick={() => setAssignedProjId(row.projId)}
                    >
                      <AssigneeSelector setAssigned={setAssigned} assignedProjId={assignedProjId} />
                    </StyledTableCell>
                    <StyledTableCell align="left"
                      onClick={() => setAssignedProjId(row.projId)}>
                      <PrioritySelector priorityProjId={assignedProjId} />
                    </StyledTableCell>
                    <StyledTableCell
                      onClick={() => handleProjDetails(row.projId)}
                      align="left" sx={{ cursor: 'pointer', textDecoration: 'underline' }} >
                      View
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </>
            )
            }

          </TableBody>
        </Table>

      </TableContainer>
      <TableFooter sx={{ display: 'flex', justifyContent: 'flex-end', }} >
        <TableRow >
          <TablePagination
            className='fmw-pagination'
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            colSpan={3}
            count={expressionData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableRow>
      </TableFooter>

    </>
  );
}