import React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import { Outlet } from "react-router-dom";
import { Box, Container, Toolbar } from "@mui/material";
import Header from "../components/Header";
import Menu from "../components/menu/Menu";
import { CssVarsProvider } from "@mui/joy/styles";
import { useSelector } from "react-redux";

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: 245,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const WithNav = () => {
  const { user } = useSelector((state) => state.auth);
  // if (user.usrJbrnId === 2) {
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <Header />

        <Drawer variant="permanent" open={true} className="nav-drawer fmw-drawer">
          <Menu />
        </Drawer>

        <Box
          component="main"
          sx={{
            backgroundColor: '#fff',
            // color-background
            flexGrow: 1,
            overflow: "auto",
          }}
        >
          <Toolbar />

          <Container className="main" maxWidth="xl" sx={{ my: 2 }}>
            <Outlet />

            {/* <Footer /> */}
          </Container>
        </Box>
      </Box>

      <CssVarsProvider />
    </>
  );
};

export default WithNav;


