import { Button, Typography } from '@mui/joy';
import { Divider, Grid, Skeleton } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import placeholder from '../../assets/placeholder.jpg';
import { BorderColorOutlined, Delete } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';

import {
	deleteNewUser,
	fetchCurrentUser,
	fetchNewUser,
	reset,
} from '../../features/auth/authSlice';
import { useEffect } from 'react';
import { useState } from 'react';
import { fetchAccountInfo } from '../../features/tech_adv/techAdvSlice';
import { formatImgPath } from '../../utils';
import DeleteAccountDialog from '../../components/users/DeleteAccountDialog';
import AddUserModal from '../../components/users/AddUserModal';
import EditAccountDialog from './EditAccountDialog';
import { useParams } from 'react-router-dom';
import { SocialMediaDropdown } from './components/socialMediaDropDown';

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	// ...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
}));

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

function AdvisorAccount() {
	const dispatch = useDispatch();
	const {
		user,
		newUsers,
		isLoadingNewUser,
		isSuccessDelete,
		isLoadingDelete,
		isSuccessNewUser,
	} = useSelector((state) => state.auth);
	const { orgDetails, techDetails } = useSelector((state) => state.techAdv);
	const [open, setOpen] = React.useState(false);
	const [image, setImage] = useState('');
	const [count, setCount] = useState(0);
	const [openDialog, setOpenDialog] = useState(false);
	const { userId, accId } = useParams();

	const [formData, setFormData] = useState({
		accTradeName: orgDetails.accTradeName,
		accPhoneNumber: orgDetails.accPhoneNumber,
		accLocation: orgDetails.accLocation,
		accZipCode: orgDetails.accZipCode,
		accEmail: orgDetails.accEmail,
		usrLastName: techDetails.usrLastName,
		usrTitle: techDetails.usrTitle,
		usrFullNames: techDetails.usrFullNames,
		usrFirstName: techDetails.usrFirstName,
	});
	const handleClickOpen = () => {
		dispatch(fetchAccountInfo(user?.usrId));
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const [openDelete, setOpenDelete] = React.useState(false);

	const handleClickOpenDelete = () => {
		setOpenDelete(true);
	};

	const handleCloseDelete = () => {
		setOpenDelete(false);
	};
	const [personId, setPersonId] = useState();
	// const handleDelete = (id) => {
	//   dispatch(
	//     deleteNewUser({
	//       usrId: id,
	//     })
	//   );
	// };

	const handleProfile = async () => {
		dispatch(fetchAccountInfo(user?.usrId));
		let localImg = await formatImgPath(orgDetails.accIcon);
		setImage(localImg);
	};

	async function fetchLoggedInUser() {
		await dispatch(
			fetchCurrentUser({
				accId: accId,
				usrId: userId,
			}),
		);
	}

	useEffect(() => {
		fetchLoggedInUser();
	}, []);

	useEffect(() => {
		fetchLoggedInUser();
	}, [accId, userId]);

	 
	useEffect(() => {
		handleProfile();
	}, [orgDetails]);
 
	useEffect(() => {
		if (!isLoadingNewUser) {
			dispatch(
				fetchNewUser({
					accId: user?.usrAccId,
					usrId: '',
				}),
			);
			setCount(newUsers?.length);
		}

		if (isSuccessNewUser) {
			dispatch(
				fetchNewUser({
					accId: user?.usrAccId,
					usrId: '',
				}),
			);
			setCount(newUsers?.length);
		}
		if (isSuccessDelete) {
			dispatch(
				fetchNewUser({
					accId: user?.usrAccId,
					usrId: '',
				}),
			);
			setTimeout(() => {
				setCount(newUsers?.length);
			}, 800);
		}

		return () => {
			dispatch(reset());
		};
	}, [dispatch, isSuccessDelete, isSuccessNewUser]);

	// let localImg = formatImgPath(orgDetails.accIcon);

	useEffect(() => {
		setFormData({
			accTradeName: orgDetails.accTradeName,
			accPhoneNumber: orgDetails.accPhoneNumber,
			accLocation: orgDetails.accLocation,
			accZipCode: orgDetails.accZipCode,
			accEmail: orgDetails.accEmail,
			usrLastName: techDetails.usrLastName,
			usrTitle: techDetails.usrTitle,
			usrFullNames: techDetails.usrFullNames,
			usrFirstName: techDetails.usrFirstName,
		});
	}, [openDialog]);

	return (
		<Box sx={{ height: '100vh', overflow: 'none', }}>
			{isLoadingNewUser ? (
				<>
					<Skeleton width={1160} height={30} />
					<Skeleton width={1160} height={30} />
					<Skeleton width={1160} height={30} />
					<Skeleton width={1160} height={30} />
					<Skeleton width={1160} height={30} />
				</>
			) : (
				<Box sx={{}}>
					<Grid container spacing={2} >
						<Grid item xs={12} sx={{ mb: 2, alignItems: 'center', height: '100%', overflowY: 'none', }}>
							<Box
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
							>
								<Typography
									component='h3'
									className='fmw-page-title'
									style={{
										paddingLeft: 'none',
										display: 'flex',
										justifyContent: 'flex-start',
									}}
								>
									My account
								</Typography>
								{user?.usrJbrnId === 2 ||
									user?.usrJbrnId === 0 ||
									user?.usrJbrnId === 3 ? (
									<Button
										color='neutral'
										size='sm'
										className='fmw-btn-info'
										onClick={handleClickOpen}
									>
										Add Users 
									</Button>
								) : null}
							</Box>
							<Divider sx={{ mt: '17px' }} />
						</Grid>

						<Grid item xs={12} sx={{ mb: 0 }}>
							<div
								style={{
									width: '',
									height: '193px',
									background:
										'linear-gradient(90deg, rgba(38,222,255,1) 0%, rgba(0,186,186,1) 35%)',
									borderRadius: '2px',
									position: 'relative',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<div
									style={{
										width: '148px',
										height: '148px',
										borderRadius: '50%',
										background: '#F7F7F7',
										position: 'absolute',
										top: '52px',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<img
										src={
											typeof orgDetails.accIcon === 'object'
												? placeholder
												: formatImgPath(orgDetails.accIcon)
										}
										alt='ecclipse-logo'
										style={{
											objectFit: 'cover',
											borderRadius: '50%',
											width: '148px',
											height: '148px',
										}}
									/>
								</div>
							</div>
							<Grid
								item
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									padding: '1.5rem',
								}}
							>
								<Grid item>
									<SocialMediaDropdown />
								</Grid>
								<Grid item style={{ marginLeft: "-200px" }}>
									<Typography
										variant="h2"
										sx={{ fontWeight: "bold", fontSize: "18px" }}
										className="fmw-page-account-title"
									>
										{orgDetails.accTradeName}
									</Typography>
								</Grid>
								<Grid item>
									<Button onClick={() => setOpenDialog(true)} variant='plain'>
										<BorderColorOutlined
											style={{ fontSize: '1.8rem', color: '#000' }}
										/>
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Divider />

					<Grid
						container
						spacing={2}
						style={{ margin: '1rem 3rem', color: '#202020', fontSize: '15px' }}
					>
						<Grid item xs={6}>
							<Typography
								component='h4'
								className='page-account-sub-title'
								sx={{ mb: '1rem' }}
							>
								Company
							</Typography>
							<Grid container spacing={2}>
								<Grid item xs={3}>
									Company email:
								</Grid>
								<Grid item xs={9}>
									<Typography component='p' className='fmw-p'>
										{orgDetails.accEmail}
									</Typography>
								</Grid>
								<Grid item xs={3}>
									Phone:
								</Grid>
								<Grid item xs={9}>
									<Typography component='p' className='fmw-p'>
										{orgDetails.accPhoneNumber}
									</Typography>
								</Grid>
								<Grid item xs={3}>
									Business location:
								</Grid>
								<Grid item xs={9}>
									<Typography component='p' className='fmw-p'>
										{orgDetails.accLocation}
									</Typography>
								</Grid>
								<Grid item xs={3}>
									Business zip code:
								</Grid>
								<Grid item xs={9}>
									<Typography component='p' className='fmw-p'>
										{orgDetails.accZipCode}
									</Typography>
								</Grid>
							</Grid>

							<Typography
								component='h4'
								className='page-account-sub-title'
								sx={{ mb: '1rem', mt: '3rem' }}
							>
								Company contact person
							</Typography>
							<Grid container spacing={2}>
								<Grid item xs={3}>
									Name:
								</Grid>
								<Grid item xs={9}>
									<Typography component='p' className='fmw-p'>
										{techDetails.usrFullNames}
									</Typography>
								</Grid>
								<Grid item xs={3}>
									Email
								</Grid>
								<Grid item xs={9}>
									<Typography component='p' className='fmw-p'>
										{techDetails.usrEmail}
									</Typography>
								</Grid>
								<Grid item xs={3}>
									Phone:
								</Grid>
								<Grid item xs={9}>
									<Typography component='p' className='fmw-p'>
										{techDetails.usrMobileNumber}
									</Typography>
								</Grid>
							</Grid>
						</Grid>

						{user?.usrJbrnId === 2 || user?.usrJbrnId === 0  || user?.usrJbrnId === 3 ?(
							<Grid item xs={6}>
								<Grid item xs={8}>
									{isLoadingNewUser || isLoadingDelete ? (
										<>
											<Skeleton width={660} height={30} />
											<Skeleton width={660} height={30} />
											<Skeleton width={660} height={30} />
											<Skeleton width={660} height={30} />
											<Skeleton width={660} height={30} />
										</>
									) : (
										<>
											<Box>
												<Grid container spacing={4}>
													<Grid item xs={6}>
														<Typography
															component='h6'
															className='page-account-sub-title'
														>
															Users{' '}
															<span
																style={{ color: 'var(--color-primary)' }}
															></span>
														</Typography>
													</Grid>
													<Grid
														item
														xs={6}
														style={{
															display: 'flex',
															justifyContent: 'flex-end',
														}}
													>
														<Typography
															component='h6'
															sx={{
																fontSize: '.925rem',
																color: 'var(--color-primary)',
															}}
														>
															View all
														</Typography>
													</Grid>
												</Grid>
												<Divider sx={{ mt: 1 }} />
											</Box>
											{newUsers?.length &&
												newUsers?.slice(1)?.map((person) => (
													<Box>
														<Grid
															container
															spacing={4}
															style={{ display: 'flex', alignItems: 'center' }}
														>
															<Grid item xs={6} style={{}}>
																<Typography
																	component='p'
																	sx={{ mt: 1.5, color: 'inherit!important' }}
																	className='fmw-p'
																>
																	{person.usrFirstName} {person.usrLastName}
																</Typography>
																<Typography
																	component='p'
																	sx={{ mb: 1.5 }}
																	className='fmw-p'
																>
																	{person.usrEmail}
																</Typography>
															</Grid>
															<Grid
																item
																xs={6}
																style={{
																	display: 'flex',
																	justifyContent: 'flex-end',
																}}
															>
																<Typography
																	component='p'
																	sx={{ mt: 1.5, mb: 1.5 }}
																	className='fmw-p'
																	onClick={() => setPersonId(person)}
																>
																	<Delete
																		sx={{
																			color: '#E4E4E4',
																			fontSize: '2.4rem',
																		}}
																		onClick={handleClickOpenDelete}
																	/>
																</Typography>
															</Grid>
														</Grid>
														<Divider />
													</Box>
												))}
										</>
									)}
								</Grid>
							</Grid>
						) : null}
					</Grid>
					<AddUserModal
						open={open}
						handleClickOpen={handleClickOpen}
						handleClose={handleClose}
					/>
					<EditAccountDialog
						open={openDialog}
						setOpen={setOpenDialog}
						accId={user?.usrAccId}
						orgDetails={orgDetails}
						user={user}
						formData={formData}
						setFormData={setFormData}
						openDialog={openDialog}
					/>
					<DeleteAccountDialog
						open={openDelete}
						handleClickOpen={handleClickOpenDelete}
						handleClose={handleCloseDelete}
						personId={personId}
					/>
				</Box>
			)}
		</Box>
	);
}

export default AdvisorAccount;
