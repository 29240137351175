import axios from "axios"

const API_URL  = `${process.env.REACT_APP_API_BASE_URL}`;
 
const saveDealSourcing =async(formData)=>{
    const response = await axios.post(`${API_URL}/usr/save_DealSourcing.action`, formData)

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }

    return response.data.jsonData;
}

const pipelinesService={
    saveDealSourcing,
}
export default pipelinesService
