import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/joy';
import SingleAttachmentComplete from '../../components/SingleAttachmentComplete';
import NoTableDataComponent from '../../components/NoTableDataComponent';

function createData(name, calories, fat, carbs, protein) {
	return { name, calories, fat, carbs, protein };
}

export default function DescWaterSources() {
	const { wtrSrc } = useSelector((state) => state.onboardPartner);
	const rows = [
		createData('Meter reader', 159),
		createData('Project manager', 237),
	];
	return (
		<TableContainer
			component={Paper}
			elevation={0}
			sx={{ background: 'inherit' }}
		>
			<Table sx={{ minWidth: 650 }} aria-label='simple table'>
				<TableHead>
					<TableRow className='table'>
						<TableCell>Source</TableCell>
						<TableCell align='left'>Reliability </TableCell>
						<TableCell align='left'>Quality</TableCell>
						<TableCell>Quality File</TableCell>
						<TableCell align='left'>Meter </TableCell>
						<TableCell align='left'>Meter File</TableCell>
						<TableCell>Permit</TableCell>
						<TableCell align='left'>Permit File </TableCell>
						<TableCell align='left'>System</TableCell>
						<TableCell align='left'>Power</TableCell>
						<TableCell align='left'>Pump</TableCell>
					</TableRow>
				</TableHead>
				{wtrSrc.length > 0 ? (
					<TableBody className='basic'>
						{wtrSrc?.map((row) => (
							<TableRow key={row?.wsSource}>
								<TableCell
									component='th'
									scope='row'
									sx={{ borderLeft: 'none !important' }}
								>
									{row?.wsSource}
								</TableCell>
								<TableCell align='left'>{row?.wsReliability}</TableCell>
								<TableCell align='left'>{row?.wsQuality}</TableCell>
								<TableCell align='left'>
									<SingleAttachmentComplete
										title={row?.wsQualityFile}
										tableFile='true'
									/>
								</TableCell>
								<TableCell align='left'>{row?.wsMasterMtr}</TableCell>
								<TableCell align='left'>
									<SingleAttachmentComplete
										title={row?.wsMtrFile}
										tableFile='true'
									/>
								</TableCell>
								<TableCell align='left'>{row?.wsWra}</TableCell>
								<TableCell align='left'>
									<SingleAttachmentComplete
										title={row?.wsWraFile}
										tableFile='true'
									/>
								</TableCell>
								<TableCell align='left'>{row?.wsSystem}</TableCell>
								<TableCell align='left'>{row?.wsPower}</TableCell>
								<TableCell align='left'>
									<SingleAttachmentComplete
										title={row?.wsPowerFile}
										tableFile='true'
									/>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				) : (
					<Box
						sx={{ display: 'flex', width: '60vw', justifyContent: 'center' }}
					>
						<NoTableDataComponent />
					</Box>
				)}
			</Table>
		</TableContainer>
	);
}
