import React, { useEffect, useState } from 'react';
import { Typography, colors } from '@mui/joy';
import { Box, Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import styled from 'styled-components';
import { DragIndicator, ExpandMore } from '@mui/icons-material';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
	fetchProjectScore,
	getPipeInformations,
	getStorageInformations,
	postScoreStep,
	setProjectScore,
} from '../../../../features/expression_demand/expressionDemandSlice';
import {
	fetchStaff,
	fetchWaterSrc,
} from '../../../../features/onboard_partner/onboardPartnerSlice';
import WaterSourceInfrastractureTable from './WaterSourceInfrastractureTable';
import SingleItem from '../../components/single_item';
import DescWaterSources from './DescWaterSources';
import TankCapacity from './TankCapacity';
import Pipeline from './Pipeline';
import OperationStaff from './OperationStaff';
import Attachments from './Attachments';
import TextareaComment from '../../components/TextareaComment';

const WaterSource = () => {
	const { singleProject } = useSelector((state) => state.global);
	const { scores } = useSelector((state) => state.expressionDemandData);
	const {  isLoadingStaff, operationalStaff } = useSelector(
		(state) => state.onboardPartner,
	);
	const dispatch = useDispatch();

	const [expanded, setExpanded] = React.useState('panel1');
	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};


	const [onBlur, setOnBlur] = useState(true);
	const [comment, setComment] = useState('');
	const [matrixInfo, setMatrixInfo] = useState({});
	const [userMatrixInfo, setUserMatrixInfo] = useState({});
	const [userComment, setUserComment] = useState({});
	const stepName = 'PROJECT_DESCRIPTION';


	const { storageInformation, pipeInformations } = useSelector(
		(state) => state.expressionDemandData,
	);

	function createData(name, description, variableName, maxScores) {
		return { name, description, variableName, maxScores };
	}

	const fetchProjectScores = async () => {
		const info = { projId: singleProject?.projId, page: stepName };
		await dispatch(fetchProjectScore(info));
	};

	function handleCommentScore() {
		scores?.forEach((score) => {
			const { scScore, scMethod, scUsrScore, scUsrComment } = score;
			setMatrixInfo((prevMatrixInfo) => ({
				...prevMatrixInfo,
				[scMethod]: scScore,
			}));
			setUserMatrixInfo((prevMatrixInfo) => ({
				...prevMatrixInfo,
				[scMethod]: scUsrScore,
			}));
			setUserComment((prevMatrixInfo) => ({
				...prevMatrixInfo,
				[scMethod]: scUsrComment,
			}));
		});
	}

	const values = Object.values(matrixInfo)?.map((v) => parseInt(v) || 0);

	const total = values.reduce((acc, val) => acc + val, 0);

  const {projId} = singleProject

	const fetchReduxData = async () => {
		await dispatch(getPipeInformations(singleProject?.projId));
		await dispatch(getStorageInformations(singleProject?.projId));
		await dispatch(fetchStaff({projId}));
		await dispatch(fetchWaterSrc({projId}));
	};



	const handleTotalScores = () => {
		dispatch(setProjectScore({ [stepName]: total }));
	};

	const tableOneData = [
		createData(
			'Is the project existing and operational ?',
			singleProject?.projExistingOperational,
			'projExistingOperational',
			5,
		),

		createData(
			'Other existing sources within the water project area of operation',
			singleProject?.projOtherExistingWaterSources,
			'projOtherExistingWaterSources',
			5,
		),
		createData(
			'Is there a WSP or another SSWP operating within the same area of the applicant ',
			singleProject?.projOtherWspSswp,
			'projOtherWspSswp',
			5,
		),
	];

	const attachments = [
		createData(
			'PDF of water quality analysis of source water',
			singleProject?.projWaterQualityFile,
			'projWaterQualityFile',
		),
		createData(
			'PDF of 12 months meter readings',
			singleProject?.projMeterReadingsFile,
			'projMeterReadingsFile',
		),
		createData(
			'PDF of picture of pump- if pumped or hybrid',
			singleProject?.projPictureOfPumpFile,
			'projPictureOfPumpFile',
		),
	];

  useEffect(() => {}, [
		operationalStaff,
		storageInformation,
		pipeInformations,
		isLoadingStaff,
	]);

	useEffect(() => {
		handleTotalScores();
	}, [total]);


  useEffect(() => {
		handleCommentScore();
	}, [scores, singleProject]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		handleCommentScore();
		fetchProjectScores();
    fetchReduxData();
	}, []);


	return (
		<Box sx={{ display: 'flex', gap: '30.03px', flexDirection: 'column' }}>
			{singleProject?.projStatus == 'complete' || singleProject?.projStatus == 'incomplete' ? (
				<Accordion
					expanded={expanded === 'panel1'}
					onChange={handleChange('panel1')}
					className='fmw-accrdion-table fmw-accrd'
					elevation={0}
				>
					<AccordionSummary
						expandIcon={
							<ExpandMore
								sx={{
									color:
										expanded == 'panel1' ? '#7E93AE' : 'var(--color-technical)',
								}}
								className='fmw-accrdion-svg'
							/>
						}
						aria-controls='panel1bh-content'
						id='panel1bh-header'
					>
						<Typography
							className='gap-5 accordion-title'
							sx={{
								width: '33%',
								flexShrink: 0,
								paddingBottom: '15px !important',
							}}
						>
							<DragIndicator className='fmw-accrdion-drag-svg' />
							Water demand assessment
						</Typography>
					</AccordionSummary>
					<AccordionDetails sx={{ padding: '0px !important' }}>
						<WaterSourceInfrastractureTable  />
					</AccordionDetails>
				</Accordion>
			) : (
				<Accordion
					expanded={expanded === 'panel1'}
					onChange={handleChange('panel1')}
					className='fmw-accrdion'
					elevation={0}
				>
					<AccordionSummary
						expandIcon={
							<ExpandMore
								sx={{
									color:
										expanded == 'panel1' ? '#7E93AE' : 'var(--color-technical)',
								}}
								className='fmw-accrdion-svg'
							/>
						}
						aria-controls='panel1bh-content'
						id='panel1bh-header'
					>
						<Typography
							className='gap-5 accordion-title'
							sx={{ width: '33%', flexShrink: 0 }}
						>
							<DragIndicator className='fmw-accrdion-drag-svg' />
							Water demand assessment
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Box
							sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
						>
							{tableOneData?.map((row) => (
								<SingleItem
									row={row}
									userComment={userComment}
									setUserComment={setUserComment}
									setMatrixInfo={setMatrixInfo}
									matrixInfo={matrixInfo}
									name={row.variableName}
									stepName={stepName}
									id={singleProject?.projId}
									setOnblur={setOnBlur}
									onBlur={onBlur}
									maxScores={row.maxScores}
									comment={comment}
									setComment={setComment}
									setUserMatrixInfo={setUserMatrixInfo}
									userMatrixInfo={userMatrixInfo}
								/>
							))}
						</Box>
					</AccordionDetails>
				</Accordion>
			)}

			<Accordion
				expanded={expanded === 'panel3'}
				onChange={handleChange('panel3')}
				className='fmw-accrdion-table fmw-accrd'
				elevation={0}
			>
				<AccordionSummary
					expandIcon={
						<ExpandMore
							sx={{
								color:
									expanded == 'panel3' ? '#7E93AE' : 'var(--color-technical)',
							}}
							className='fmw-accrdion-svg'
						/>
					}
					aria-controls='panel3bh-content'
					id='panel3bh-header'
				>
					<Typography
						className='gap-5 accordion-title'
						sx={{
							width: '33%',
							flexShrink: 0,
							paddingBottom: '15px !important',
						}}
					>
						<DragIndicator className='fmw-accrdion-drag-svg' />
						Description of water sources
					</Typography>
				</AccordionSummary>
				<AccordionDetails sx={{ padding: '0px !important' }}>
					<DescWaterSources />
				</AccordionDetails>
			</Accordion>

			<Accordion
				expanded={expanded === 'panel4'}
				onChange={handleChange('panel4')}
				className='fmw-accrdion-table fmw-accrd'
				elevation={0}
			>
				<AccordionSummary
					expandIcon={
						<ExpandMore
							sx={{
								color:
									expanded == 'panel4' ? '#7E93AE' : 'var(--color-technical)',
							}}
							className='fmw-accrdion-svg'
						/>
					}
					aria-controls='panel4bh-content'
					id='panel4bh-header'
				>
					<Typography
						className='accordion-title'
						sx={{
							width: '75%',
							flexShrink: 0,
							paddingBottom: '15px !important',
						}}
					>
						<DragIndicator
							className='fmw-accrdion-drag-svg'
							style={{ marginRight: '1.25rem' }}
						/>
						Total capacity of storage tanks within the project{' '}
						<span style={{ marginLeft: '10px' }}>
							(m<sup>3</sup>)
						</span>
					</Typography>
				</AccordionSummary>
				<AccordionDetails sx={{ padding: '0px !important' }}>
					<TankCapacity />
				</AccordionDetails>
			</Accordion>

			<Accordion
				expanded={expanded === 'panel5'}
				onChange={handleChange('panel5')}
				className='fmw-accrdion-table fmw-accrd'
				elevation={0}
			>
				<AccordionSummary
					expandIcon={
						<ExpandMore
							sx={{
								color:
									expanded == 'panel5' ? '#7E93AE' : 'var(--color-technical)',
							}}
							className='fmw-accrdion-svg'
						/>
					}
					aria-controls='panel5bh-content'
					id='panel5bh-header'
				>
					<Typography
						className='gap-5 accordion-title'
						sx={{
							width: '75%',
							flexShrink: 0,
							paddingBottom: '15px !important',
						}}
					>
						<DragIndicator className='fmw-accrdion-drag-svg' />
						Extent of pipelines (diameter)
					</Typography>
				</AccordionSummary>
				<AccordionDetails sx={{ padding: '0px !important' }}>
					<Pipeline />
				</AccordionDetails>
			</Accordion>

			<Accordion
				expanded={expanded === 'panel2'}
				onChange={handleChange('panel2')}
				className='fmw-accrdion-table fmw-accrd'
				elevation={0}
			>
				<AccordionSummary
					expandIcon={
						<ExpandMore
							sx={{
								color:
									expanded == 'panel2' ? '#7E93AE' : 'var(--color-technical)',
							}}
							className='fmw-accrdion-svg'
						/>
					}
					aria-controls='panel2bh-content'
					id='panel2bh-header'
				>
					<Typography
						className='gap-5 accordion-title'
						sx={{
							width: '33%',
							flexShrink: 0,
							paddingBottom: '15px !important',
						}}
					>
						<DragIndicator className='fmw-accrdion-drag-svg' />
						Operations staff information
					</Typography>
				</AccordionSummary>
				<AccordionDetails sx={{ padding: '0px !important' }}>
					<OperationStaff
						userComment={userComment}
						setUserComment={setUserComment}
						setMatrixInfo={setMatrixInfo}
						matrixInfo={matrixInfo}
						name='operationsScores'
						stepName={stepName}
						id={singleProject?.projId}
						comment={comment}
						setComment={setComment}
						setUserMatrixInfo={setUserMatrixInfo}
						userMatrixInfo={userMatrixInfo}
					/>
				</AccordionDetails>
			</Accordion>

			<Accordion
				expanded={expanded === 'panel6'}
				onChange={handleChange('panel6')}
				className='fmw-accrdion-table fmw-accrd'
				elevation={0}
			>
				<AccordionSummary
					expandIcon={
						<ExpandMore
							sx={{
								color:
									expanded == 'panel6' ? '#7E93AE' : 'var(--color-technical)',
							}}
							className='fmw-accrdion-svg'
						/>
					}
					aria-controls='panel6bh-content'
					id='panel6bh-header'
				>
					<Typography
						className='gap-5 accordion-title'
						sx={{
							width: '75%',
							flexShrink: 0,
							paddingBottom: '15px !important',
						}}
					>
						<DragIndicator className='fmw-accrdion-drag-svg' />
						Attachments
					</Typography>
				</AccordionSummary>
				<AccordionDetails sx={{ padding: '0px !important' }}>
					<Attachments attachments={attachments} />
				</AccordionDetails>
			</Accordion>

			
				{/* <Grid item xs={12}>
					<Grid item xs={12}>
						<TextareaComment
							comment={comment}
						/>
					</Grid>
				</Grid> */}
		</Box>
	);
};

export default WaterSource;
