import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_BASE_URL}`;

const initialState = {
	loading: false,
	error: '',
	loanPerfections: [],
	loanPerfectionDetails: {},
	message: '',
};

// export const saveLoanPerfection = createAsyncThunk(
// 	'project/saveLoanPerfection',
// 	async (formData) => {
// 		const response = await axios.post(
// 			`${API_URL}/usr/saveLoanTermCondition.action`,
// 			formData,
// 		);
// 		if (!response.data.success) {
// 			return Promise.reject(response.data.message);
// 		}
// 		return response.data.jsonData;
// 	},
// );

export const fetchLoanPerfections = createAsyncThunk(
	'project/fetchLoanPerfections',
	async (ltcAccId) => {
		const response = await axios.get(
			`${API_URL}/usr/fetchLoanTermConditions.action?ltcAccId=${ltcAccId}&ltcStatus=ACCEPTED`,
		);
		if (!response.data.success) {
			return Promise.reject(response.data.message);
		}
		return response.data.data.result;
	},
);

export const projectSlice = createSlice({
	name: 'project',
	initialState,
	reducers: {
		setLoanPerfection: (state, action) => {
			state.loanPerfectionDetails = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder

			.addCase(fetchLoanPerfections.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchLoanPerfections.fulfilled, (state, action) => {
				state.loanPerfections = action.payload;
			})
			.addCase(fetchLoanPerfections.rejected, (state, action) => {
				state.loading = false;
				state.message = action.payload;
			});
	},
});

export const { setLoanPerfection } = projectSlice.actions;
export default projectSlice.reducer;
