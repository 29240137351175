import { Box, IconButton, TextField, Tooltip } from '@mui/material';
import { Typography } from '@mui/joy';
import React, { useState } from 'react';
import { BorderColor } from '@mui/icons-material';
import FileButton from './FileButton';
import { formatImgPath, formatPath, } from '../../../utils';

const SingleAttachmentComplete = ({
	title,
	comment,
	tableFile,
	titleLabel,
}) => {
	const [editing, setEditing] = useState(false);

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column' }}>
			{!tableFile ? (
				<Typography
					sx={{
						fontWeight: 700,
						fontSize: '16px',
						my: 0,
						display: 'flex',
						alignItems: 'center',
					}}
				>
					{titleLabel}
					<FileButton title={title} titlePreview={title} viable='true' />
					{comment && (
						<Tooltip title='Edit comment' arrow placement='top'>
							<IconButton onClick={() => setEditing(!editing)}>
								<BorderColor
									sx={{
										fontWeight: 'inherit',
										fontSize: 'inherit',
										color: '#000000DE',
									}}
								/>
							</IconButton>
						</Tooltip>
					)}
				</Typography>
			) : (
				<Typography sx={{ fontWeight: 700, fontSize: '16px', my: 0 }}>
					<FileButton title={title} titlePreview={title} />
				</Typography>
			)}

			<Typography>{comment}</Typography>
		</Box>
	);
};

export default SingleAttachmentComplete;
