import "./App.css";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import RedirectToHTTPS from "./RedirectToHTTPS";
import WithoutNav from "./layout/WithoutNav";
import WithNav from "./layout/WithNav";
import internetloss from "./assets/img/internet2.jpg";
import SetPassword from "./pages/signin/SetPassword";
import Home from "./pages/home/Home";
import Complete from "./pages/expression_of_demand/Complete";
import ViewProject from "./components/global/ViewProject";
import Screening from "./components/wsp_screening/Screening";
import InComplete from "./pages/expression_of_demand/Incomplete";
import Messages from "./pages/messages/Messages";
import Signups from "./pages/expression_of_demand/Signups";
import ProjectPreparation from "./pages/pipelines/ProjectPreparation";
import AdvisorAccount from "./pages/my_account/AdvisorAccount";
import Dashboard from "./pages/dashboard/sections/stats/stats";
import WashProspects from "./pages/wash_prospects/WashProspects";
import BankApproval from "./pages/pipelines/BankApproval";
import ViableProjects from "./pages/deal_room/ViableProject";
import NewProject from "./pages/new_project/NewProject";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IntroPage from "./components/wsp_screening/intro_page";
import CommittedProjects from "./pages/committedProjects/CommittedProjects";
import ImpactDashboard from "./pages/impact-dashbaord/dashboard/ImpactDashboard";
import WaterResource from "./pages/impact-dashbaord/water-resource/WaterResource";
import Monitoring from "./pages/impact-dashbaord/monitoring/Monitoring";
import SingleWaterResource from "./pages/impact-dashbaord/water-resource/SingleWaterResource";
import PrepareProjectMain from "./pages/project_preparation/prepare_project_steps/PrepareProjectMain";
import CommitedProjects from "./pages/project_preparation/CommitedProjects";
import ProjectPreparationView from "./pages/prepare_project_view/ProjectPreparationView";

function App() {
  if (!navigator.onLine) {
    return (
      <>
        <div
          style={{ padding: "0px 50px", margin: "0px 50px" }}
          className='container text-center'
        >
          <h1 style={{ color: "red", fontWeight: 700 }}>
            Oops! Something went wrong...
          </h1>
          <p>You appear to be offline. Turn on your Wi-Fi to continue</p>
          <img
            alt='No internet'
            src={internetloss}
            style={{ objectFit: "cover", width: "100%" }}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <Router>
        <Routes component={RedirectToHTTPS}>
          <Route element={<WithoutNav />}>
            <Route path='/' element={<Home />} />
            {/* <Route path='/signup-impact-funder' element={<SignupPartner />} />
						<Route path='/verify-login' element={<VerifyLogin />} />
						<Route path='/verify-email' element={<VerifyEmail />} />
						<Route path='/news-events' element={<NewsEvents />} />
						<Route path='/publications' element={<Publication />} />
						<Route path='/test-pass' element={<TestPass />} /> */}

            <Route
              path='/set-password/:userName/:userId'
              element={<SetPassword />}
            />
            {/* Partner routes */}
          </Route>

          <Route element={<WithNav />}>
            <Route
              path='/impact-funder-account/:userId/:accId'
              element={<Dashboard />}
            />
            <Route path='/complete' element={<Complete />} />
            <Route path='/wash' element={<WashProspects />} />
            <Route path='/incomplete' element={<InComplete />} />
            <Route path='/intro-page/:id/:status' element={<IntroPage />} />
            <Route path='/:url/:id/:name' element={<ViewProject />} />
            <Route path='/screening/:id/:name' element={<Screening />} />
            <Route path='/messages' element={<Messages />} />
            <Route path='/ssp-accounts' element={<Signups />} />
            <Route path='/deal-sourcing' element={<BankApproval />} />
            <Route path='/viable' element={<ViableProjects />} />
            <Route path='/dashboard' element={<AdvisorAccount />} />
            <Route path='/new-project' element={<NewProject />} />
            <Route path='/impact-dashboard' element={<ImpactDashboard />} />
            <Route path='/water-sector' element={<WaterResource />} />
            <Route path='/monitoring' element={<Monitoring />} />
            <Route path='//single-water' element={<SingleWaterResource />} />
            <Route
              path='/project-preparation'
              element={<ProjectPreparationView />}
            />
            <Route
              path='/project-preparation/commited-projects'
              element={<CommitedProjects />}
            />
          </Route>
        </Routes>
      </Router>

      <ToastContainer
        position='bottom-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
    </>
  );
}
export default App;
