import { useEffect, useState } from 'react';
import GlobalSearch from '../../components/global/GlobalSearch';
import PageTitle from '../../components/global/PageTitle';
import { useDispatch, useSelector } from 'react-redux';
import UserListTable from '../../components/global/UserListTable';
import { fetchWsp } from '../../features/auth/authSlice';

export default function Signups() {
	const { wspAccounts } = useSelector((state) => state.auth);
	const dispatch = useDispatch();

	const [search, setsearch] = useState('');
	const [data, setdata] = useState(wspAccounts);

	function getSearchValue(val) {
		setsearch(val);
	}

	async function fetchAccounts() {
		const resp = await dispatch(fetchWsp());
		await setdata(resp?.payload);
	}

	useEffect(() => {
		fetchAccounts();
	}, []);

	useEffect(() => {}, [data, wspAccounts]);

	return (
		<>
			<PageTitle
				currentPage='Expression of demand'
				subSubTitle={'SSP Accounts'}
				subTitle={'Signups'}
				urlSubTitle={'/ssp-accounts'}
			/>
			<GlobalSearch getSearchValue={getSearchValue} />
			<UserListTable
				tableData={data}
				assigned={[]}
				search={search}
				url='incomplete'
			/>
		</>
	);
}
