import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import SingleTableItem from '../../components/single_table_item';



function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}





export default function AuditorTable({ handleChangeInput, trust, data,
  userComment,
  setUserComment,
  setMatrixInfo,
  matrixInfo,
  name,
  stepName,
  id,
  status,
  comment,
  setComment,
  setUserMatrixInfo,
  userMatrixInfo,
  auditorInfo
}) {
  const rows = [
    createData('Name', 159),
    createData('Firm', 237),
    createData('Telephone', 159),
    createData('Email', 237),
  ];
  const location = useLocation();
  const category = location.pathname.split('/')[3]
  return (
    <TableContainer component={Paper} elevation={0} sx={{ background: 'inherit' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow className='table'>
            <TableCell>Item</TableCell>
            <TableCell align="left">Details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className='basic-comment'>
          {auditorInfo.map((row) => (
            <TableRow
              key={row?.name}
            >
              <TableCell component="th" scope="row" sx={{ borderLeft: 'none !important' }}>
                {row?.name}
              </TableCell>
              <TableCell align="left"  >{row?.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {
        (category == "Viable" || category == "Partner" || category == "poor" || category == "Assistance") && (
          <Box sx={{ padding: '20px', mt: 2 }}>
            <SingleTableItem
              overallComment="true"
              userComment={userComment}
              setUserComment={setUserComment}
              setMatrixInfo={setMatrixInfo}
              matrixInfo={matrixInfo}
              name="auditScores"
              stepName={stepName}
              id={data?.projId}
              maxScores="5"
              comment={comment}
              setComment={setComment}
              setUserMatrixInfo={setUserMatrixInfo}
              userMatrixInfo={userMatrixInfo}

            />
          </Box>
        )
      }

    </TableContainer>
  );
}