import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getExpressionDemandData } from "../../features/expression_demand/expressionDemandSlice";
import PageTitle from "../../components/global/PageTitle";
import GlobalSearch from "../../components/global/GlobalSearch";
import AssigneePriorityTable from "../../components/table_priority_assigned/AssigneePriorityTable";


export default function BankApproval() {
    const dispatch = useDispatch()

    const [search, setsearch] = useState('');
    const [data, setdata] = useState([])

    function getSearchValue(val) {
        setsearch(val);
    }

    async function fetchCompleteExpressions() {
        const resp = await dispatch(getExpressionDemandData({ projStatus: "APPROVED" }))
        await setdata(resp?.payload?.data?.result)
    }

    useEffect(() => {
        fetchCompleteExpressions()
    }, [])

    useEffect(() => {

    }, [data])

    return (
        <>
            <PageTitle
                currentPage='Pipelines'
                subSubTitle={'partner selection'}
                subTitle={'partner selection'}
                urlSubTitle={'/approved'}
            />
            <GlobalSearch getSearchValue={getSearchValue} />
            <AssigneePriorityTable
                tableData={data}
                assigned={[]}
                search={search}
                category={null}
                url='approved'
                bank={true}
            />
        </>
    );
}
