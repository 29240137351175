import { Box } from '@mui/material';
import { Typography } from '@mui/joy';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import UserMatrix from './UserMatrix';
import FileButton from './FileButton';
import { formatImgPath } from '../../../utils';

const SingleAttachment = ({
	name,
	userComment,
	stepName,
	id,
	maxScores,
	matrixInfo,
	row,
	tableFile,
	userMatrixInfo,
	setUserMatrixInfo,
}) => {
	console.log(tableFile, "*******************FILE********************");
	console.log(tableFile, "*******************FILE TWOOOOOOOOOO********************");
	return (
		<>
			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				{!tableFile ? (
					<Typography
						sx={{
							fontWeight: 700,
							fontSize: '16px',
							my: 0,
							display: 'flex',
							alignItems: 'center',
							textAlign: 'center',
						}}
					>
						{row?.name}
						<FileButton
							title={row?.description}
							titlePreview={row?.description}
							viable='true'
						/>
					</Typography>
				) : (
					<Typography sx={{ fontWeight: 700, fontSize: '16px', my: 0 }}>
						<FileButton
							title={row?.description}
							titlePreview={row?.description}
						/>
					</Typography>
				)}
				{userComment[name] ? (
					<>
						<Typography sx={{ mt: 1 }}>{userComment[name]}</Typography>
					</>
				) : (
					<Typography sx={{ mt: 1 }}>No comment</Typography>
				)}

				{!tableFile && maxScores && (
					<Box
						sx={{
							display: 'flex',
							mt: 2,
							mb: 2,
							gap: 6,
							alignItems: 'center',
						}}
					>
						<Typography
							sx={{
								color: 'var(--color-blue-grey)',
								fontWeight: 500,
								fontSize: '14px',
							}}
						>
							System score: {matrixInfo[name]}
						</Typography>
						<Box
							sx={{
								width: '300px',
								display: 'flex',
								alignItems: 'center',
								bgColor: 'red',
								gap: 2,
							}}
						>
							<Typography
								sx={{
									display: 'flex',
									alignItems: 'center',
									gap: 2,
									color: '#0873B9',
								}}
							>
								Technical advisor score:
								<UserMatrix
									setMatrixInfo={setUserMatrixInfo}
									matrixInfo={userMatrixInfo}
									name={row.variableName}
									stepName={stepName}
									id={id}
									maxScores={row.maxScores}
								/>
							</Typography>
						</Box>
					</Box>
				)}
			</Box>
		</>
	);
};

export default SingleAttachment;
