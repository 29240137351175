import axios from "axios"

const API_URL  = `${process.env.REACT_APP_API_BASE_URL}`;
 
const fetchAccounts = async (data) => {
	const response = await axios.get(`${API_URL}/usr/fetchAccounts.action?${data && `criteria=accTradeName&txtSearch=${data}`}`); 
    // console.log("Was the Response Dispatched =======************++++++++=======****",response.data);

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data
};

const fetchProjects = async (filterData) => {

    const response = await axios.get(`${API_URL}/usr/fetch_projects.action?accId=${2433}`);
    console.log(response,"Here is the Response--------------------->>>>>>>>>>>>>>>>>>RRRRRR..r.R..R.R.>>>>>>>>>>>>>>>")

    if (!response.data.success) {
        return Promise.reject(response.data.message)
    }
    return response.data
}


const supportService={
    fetchAccounts,
    fetchProjects,
}

export default supportService
